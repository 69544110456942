import { convertUTCDateToTimezone } from "../calendar/Calendar.utils";
import { IOptionRecurrence } from "../../interfaces/Enums";
import moment from "moment-timezone";
import { translations } from "../../translations";
/**
 * Clear timezone from dates
 * @param event event
 */
export const clearTimezoneFromDates = (event) => {
    if (!event.id && !event.groupId && event.allDay)
        return event;
    let startDate = convertUTCDateToTimezone(event.startDate, event.timeZone, false).format("YYYY-MM-DDTHH:mm:00");
    let endDate = convertUTCDateToTimezone(event.endDate, event.timeZone, false).format("YYYY-MM-DDTHH:mm:00");
    return {
        ...event,
        startDate: startDate,
        endDate: endDate,
    };
};
/**
 *
 * @param date
 */
export const getWeekDayNumber = (date) => {
    // day() function return sunday as first day of week, we want monday as first day
    let number = moment(date).day() - 1;
    if (number === -1)
        number = 6;
    return number;
};
/**
 * Update event recurrence
 * @param sourceEvent source event
 * @param recurrenceMode new recurrence mode
 */
export const updateEventRRule = (sourceEvent, recurrenceMode) => {
    let newDuration = calculateDatesRangeDuration(sourceEvent.startDate, sourceEvent.endDate);
    let isRecurrenceValid = isEventRecurrenceValid(recurrenceMode, newDuration);
    let event = { ...sourceEvent };
    event.isRecurrenceValid = isRecurrenceValid;
    event.modeRecurrence = recurrenceMode;
    if (!!event.rrule && recurrenceMode === IOptionRecurrence.Custom)
        return event;
    let rrule = {};
    rrule.interval = 1;
    rrule.until = null;
    if (recurrenceMode !== IOptionRecurrence.NotRepeat)
        rrule.until = event.rrule?.until;
    rrule.dtstart = formatDateWithoutTimezone(event.startDate);
    switch (recurrenceMode) {
        case IOptionRecurrence.Workweek:
            rrule = { ...rrule, freq: "daily", byweekday: [0, 1, 2, 3, 4] };
            break;
        case IOptionRecurrence.Daily:
            rrule = { ...rrule, freq: "daily" };
            break;
        case IOptionRecurrence.Weekly:
        case IOptionRecurrence.Custom:
            // By default custom recurrence will start with weekly recurrence if rrule is not defined
            if (recurrenceMode === IOptionRecurrence.Custom && !!event.rrule) {
                rrule = event.rrule;
                break;
            }
            rrule = { ...rrule, freq: "weekly" };
            break;
        case IOptionRecurrence.Monthly:
            rrule = { ...rrule, freq: "monthly" };
            break;
        case IOptionRecurrence.Yearly:
            rrule = { ...rrule, freq: "yearly" };
            break;
        default:
            rrule = undefined;
            break;
    }
    event.rrule = rrule;
    return event;
};
/**
 * Update event start date
 * @param sourceEvent source event
 * @param startDate start date
 */
export const updateEventStartDate = (sourceEvent, startDate, allowDoubleBooking) => {
    let momentStart = moment(sourceEvent.startDate);
    if (sourceEvent.allDay)
        momentStart = moment(startDate).set({ hours: 0, minutes: 0 });
    else
        momentStart = moment(startDate).set({ hours: momentStart.hours(), minutes: momentStart.minutes() });
    const start = formatDateWithoutTimezone(momentStart);
    let end = sourceEvent.endDate;
    if (momentStart > moment(sourceEvent.endDate)) {
        let momentEnd = moment(sourceEvent.endDate);
        if (sourceEvent.allDay)
            momentEnd = momentStart.set({ hours: 23, minutes: 59 });
        else
            momentEnd = momentStart.set({ hours: momentEnd.hours(), minutes: momentEnd.minutes() });
        end = formatDateWithoutTimezone(momentEnd);
    }
    let event = {
        ...sourceEvent,
        startDate: start,
        endDate: end,
    };
    if (event.modeRecurrence === IOptionRecurrence.NotRepeat)
        return event;
    if (!allowDoubleBooking) {
        event = updateEventUntilDate(event, moment(event.rrule?.until).toDate(), moment(sourceEvent.startDate).toDate());
    }
    else
        event = updateEventRRule(event, event.modeRecurrence);
    return event;
};
/**
 * Update event start date time
 * @param sourceEvent source event
 * @param hours hours
 * @param minutes minutes
 */
export const updateEventStartDateTime = (sourceEvent, hours, minutes) => {
    const momentStart = moment(sourceEvent.startDate).set({ h: hours, m: minutes });
    let event = { ...sourceEvent, startDate: formatDateWithoutTimezone(momentStart) };
    if (event.modeRecurrence === IOptionRecurrence.NotRepeat)
        return event;
    event = updateEventRRule(event, event.modeRecurrence);
    return event;
};
/**
 * Update event end date
 * @param sourceEvent source event
 * @param endDate end date
 */
export const updateEventEndDate = (sourceEvent, endDate) => {
    let momentEnd = moment(sourceEvent.endDate);
    if (sourceEvent.allDay)
        momentEnd = moment(endDate).set({ hours: 23, minutes: 59 });
    else
        momentEnd = moment(endDate).set({ hours: momentEnd.hours(), minutes: momentEnd.minutes() });
    const end = formatDateWithoutTimezone(momentEnd);
    let start = sourceEvent.startDate;
    if (moment(sourceEvent.startDate) > momentEnd) {
        let momentStart = moment(sourceEvent.startDate);
        if (sourceEvent.allDay)
            momentStart = momentEnd.set({ hours: 0, minutes: 0 });
        else
            momentStart = momentEnd.set({ hours: momentStart.hours(), minutes: momentStart.minutes() });
        start = formatDateWithoutTimezone(momentStart);
    }
    let event = {
        ...sourceEvent,
        endDate: end,
        startDate: start
    };
    if (event.modeRecurrence === IOptionRecurrence.NotRepeat)
        return event;
    event = updateEventRRule(event, event.modeRecurrence);
    return event;
};
/**
 * Update event end date time
 * @param sourceEvent source event
 * @param hours hours
 * @param minutes minutes
 */
export const updateEventEndDateTime = (sourceEvent, hours, minutes) => {
    const newEndHour = moment(sourceEvent.endDate).set({ h: hours, m: minutes });
    let event = { ...sourceEvent, endDate: newEndHour.toISOString() };
    if (event.modeRecurrence === IOptionRecurrence.NotRepeat)
        return event;
    event = updateEventRRule(event, event.modeRecurrence);
    return event;
};
/**
 * Update event start date
 * @param sourceEvent source event
 * @param startDate start date
 */
export const updateEventUntilDate = (sourceEvent, untilDate, prevStartDate = null) => {
    let momentUntil = moment(!sourceEvent.rrule?.until ? sourceEvent.startDate : sourceEvent.rrule?.until);
    if (sourceEvent.allDay)
        momentUntil = moment(!untilDate ? sourceEvent.startDate : untilDate).set({ hours: 0, minutes: 0 });
    else
        momentUntil = moment(!untilDate ? sourceEvent.startDate : untilDate).set({ hours: momentUntil.hours(), minutes: momentUntil.minutes() });
    // ce if permet de mettre à jour la date du until lorsque celui-ci n'est pas défini ou lors du changement de mode de 
    // récurrence dans le cas où il n'a pas été modifié par l'utilisateur
    if (!sourceEvent.rrule?.until || (((sourceEvent.modeRecurrence !== IOptionRecurrence.Yearly &&
        (moment(sourceEvent.rrule?.until).toDate().getTime() > addYearAndHalf(sourceEvent.startDate).toDate().getTime() ||
            moment(sourceEvent.rrule?.until).toDate().getTime() == addYear(prevStartDate).toDate().getTime())) ||
        (sourceEvent.modeRecurrence === IOptionRecurrence.Yearly &&
            (moment(sourceEvent.rrule?.until).toDate().getTime() > addFiveYears(sourceEvent.startDate).toDate().getTime() ||
                moment(sourceEvent.rrule?.until).toDate().getTime() == addYear(prevStartDate).toDate().getTime()))) &&
        untilDate.getTime() === moment(sourceEvent.rrule?.until).toDate().getTime())) {
        momentUntil = addYear(sourceEvent.startDate);
    }
    else {
        if (prevStartDate !== null && moment(prevStartDate).toDate() < moment(sourceEvent.startDate).toDate()) {
            if (moment(untilDate).toDate().getTime() === addYear(prevStartDate).toDate().getTime()) {
                momentUntil = addYear(sourceEvent.startDate);
            }
            else {
                momentUntil = moment(untilDate);
            }
        }
        else {
            momentUntil = moment(untilDate);
        }
    }
    const until = formatDateWithoutTimezone(momentUntil);
    let event = {
        ...sourceEvent,
        rrule: {
            ...sourceEvent.rrule,
            until: until,
        },
    };
    if (event.modeRecurrence === IOptionRecurrence.NotRepeat)
        return event;
    event = updateEventRRule(event, event.modeRecurrence);
    return event;
};
export const addFiveYears = (date) => {
    return (moment(date).add(5, "years"));
};
export const addYearAndHalf = (date) => {
    return (moment(date).add(1, "year").add(6, "months"));
};
export const addYear = (date) => {
    return (moment(date).add(1, "year"));
};
/**
 * Update event all day
 * @param sourceEvent source event
 */
export const updateEventAllDayToggle = (sourceEvent) => {
    const isAllDay = !sourceEvent.allDay;
    let newStartDate;
    let newEndDate;
    const startDate = moment(sourceEvent.startDate);
    const endDate = moment(sourceEvent.endDate);
    if (isAllDay) {
        newStartDate = startDate.set({ h: 0, m: 0, s: 0, ms: 0 }).toISOString();
        newEndDate = endDate === startDate ?
            startDate.add(1, "days").add(-1, "minutes").toISOString()
            :
                endDate.set({ h: 23, m: 59, s: 0, ms: 0 }).toISOString();
    }
    else {
        const currentHour = moment().get("hours");
        newStartDate = startDate.set({ h: currentHour, m: 0, s: 0, ms: 0 }).toISOString();
        newEndDate = endDate.set({ h: currentHour + 1, m: 0, s: 0, ms: 0 }).toISOString();
    }
    let event = {
        ...sourceEvent,
        allDay: isAllDay,
        startDate: newStartDate,
        endDate: newEndDate,
    };
    if (event.modeRecurrence === IOptionRecurrence.NotRepeat)
        return event;
    event = updateEventRRule(event, event.modeRecurrence);
    return event;
};
/**
 * Capitalize a string
 * @param s string
 */
export const capitalizeString = (s) => {
    if (!s)
        return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
};
/**
 * Format date hours to local hours
 * @param date date
 * @param use12HoursFormat is using 12 format hours ?
 */
export const formatToLocalHours = (date, use12HoursFormat) => {
    if (use12HoursFormat) {
        let temp = moment(date);
        return temp.format("hh a");
    }
    else {
        return (moment(date).format("HH")) + "h";
    }
};
/**
 * Format date minutes to local minutes
 * @param date
 */
export const formatToLocalMinutes = (date) => {
    return moment(date).format("mm");
};
/**
 * Get array of hours in a day
 * @param use12HoursFormat is using 12 hours format ?
 */
export const getHours = (use12HoursFormat) => {
    let times = new Array();
    if (use12HoursFormat) {
        for (let k = 0; k < 24; k++) {
            const hour = (k < 12 ? k : k - 12);
            times.push((hour < 10 ? "0" : "") + hour + " " + (k < 12 ? "AM" : "PM"));
        }
    }
    else {
        for (let k = 0; k < 24; k++)
            times.push((k < 10 ? "0" : "") + k + "h");
    }
    return times;
};
/**
 * Get array of minutes
 */
export const getMinutes = () => {
    let minutes = new Array();
    for (let i = 0; i < 60; i++)
        minutes.push((i < 10 ? "0" : "") + i);
    return minutes;
};
/**
 * Check if event dates are valid
 * @param event event
 */
export function datesAreValid(event) {
    if (!event.startDate && !event.endDate)
        return false;
    if (moment(event.startDate) > moment(event.endDate))
        return false;
    if (moment(event.startDate) >= moment(event.endDate) && !event.allDay)
        return false;
    return true;
}
/**
 * Calculate dates range duration
 * @param start start date
 * @param end end date
 */
export function calculateDatesRangeDuration(start, end) {
    const startDate = moment(start);
    const endDate = moment(end);
    return endDate.diff(startDate, "ms");
}
/**
 * Format date without timezone
 * @param date date
 */
export function formatDateWithoutTimezone(date) {
    return moment(date).format("YYYY-MM-DDTHH:mm:00");
}
/**
 * Check event recurrence
 * @param mode mode
 * @param eventDuration event duration
 */
export function isEventRecurrenceValid(mode, eventDuration) {
    let maxDuration = 0;
    switch (mode) {
        case IOptionRecurrence.NotRepeat:
            return true;
        case IOptionRecurrence.Workweek:
            maxDuration = 86400000;
            break;
        case IOptionRecurrence.Daily:
            maxDuration = 86400000;
            break;
        case IOptionRecurrence.Weekly:
            maxDuration = 604800000;
            break;
        case IOptionRecurrence.Monthly:
            maxDuration = 2419000000;
            break;
        case IOptionRecurrence.Yearly:
            maxDuration = 31540000000;
            break;
        case IOptionRecurrence.Custom:
            return true;
    }
    return eventDuration <= maxDuration;
}
/**
 * Generate dropdown items
 * @param translate
 * @param enumType
 * @param ids
 */
export function generateDropdownItems(enumType, ids) {
    let array = [];
    ids.forEach((item) => {
        array.push({
            header: translations.get(item),
            value: enumType[item]
        });
    });
    return array;
}
/**
 * Get rrule days and months translations
 * @param translate
 */
export function getRruleDaysMonthsTranslations(translate) {
    return {
        dayNames: [
            translate("RRuleSunday"),
            translate("RRuleMonday"),
            translate("RRuleTuesday"),
            translate("RRuleWednesday"),
            translate("RRuleThursday"),
            translate("RRuleFriday"),
            translate("RRuleSaturday")
        ],
        monthNames: [
            translate("RRuleJanuary"),
            translate("RRuleFebruary"),
            translate("RRuleMarch"),
            translate("RRuleApril"),
            translate("RRuleMay"),
            translate("RRuleJune"),
            translate("RRuleJuly"),
            translate("RRuleAugust"),
            translate("RRuleSeptember"),
            translate("RRuleOctober"),
            translate("RRuleNovember"),
            translate("RRuleDecember")
        ]
    };
}
/**
 * Get rrule translations
 * @param translate
 */
export function getRruleTranslations(translate) {
    return [
        { content: "every", value: translate("RRuleEvery"), },
        { content: 'day', value: translate("RRuleDay") },
        { content: 'days', value: translate("RRuleDays"), },
        { content: 'month', value: translate("RRuleMonth") },
        { content: 'months', value: translate("RRuleMonths") },
        { content: 'year', value: translate("RRuleYear") },
        { content: 'years', value: translate("RRuleYears") },
        { content: 'on the', value: translate("RRuleOnThe") },
        { content: 'and', value: translate("RRuleAnd") },
        { content: 'nd', value: translate("RRuleNd") },
        { content: 'th', value: translate("RRuleTh") },
        { content: 'st', value: translate("RRuleSt") },
        { content: 'week', value: translate("RRuleWeek") },
        { content: 'weeks', value: translate("RRuleWeeks") },
        { content: "on", value: translate("RRuleOn") },
        { content: "for", value: translate("RRuleFor") },
        { content: "times", value: translate("RRuleTimes") },
        { content: "until", value: translate("RRuleUntil") }
    ];
}
/**
 * Get days of month from date
 * @param date
 */
export function getDaysOfMonthFromDate(date) {
    const numberOfDays = moment(date).daysInMonth();
    const days = Array.from(Array(numberOfDays).keys()).map(d => d + 1);
    return days;
}
