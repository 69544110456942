import { DisplayMode } from "calendarShared";
import momentTz from "moment-timezone";
import listPlugin from "@fullcalendar/list";
import timeZonePlugin from "@fullcalendar/moment-timezone";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

export const timeZones = momentTz.tz.names();

export const viewModes = [
  {
    content: "Month",
    key: DisplayMode.Month,
    value: DisplayMode.Month,
  },
  {
    content: "Week",
    key: DisplayMode.Week,
    value: DisplayMode.Week,
  },
  {
    content: "Day",
    key: DisplayMode.Day,
    value: DisplayMode.Day,
  },
];

export const plugins = [timeZonePlugin, listPlugin, dayGridPlugin, timeGridPlugin];
