import { configureStore } from "@reduxjs/toolkit";
import {
  appConfigurationReducer,
  authReducer,
  configurationReducer,
  draftsReducer,
  errorReducer,
  infosReducer,
  modalReducer,
  msTeamsReducer,
  notificationsReducer,
  permissionsReducer,
  premiumReducer,
  scopesReducer,
  userReducer,
} from "front";

import { googleMapReducer } from "./reducers/googleMap.reducer";
import { connectedAppReducer } from "./reducers/connectedApp.reducer";
import PaginationReducer from "./reducers/PaginationReducer";

import { navBarReducer } from "calendarShared";
import limitationReducer from "./reducers/limitation.reducer";

const reducer = {
  configuration: configurationReducer,
  msTeams: msTeamsReducer,
  auth: authReducer,
  scopes: scopesReducer,
  user: userReducer,
  premium: premiumReducer,
  modal: modalReducer,
  error: errorReducer,
  appConfiguration: appConfigurationReducer,
  infosApp: infosReducer,
  notifications: notificationsReducer,
  permissions: permissionsReducer,
  googleMap: googleMapReducer,
  limitation: limitationReducer,
  connectedApp: connectedAppReducer,
  pagination: PaginationReducer,
  navBar: navBarReducer,
  draft : draftsReducer
};

export const initStore = () => {
  return configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
};

const store = initStore();

export type IStore = ReturnType<typeof initStore>;

export type ReduxStoreState = ReturnType<typeof store.getState>;
export type IAppDispatch = typeof store.dispatch;

export default store;
