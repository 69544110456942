import { useCallback } from "react";
import { ErrorModule } from "../../components/ErrorBoundary/ErrorBoundary";
import { scopesServiceButtonAction } from "../../redux/reducers/ScopesReducer/ScopesReducer";
import { useSelector } from "react-redux";
export const useScopesService = () => {
    const state = useSelector((s) => s.scopes);
    const bindConsentButton = useCallback(async (ref) => {
        if (!ref)
            return;
        if (!scopesServiceButtonAction)
            return ErrorModule.showErrorAlert("Consent button action is undefined");
        await handleConsentButtonClick(ref, scopesServiceButtonAction);
    }, []);
    return {
        data: state,
        bindConsentButton,
    };
};
export const checkConfig = (config) => {
    if (!config.scopes || config.scopes.length === 0)
        config.scopes = [".default"];
    if (!config.clientId)
        return ErrorModule.showErrorAlert("TeamsFx client id is invalid");
    if (!config.apiBaseUrl)
        return ErrorModule.showErrorAlert("TeamsFx api base url is invalid");
    if (config.apiBaseUrl.lastIndexOf('/') === config.apiBaseUrl.length - 1)
        config.apiBaseUrl = config.apiBaseUrl.substring(0, config.apiBaseUrl.length - 1);
};
const handleConsentButtonClick = (button, action) => {
    return new Promise((resolve, reject) => {
        if (!button)
            return reject(ErrorModule.showErrorAlert("Consent button ref is undefined"));
        const onClickLogin = async () => {
            await action();
            button?.removeEventListener("click", onClickLogin);
            resolve();
        };
        button?.addEventListener("click", onClickLogin);
    });
};
