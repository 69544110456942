import { IGoogleInput, setModalWithData, useGoogleInput, useMsTeamsSelector, usePremiumSelector, useTranslate } from "front";
import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { translations } from "../../../translations";
import { reducer, initialState, IAction, IState } from "./SideViewPlaces.reducer";
import { useForm } from "react-hook-form";
import { IPlace } from "../../../types/IPlace/IPlace";
import { useSelector, useDispatch } from "react-redux";
import { IAppDispatch, ReduxStoreState } from "../../../redux/store";
import { createMarker } from "../../../utils/createMarker";
import { usePlaceCache } from "../../../hooks/usePlaceCache/usePlaceCache";
import { IDialogs } from "../../../types/IDialogs/IDialogs";
import { useMapCache } from "../../../hooks/useMapCache/useMapCache";
import { CheckboxProps } from "@fluentui/react-northstar";
import { usePlaceLimitation } from "../../../hooks/usePlaceLimitation/usePlaceLimitation";
import { useTripLimitation } from "../../../hooks/useTripLimitation/useTripLimitation";

export const useSideViewPlaces = (props: { isOpen: boolean; handleClose: () => void }) => {
  const t = useTranslate(translations);
  const dispatchCtx = useDispatch<IAppDispatch>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    clearErrors,
    setValue,
  } = useForm<{
    title: string;
    description: string;
  }>({
    defaultValues: {
      title: "",
      description: "",
    },
  });

  const { googleMap, oms } = useSelector((s: ReduxStoreState) => s.googleMap);
  const {userName } = useMsTeamsSelector("userName");
  const { isPremium } = usePremiumSelector("isPremium");

  // Refs
  const initialPlacesAreInit = useRef<boolean>(false);
  const addressInpRef = useRef<HTMLInputElement>(null);
  const isSwitchingToEditMode = useRef<boolean>(false);
  const onEditMode = useRef<{ isEditing: boolean; id: string }>({ isEditing: false, id: "" });

  // State
  const [state, dispatch] = useReducer(reducer, { ...initialState });
  const [markers, setMarkers] = useState<Map<string, google.maps.Marker>>(new Map());

  const { queryPlace, createPlaceMutation, editPlaceMutation, getPlaceById } = usePlaceCache();
  const { queryMap } = useMapCache();
  const googleInput = useGoogleInput({ inpRef: addressInpRef });

  const { canAddPlace } = usePlaceLimitation();
  const { canAddTrip } = useTripLimitation();
  const limitIsReached = () => !canAddPlace() || !canAddTrip();

  useEffect(() => {
    !initialPlacesAreInit.current && initInitialMarkers();
  }, [queryPlace.data]);

  useEffect(() => {
    if (isSwitchingToEditMode.current) {
      googleInput.forceValidity(true);
      isSwitchingToEditMode.current = false;
      return;
    }
  }, [googleInput.values]);

  const initInitialMarkers = () => {
    if (!queryPlace.data || !googleMap || !oms) return;
    const filteredPlaces = queryPlace.data.filter((qp) => !qp.isDisabled);
    queryPlace.data.forEach((place, idx) => {
      const marker = addMarker(place);
      if (idx + 1 === filteredPlaces.length) zoomOnMarker(marker); // Zoom on last displayed marker
      if (place.isDisabled) marker!.setVisible(false);
    });
    initialPlacesAreInit.current = true;
  };

  const zoomOnMarker = (marker: google.maps.Marker | undefined) => {
    if (!marker || !googleMap) return;
    googleMap.setCenter(marker.getPosition() as any);
    googleMap.setZoom(15);
  };

  const toggleAllMarkers = (_: React.SyntheticEvent, data: CheckboxProps | undefined) => {
    if (!data) return;
    dispatch({ type: IAction.MODIFY_MODE, payload: { ...state, showAllMarkers: data.checked as boolean } });
    markers.forEach((m) => m.setVisible(data.checked as boolean));
  };

  const toggleMode = (mode: 0 | 1) => {
    if (state.mode === 1) clearValues();
    dispatch({ type: IAction.MODIFY_MODE, payload: { ...state, mode } });
  };

  const onSubmit = async (data: { title: string; description: string }) => {
    try {
      let place: IPlace | undefined = undefined;
      const newPlace = generatePlaceDTO(data, onEditMode.current.id);
      if (onEditMode.current.isEditing) {
        await editPlaceMutation.mutateAsync({ place: newPlace });
        place = getPlaceById(newPlace.id as string);
        if (!place) return;
        removeMarker(place.id);
      } else {
        place = await createPlaceMutation.mutateAsync({ place: newPlace });
      }
      if (!place || !place.id) return;
      setMarker(place);
    } catch (error) {
      console.error(error);
    }
  };

  const generatePlaceDTO = (data: { title: string; description: string }, id: string | undefined): IPlace => {
    let placeDTO: IPlace = {};
    const { title, description } = data;
    const { fullAddress, lat, lng } = googleInput.values;
    const appId = queryMap.data.id!;
    placeDTO = { title, address: fullAddress, lat, lng, description, mapId: appId};

    if (id) {
      placeDTO.id = id;
      placeDTO.updatedByObjectId = userName;
    }
    else {
      placeDTO.createdByObjectId = userName;
    }
    return placeDTO;
  };

  const removeMarker = (placeId: string | undefined) => {
    if (!placeId) return;
    const oldMarker = markers.get(placeId);
    if (!oldMarker) return;
    oldMarker.setMap(null);
    markers.delete(placeId);
    setMarkers(new Map(markers));
  };

  const setMarker = (place: IPlace) => {
    addMarker(place);
    onClose();
  };

  const addMarker = (place: IPlace): google.maps.Marker | undefined => {
    if (!googleMap || !oms) return;
    const { id, title, description, address, lat, lng } = place as any;
    const marker = createMarker(googleMap, oms, { id, title, description, address, lat, lng });
    markers.set(place.id as string, marker);
    setMarkers(new Map(markers));
    return marker;
  };

  const handleAddress = (data: IGoogleInput) => googleInput.setValues(data);

  const onClose = useCallback(() => {
    clearValues();
    props.handleClose();
  }, []);

  const clearValues = useCallback(() => {
    clearErrors();
    reset();
    googleInput.reset();
    dispatch({ type: IAction.MODIFY_MODE, payload: { ...state, mode: 0 } });
    onEditMode.current = { isEditing: false, id: "" };
  }, []);

  const handleOpenDeletePlaceDialog = (placeId: string | undefined) => {
    if (!placeId) return;
    dispatchCtx(
      setModalWithData({
        isOpen: IDialogs.DELETE_PLACE,
        data: { placeId, markers, handleShow },
      })
    );
  };

  const handleEditMode = (placeId: string | undefined) => {
    if (!placeId) return;
    const place = queryPlace.data?.find((p) => p.id === placeId);
    if (!place || !place.title || !place.address) return;
    isSwitchingToEditMode.current = true;
    onEditMode.current = {
      isEditing: true,
      id: placeId,
    };
    dispatch({ type: IAction.MODIFY_MODE, payload: { ...state, mode: 1 } });
    googleInput.forceValidity(true);
    googleInput.setValues({
      fullAddress: place.address,
      lat: place.lat as number,
      lng: place.lng as number,
    });
    setValue("title", place.title);
    setValue("description", place.description as string);
  };

  const handleShow = (id: string) => {
    const marker = markers.get(id);
    if (!marker) return;
    const markerOldValue = marker.getVisible();
    marker.setVisible(!markerOldValue);
    setMarkers(new Map(markers));
  };

  return {
    ...state,
    t,
    toggleAllMarkers,
    toggleMode,
    handleSubmit,
    onSubmit,
    register,
    errors,
    watch,
    handleAddress,
    addressInpRef,
    addressIsValid: googleInput.isValid,
    places: queryPlace.data ?? [],
    handleOpenDeletePlaceDialog,
    handleEditMode,
    onClose,
    onEditMode: onEditMode.current.isEditing,
    handleShow,
    markers,
    isPremium,
    googleInput,
    title: watch("title"),
    description: watch("description"),
    address: googleInput.values,
    limitIsReached,
  };
};
