import { useMutation, useQuery } from "react-query";
import { IQueryKey } from "../../types/IQueryKey/IQueryKey";
import { queryOptions } from "../../utils/queryOptions";
import { useSelector } from "react-redux";
import { ReduxStoreState } from "redux/store";
import { queryClient } from "../../index";
import { useCallback } from "react";
import { createTrip, deleteTrip, editTrip, getTrips } from "../../apis/tripsActions/tripsActions";
import { ITrip } from "../../types/ITrip/ITrips";
import { handleExpiredToken, useMsTeamsSelector } from "front";

export const useTripCache = () => {
  const { appId } = useSelector((s: ReduxStoreState) => s.appConfiguration);
  const { tenantId } = useMsTeamsSelector("tenantId");
  const KEY = [IQueryKey.TRIP, appId, tenantId];

  const queryTrip = useQuery<ITrip[]>(KEY, getTrips, {
    ...queryOptions,
    enabled: !!appId,
    staleTime: Infinity,
    ...handleExpiredToken,
  });

  const createTripMutation = useMutation(createTrip, {
    ...handleExpiredToken,
    onSuccess: (res: ITrip) => {
      queryClient.setQueryData(KEY, (oldData: ITrip[] | undefined) => {
        if (!oldData) return [];
        const newData = [...oldData, res];
        return newData;
      });
    },
  });

  const editTripMutation = useMutation(editTrip, {
    ...handleExpiredToken,
    onSuccess: (_: undefined, variable: { trip: ITrip }) => {
      queryClient.setQueryData(KEY, (oldData: ITrip[] | undefined) => {
        if (!oldData) return [];
        const newData = oldData.map((item) => (item.id === variable.trip.id ? { ...item, ...variable.trip } : item));
        return newData;
      });
    },
  });

  const deleteTripMutation = useMutation(deleteTrip, {
    ...handleExpiredToken,
    onSuccess: (_: undefined, variable: { mapId: string; tripId: string }) => {
      queryClient.setQueryData(KEY, (oldData: ITrip[] | undefined) => {
        if (!oldData) return [];
        const newData = oldData.filter((item) => item.id !== variable.tripId);
        const firstDisabledElement = oldData.findIndex((od) => od.isDisabled);
        if (firstDisabledElement !== -1) oldData[firstDisabledElement]!.isDisabled = false;
        return newData;
      });
    },
  });

  const getTripById = useCallback(
    (id: string): ITrip | undefined => {
      const trips: ITrip[] | undefined = queryClient.getQueryData(KEY);
      if (!trips) return;
      return trips.find((p) => p.id === id);
    },
    [appId]
  );

  return { queryTrip, createTripMutation, editTripMutation, deleteTripMutation, getTripById };
};
