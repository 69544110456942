import { Button, CloseIcon, Dialog, Flex, Text } from "@fluentui/react-northstar";
import React, { memo, useMemo } from "react";
import { useDeleteTripDialog } from "./DeleteTripDialog.logic";

const DeleteTripDialog = memo(() => {
  const logic = useDeleteTripDialog();

  const content = useMemo(
    () => (
      <Flex column gap="gap.medium" style={{ padding: ".5rem 0" }}>
        <Text disabled={logic.isLoading} content={`${logic.t("DeleteMessage", { event: logic.t("Trip") })}`} />
      </Flex>
    ),
    [logic.isLoading]
  );

  const footer = useMemo(
    () => (
      <Flex hAlign="end">
        <Button disabled={logic.isLoading} content={logic.t("Cancel")} onClick={logic.onClose} style={{ marginRight: ".5rem" }} />
        <Button disabled={logic.isLoading} primary content={logic.t("Delete")} onClick={logic.handleSubmit} />
      </Flex>
    ),
    [logic.isLoading]
  );

  return (
    <Dialog
      header={logic.t("Deletion", { event: logic.t("Trip") })}
      headerAction={{ icon: <CloseIcon />, title: "Close", onClick: logic.onClose }}
      content={content}
      footer={footer}
      defaultOpen={true}
      onCancel={logic.onClose}
      closeOnOutsideClick
    />
  );
});

export default DeleteTripDialog;
