import { tryCatch } from "../../utils/tryCatch";
import { AxiosConfig } from "../AxiosConfig/AxiosConfig";
const getNewsAction = async ({ queryKey }) => {
    const [_, appType, campaignQuery] = queryKey;
    const query = `isOnMobile=${campaignQuery.isOnMobile}&language=${campaignQuery.language}&subscription=${campaignQuery.subscription}&tenantId=${campaignQuery.tenantId}`;
    const res = await AxiosConfig.instance.get(`/${appType}/news?${query}`);
    return res.data;
};
const getNewsBannerAction = async ({ queryKey }) => {
    const [_, appType, campaignQuery] = queryKey;
    const query = `isOnMobile=${campaignQuery.isOnMobile}&language=${campaignQuery.language}&subscription=${campaignQuery.subscription}&tenantId=${campaignQuery.tenantId}`;
    const res = await AxiosConfig.instance.get(`/${appType}/news/banner?${query}`);
    return res.data;
};
export const getNews = tryCatch(getNewsAction);
export const getNewsBanner = tryCatch(getNewsBannerAction);
