import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ClipboardCopiedToIcon, CloseIcon, Dialog, Flex, Popup, Text } from "@fluentui/react-northstar";
import { memo, useMemo } from "react";
import { useAboutDialog } from "./AboutDialog.logic";
import { Modal } from "../../../interfaces/Modal";
export const AboutDialog = memo((props) => {
    const logic = useAboutDialog();
    const content = useMemo(() => (_jsxs(Flex, { column: true, gap: "gap.medium", style: { padding: "1rem 0" }, children: [_jsxs(Flex, { column: true, children: [_jsx(Text, { content: logic.t("Version"), weight: "bold" }), _jsx(Text, { content: logic.appVersion })] }), logic.appVersion && (_jsxs(Flex, { column: true, children: [_jsx(Text, { content: logic.t("UniqueID"), weight: "bold" }), _jsxs(Flex, { vAlign: "center", children: [_jsx(Text, { content: logic.uniqueId }), _jsx(Flex, { vAlign: "center", children: _jsx(Popup, { pointing: true, open: logic.state.showPopupCopied, content: logic.t("Copied"), trigger: _jsx(Button, { icon: _jsx(ClipboardCopiedToIcon, { outline: true, onClick: logic.onCopyAppId }), text: true, iconOnly: true, title: logic.t("Copy") }) }) })] })] })), logic.createdBy && (_jsxs(Flex, { column: true, children: [_jsx(Text, { weight: "bold", content: logic.t("CreatedBy") }), _jsx(Flex, { vAlign: "center", children: _jsx(Text, { content: logic.createdBy }) })] })), props.customItems &&
                props.customItems.map((ci, i) => (_jsxs(Flex, { column: true, children: [_jsx(Text, { weight: "bold", content: ci.title }), _jsx(Flex, { vAlign: "center", children: _jsx(Text, { content: ci.value }) })] }, `${ci.title}-${i}`)))] })), [logic.appVersion, logic.onCopyAppId, logic.createdBy, logic.state.showPopupCopied, props.customItems]);
    const footer = useMemo(() => (_jsx(Flex, { hAlign: "end", children: _jsx(Button, { primary: true, content: logic.t("Close"), onClick: logic.onClose, style: { marginRight: ".5rem" } }) })), []);
    return (_jsx(Dialog, { header: logic.t("About"), headerAction: { icon: _jsx(CloseIcon, {}), title: logic.t("Close"), onClick: logic.onClose }, open: logic.isOpen === Modal.ABOUT, content: content, footer: footer, defaultOpen: true, onCancel: logic.onClose, closeOnOutsideClick: true }));
});
