import React from "react";

const WalkIcon = () => {
  return (
    <svg width="13" height="22" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.12 10.0002H13V8.2002H9.38L7.38 4.8702C7.08 4.3702 6.54 4.0302 5.92 4.0302C5.74 4.0302 5.58 4.0602 5.42 4.1102L0 5.8002V11.0002H1.8V7.3302L3.91 6.6702L0 22.0002H1.8L4.67 13.8902L7 17.0002V22.0002H8.8V15.5902L6.31 11.0502L7.04 8.1802M8 3.8002C9 3.8002 9.8 3.0002 9.8 2.0002C9.8 1.0002 9 0.200195 8 0.200195C7 0.200195 6.2 1.0002 6.2 2.0002C6.2 3.0002 7 3.8002 8 3.8002Z"
        fill="black"
      />
    </svg>
  );
};

export default WalkIcon;
