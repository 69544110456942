import { useMsTeamsSelector, usePremiumSelector, useTranslate } from "front";
import { createRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import FullCalendar, { CalendarApi } from "@fullcalendar/react";
import {
  DisplayMode,
  IAppDispatch,
  getFullcalendarDisplayMode,
  localTimezone,
  setNavbar,
  useNavBarSelector,
} from "calendarShared";
import { useDispatch } from "react-redux";
import { DropdownProps, MenuItemProps } from "@fluentui/react-northstar";
import { viewModes } from "./CalendarNav.data";
import { useCalendarCache } from "../../hooks/useCalendarCache/useCalendarCache";
import { translations } from "../../translations";
import { useTagsCache } from "../../hooks/useTagsCache/useTagsCache";

export const useCalendarNav = () => {
  const t = useTranslate(translations);

  // Cache
  const { queryCalendar } = useCalendarCache();
  const { queryTags } = useTagsCache();

  // Reducers
  const dispatchCtx = useDispatch<IAppDispatch>();
  const { searchTag, calendarTimeZone, displayMode } = useNavBarSelector("searchTag", "calendarTimeZone", "displayMode");
  const { isOnMobile, locale } = useMsTeamsSelector("isOnMobile", "locale");
  const { isPremium } = usePremiumSelector("isPremium");

  // Refs
  const fullcalendarRef = createRef<FullCalendar>();
  const calendarAPI = useRef<CalendarApi>();

  // States
  const [calendarTitle, setCalendarTitle] = useState<string>();

  useEffect(() => init(), []);

  const init = useCallback(() => {
    calendarAPI.current = fullcalendarRef.current!.getApi();
    handleCalendarTitle();
    dispatchCtx(
      setNavbar({
        displayMode: DisplayMode.Month,
        calendarTimeZone: localTimezone,
        activeStartDate: String(calendarAPI.current!.currentDataManager?.getCurrentData().viewApi.currentStart),
      })
    );
    calendarAPI.current!.setOption("timeZone", localTimezone);
  }, []);

  const handleCursorEvents = (action: "prev" | "next") => {
    calendarAPI.current![action]();
    updateStartDate();
    handleCalendarTitle();
  };

  const handleCalendarTitle = useCallback(() => {
    const title = calendarAPI.current!.view.title[0]?.toUpperCase() + calendarAPI.current!.view.title.slice(1);
    setCalendarTitle(title);
  }, []);

  const handleSearchTag = (_: any, data: DropdownProps) => {
    let value = data.value;
    if (typeof value !== "string") value = "";
    dispatchCtx(setNavbar({ searchTag: value }));
  };

  const handleCalendarTimeZone = (_: any, data: DropdownProps) => {
    const timeZone = data.value as string;
    if (!timeZone) return;
    dispatchCtx(setNavbar({ calendarTimeZone: timeZone }));
    calendarAPI.current!.setOption("timeZone", timeZone);
  };

  const showTodayEvents = useCallback(() => {
    calendarAPI.current!.today();
    updateStartDate();
    handleCalendarTitle();
  }, []);

  const updateStartDate = useCallback(() => {
    const activeStartDate = String(calendarAPI.current!.currentDataManager?.getCurrentData().viewApi.currentStart);
    dispatchCtx(setNavbar({ activeStartDate }));
  }, []);

  const getViewModeLabel = useMemo((): string => t(DisplayMode[displayMode]), [displayMode]);

  const handleChangeDisplayMode = (_: any, data: MenuItemProps | undefined) => {
    if (data?.index === undefined) return;
    const displayMode = viewModes[data.index]?.value ?? DisplayMode.Month;
    dispatchCtx(setNavbar({ displayMode }));
    calendarAPI.current?.changeView(getFullcalendarDisplayMode(displayMode, undefined));
    updateStartDate();
    handleCalendarTitle();
  };

  return {
    t,
    isOnMobile,
    handleCursorEvents,
    fullcalendarRef,
    calendarTitle,
    searchTag,
    isPremium,
    handleCalendarTimeZone,
    calendarTimeZone,
    showTodayEvents,
    handleSearchTag,
    getViewModeLabel,
    displayMode,
    handleChangeDisplayMode,
    locale,
    allTags: queryTags.data,
    data: queryCalendar.data,
  };
};
