import { ConfigurationApi } from "../../apis/Configuration/ConfigurationApi";
import { AxiosConfig } from "../../apis/AxiosConfig/AxiosConfig";
///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////
export const getConfig = async () => {
    const configuration = await ConfigurationApi.getConfiguration();
    const apiBaseUrl = `https://${window.location.host}/api/${configuration.apiVersion}`;
    AxiosConfig.setAxiosInstanceApiBaseUrl(apiBaseUrl);
    checkVersion(configuration.applicationVersion);
    return configuration;
};
const checkVersion = async (lastVersion) => {
    const currentVersion = window.localStorage.getItem("app_version");
    const isLastVersion = lastVersion === currentVersion;
    if (isLastVersion)
        return true;
    window.localStorage.setItem("app_version", lastVersion);
    await handleHardReload(window.location.href);
    return false;
};
export const handleHardReload = async (url) => {
    if ("caches" in window) {
        const cachedKeys = await window.caches.keys();
        await Promise.all(cachedKeys.map((key) => window.caches.delete(key)));
    }
    await fetch(url, { headers: { Pragma: "no-cache", Expires: "-1", "Cache-Control": "no-cache" } });
    window.location.href = url;
    window.location.reload();
};
