import { setModalWithData, useGoogleInput, useMsTeamsSelector, useTranslate, useGoogleTravel, usePremiumSelector, IGoogleInput } from "front";
import React, { useCallback, useEffect, useReducer, useRef } from "react";
import { translations } from "../../../translations";
import { reducer, initialState, IAction } from "./SideViewTrips.reducer";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { IAppDispatch, ReduxStoreState } from "../../../redux/store";
import { IDialogs } from "../../../types/IDialogs/IDialogs";
import { useTripCache } from "../../../hooks/useTripCache/useTripCache";
import { useMapCache } from "../../../hooks/useMapCache/useMapCache";
import { IOptionTrip } from "../../../types/IOptionTrip/IOptionTrip";
import { ITrip } from "../../../types/ITrip/ITrips";
import { convertToGoogleMapsTravelMode } from "../../../utils/convertToGoogleMapsTravelMode";
import { createTripDTO } from "../../../utils/createTripDTO";
import { usePlaceLimitation } from "../../../hooks/usePlaceLimitation/usePlaceLimitation";
import { useTripLimitation } from "../../../hooks/useTripLimitation/useTripLimitation";

export const useSideViewTrips = (props: { isOpen: boolean; handleClose: () => void }) => {
  const t = useTranslate(translations);
  const dispatchCtx = useDispatch<IAppDispatch>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    clearErrors,
    setValue,
    control,
  } = useForm<{
    type: IOptionTrip;
    title: string;
    description: string;
  }>({
    defaultValues: {
      type: IOptionTrip.Car,
      title: "",
      description: "",
    },
  });

  const type = watch("type");

  const { googleMap } = useSelector((s: ReduxStoreState) => s.googleMap);
  const { themeClass } = useMsTeamsSelector("themeClass");

  // Refs
  const addressFromInpRef = useRef<HTMLInputElement>(null);
  const addressToInpRef = useRef<HTMLInputElement>(null);
  const isSwitchingToEditMode = useRef<boolean>(false);
  const onEditMode = useRef<{ isEditing: boolean; id: string }>({
    isEditing: false,
    id: "",
  });
  const visibleTrip = useRef<string>("");

  // State
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  const { isPremium } = usePremiumSelector("isPremium");
  const { queryTrip, createTripMutation, editTripMutation, getTripById } = useTripCache();
  const { queryMap } = useMapCache();
  const googleInputFrom = useGoogleInput({ inpRef: addressFromInpRef });
  const googleInputTo = useGoogleInput({ inpRef: addressToInpRef });
  const googleTravel = useGoogleTravel({
    map: googleMap,
    from: googleInputFrom.values.fullAddress,
    to: googleInputTo.values.fullAddress,
    travelMode: convertToGoogleMapsTravelMode(type),
  });

  const { canAddPlace } = usePlaceLimitation();
  const { canAddTrip } = useTripLimitation();
  const limitIsReached = () => !canAddPlace() || !canAddTrip();

  const googleInputs = {
    from: {
      input: googleInputFrom,
    },
    to: {
      input: googleInputTo,
    },
  };

  const getGoogleInput = (type: "from" | "to"): { input: ReturnType<typeof useGoogleInput> } => googleInputs[type];

  useEffect(() => {
    if (isSwitchingToEditMode.current) {
      Object.values(googleInputs).forEach((i) => i.input.forceValidity(true));
      isSwitchingToEditMode.current = false;
      return;
    }
  }, [googleInputFrom.values, googleInputTo.values]);

  useEffect(() => {
    dispatch({ type: IAction.MODIFY_MODE, payload: { ...state, travelMode: type } });
  }, [type]);

  const toggleMode = (mode: 0 | 1) => {
    clearValues();
    dispatch({ type: IAction.MODIFY_MODE, payload: { ...state, mode } });
  };

  const onSubmit = async (data: { title: string; description: string }) => {
    try {
      const isOnEditMode = onEditMode.current.isEditing;
      let trip: ITrip | undefined = undefined;
      const mapId = queryMap.data.id as string;
      const newTrip = createTripDTO({
        title: data.title,
        description: data.description,
        from: googleInputFrom.values,
        to: googleInputTo.values,
        id: onEditMode.current.id,
        mapId,
        type: state.travelMode,
      });
      if (onEditMode.current.isEditing) {
        await editTripMutation.mutateAsync({ trip: newTrip });
        trip = getTripById(newTrip.id as string);
      } else {
        trip = await createTripMutation.mutateAsync({ trip: newTrip });
      }
      if (!trip || !trip.id) return;
      clearValues();
      onClose();
      if (isOnEditMode) return;
      handleShow(trip.id);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddress = (data: IGoogleInput, key: "from" | "to") => {
    const { input } = getGoogleInput(key);
    input.setValues(data);
  };

  const onClose = useCallback(() => {
    clearValues();
    props.handleClose();
  }, []);

  const clearValues = useCallback(() => {
    clearErrors();
    reset();
    Object.values(googleInputs).forEach((i) => i.input.reset());
    dispatch({ type: IAction.MODIFY_MODE, payload: { ...state, mode: 0, travelMode: IOptionTrip.Car } });
    onEditMode.current = { isEditing: false, id: "" };
  }, []);

  const handleOpenDeleteTripDialog = (tripId: string | undefined) => {
    if (!tripId) return;
    dispatchCtx(
      setModalWithData({
        isOpen: IDialogs.DELETE_TRIP,
        data: { currentDisplayedTrip: visibleTrip.current, tripId, googleTravel },
      })
    );
  };

  const handleEditMode = (tripId: string | undefined) => {
    if (!tripId) return;
    const selectedTrip = getTripById(tripId);
    if (!selectedTrip || !selectedTrip.title) return;
    isSwitchingToEditMode.current = true;
    onEditMode.current = {
      isEditing: true,
      id: tripId,
    };
    dispatch({ type: IAction.MODIFY_MODE, payload: { ...state, mode: 1 } });
    updateGoogleAddress(selectedTrip);
    setValue("title", selectedTrip.title);
    setValue("description", selectedTrip.description as string);
  };

  const handleShow = (id: string) => {
    if (id === visibleTrip.current) {
      googleTravel.clearTrip();
      Object.values(googleInputs).forEach((i) => i.input.reset());
      visibleTrip.current = "";
      return;
    }
    const selectedTrip = getTripById(id);
    if (!selectedTrip) return;
    updateGoogleAddress(selectedTrip);
  };

  const updateGoogleAddress = (data: ITrip) => {
    const { from, to } = data;
    if (!from || !to) return;

    googleInputFrom.setValues({
      fullAddress: from.address,
      lat: from.lat,
      lng: from.lng,
    });

    googleInputTo.setValues({
      fullAddress: to.address,
      lat: to.lat,
      lng: to.lng,
    });

    visibleTrip.current = data.id as string;
  };

  return {
    ...state,
    t,
    toggleMode,
    handleSubmit,
    onSubmit,
    register,
    errors,
    watch,
    handleAddress,
    addressFromInpRef,
    addressToInpRef,
    googleInputs,
    trips: queryTrip.data ?? [],
    handleOpenDeleteTripDialog,
    handleEditMode,
    onClose,
    onEditMode: onEditMode.current.isEditing,
    themeClass,
    control,
    googleTravel,
    handleShow,
    visibleTrip: visibleTrip.current,
    isPremium,
    title: watch("title"),
    description: watch("description"),
    limitIsReached,
  };
};
