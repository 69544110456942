import { WidgetSettingsForm } from "@witivio_teamspro/witivio-react-toolkit";
import { useState } from "react";
import { IMap } from "../../types/IMap/IMap";
import axios from "axios";
import { useHomeProToken } from "front";

export const WidgetConfiguration = () => {
  // Hooks
  const { token } = useHomeProToken();

  // States
  const [map, setMap] = useState<IMap>();

  const checkMapIdExists = async (mapId: string): Promise<boolean> => {
    const map = await getMapAction(mapId);
    setMap(map);
    return true;
  };

  const getTitle = (): string => "Map Pro";
  const getChannelId = (): string => map?.channelId ?? "";

  const getMapAction = async (id: string): Promise<IMap> => {
    const res = await axios.get(`/api/v1/maps/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  };

  return (
    <WidgetSettingsForm
      appName={"Map Pro"}
      checkExists={checkMapIdExists}
      manifestId={"246ac3e7-7140-4da8-aec0-f2dafea37778"}
      getWidgetTitle={getTitle}
      getChannelId={getChannelId}
    />
  );
};
