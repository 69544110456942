import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getSliceSelector, ISubscription } from "front";

const initialState: {
  subscription: ISubscription | undefined;
  maxPlace: number;
  maxTrip: number;
} = {
  subscription: undefined,
  maxPlace: 0,
  maxTrip: 0,
};

const limitationSlice = createSlice({
  name: "limitation",
  initialState: initialState,
  reducers: {
    setLimitation(state, action: PayloadAction<typeof initialState>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { setLimitation } = limitationSlice.actions;
export const useLimitationSelector = getSliceSelector(limitationSlice);
export default limitationSlice.reducer;
