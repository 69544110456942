import { AxiosConfig, tryCatch } from "front";
import { IContact } from "types/IContact/IContact";

export const isContactExistAction = async (id: string): Promise<void> => {
  const res = await AxiosConfig.instance.get(`/internal/contacts/${id}/check`);
  if (res.status !== 200) throw new Error("Wrong app ID");
};

const getAllContactsAction = async ({ queryKey }: { queryKey: any[] }): Promise<IContact[]> => {
  const contactListIds = queryKey[1];
  const { skip, take, searchPattern, firstLetter } = queryKey[2];
  const tenantId = queryKey[3];
  const query = `skip=${skip}&take=${take}&searchPattern=${searchPattern}&firstLetter=${firstLetter}`;
  const res = await AxiosConfig.instance.get(`/internal/contacts/tenantId/${tenantId}?ids=${contactListIds}&${query}`);
  return res.data;
};

export const isContactExist = tryCatch(isContactExistAction);
export const getAllContacts = tryCatch(getAllContactsAction);
