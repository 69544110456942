export var DisplayMode;
(function (DisplayMode) {
    DisplayMode[DisplayMode["Month"] = 0] = "Month";
    DisplayMode[DisplayMode["Week"] = 1] = "Week";
    DisplayMode[DisplayMode["Day"] = 2] = "Day";
    DisplayMode[DisplayMode["Loading"] = 3] = "Loading";
})(DisplayMode || (DisplayMode = {}));
export var SecurityMode;
(function (SecurityMode) {
    SecurityMode[SecurityMode["AllUserCanEdit"] = 0] = "AllUserCanEdit";
    SecurityMode[SecurityMode["OnlyMeCanEdit"] = 1] = "OnlyMeCanEdit";
    SecurityMode[SecurityMode["OnlyOwnersCanEdit"] = 2] = "OnlyOwnersCanEdit";
})(SecurityMode || (SecurityMode = {}));
export var ResourceMode;
(function (ResourceMode) {
    ResourceMode[ResourceMode["Standard"] = 0] = "Standard";
    ResourceMode[ResourceMode["Resources"] = 1] = "Resources";
})(ResourceMode || (ResourceMode = {}));
export var MoreOptionsAction;
(function (MoreOptionsAction) {
    MoreOptionsAction[MoreOptionsAction["Print"] = 0] = "Print";
    MoreOptionsAction[MoreOptionsAction["Settings"] = 1] = "Settings";
    MoreOptionsAction[MoreOptionsAction["Permissions"] = 2] = "Permissions";
    MoreOptionsAction[MoreOptionsAction["Tags"] = 3] = "Tags";
    MoreOptionsAction[MoreOptionsAction["GetId"] = 4] = "GetId";
    MoreOptionsAction[MoreOptionsAction["Sync"] = 5] = "Sync";
    MoreOptionsAction[MoreOptionsAction["Rooms"] = 6] = "Rooms";
    MoreOptionsAction[MoreOptionsAction["MeetingScheduler"] = 7] = "MeetingScheduler";
    MoreOptionsAction[MoreOptionsAction["Notifications"] = 8] = "Notifications";
    MoreOptionsAction[MoreOptionsAction["Upgrade"] = 9] = "Upgrade";
})(MoreOptionsAction || (MoreOptionsAction = {}));
export var HelpOptionsAction;
(function (HelpOptionsAction) {
    HelpOptionsAction[HelpOptionsAction["About"] = 0] = "About";
    HelpOptionsAction[HelpOptionsAction["TeamsPro"] = 1] = "TeamsPro";
    HelpOptionsAction[HelpOptionsAction["HelpCenter"] = 2] = "HelpCenter";
    HelpOptionsAction[HelpOptionsAction["SuggestFeature"] = 3] = "SuggestFeature";
    HelpOptionsAction[HelpOptionsAction["WhatsNewsOn"] = 4] = "WhatsNewsOn";
})(HelpOptionsAction || (HelpOptionsAction = {}));
export var IOptionRecurrence;
(function (IOptionRecurrence) {
    IOptionRecurrence[IOptionRecurrence["NotRepeat"] = 0] = "NotRepeat";
    IOptionRecurrence[IOptionRecurrence["Workweek"] = 1] = "Workweek";
    IOptionRecurrence[IOptionRecurrence["Daily"] = 2] = "Daily";
    IOptionRecurrence[IOptionRecurrence["Weekly"] = 3] = "Weekly";
    IOptionRecurrence[IOptionRecurrence["Monthly"] = 4] = "Monthly";
    IOptionRecurrence[IOptionRecurrence["Yearly"] = 5] = "Yearly";
    IOptionRecurrence[IOptionRecurrence["Custom"] = 6] = "Custom";
})(IOptionRecurrence || (IOptionRecurrence = {}));
export var IOptionFrequencyRecurrence;
(function (IOptionFrequencyRecurrence) {
    IOptionFrequencyRecurrence[IOptionFrequencyRecurrence["Day"] = 0] = "Day";
    IOptionFrequencyRecurrence[IOptionFrequencyRecurrence["Week"] = 1] = "Week";
    IOptionFrequencyRecurrence[IOptionFrequencyRecurrence["Month"] = 2] = "Month";
    IOptionFrequencyRecurrence[IOptionFrequencyRecurrence["Year"] = 3] = "Year";
})(IOptionFrequencyRecurrence || (IOptionFrequencyRecurrence = {}));
export var IOptionDayStartCustomRecurrence;
(function (IOptionDayStartCustomRecurrence) {
    IOptionDayStartCustomRecurrence[IOptionDayStartCustomRecurrence["First"] = 0] = "First";
    IOptionDayStartCustomRecurrence[IOptionDayStartCustomRecurrence["Second"] = 1] = "Second";
    IOptionDayStartCustomRecurrence[IOptionDayStartCustomRecurrence["Third"] = 2] = "Third";
    IOptionDayStartCustomRecurrence[IOptionDayStartCustomRecurrence["Fourth"] = 3] = "Fourth";
    IOptionDayStartCustomRecurrence[IOptionDayStartCustomRecurrence["Last"] = 4] = "Last";
})(IOptionDayStartCustomRecurrence || (IOptionDayStartCustomRecurrence = {}));
export var IOptionDayCustomRecurrence;
(function (IOptionDayCustomRecurrence) {
    IOptionDayCustomRecurrence[IOptionDayCustomRecurrence["Monday"] = 0] = "Monday";
    IOptionDayCustomRecurrence[IOptionDayCustomRecurrence["Tuesday"] = 1] = "Tuesday";
    IOptionDayCustomRecurrence[IOptionDayCustomRecurrence["Wednesday"] = 2] = "Wednesday";
    IOptionDayCustomRecurrence[IOptionDayCustomRecurrence["Thursday"] = 3] = "Thursday";
    IOptionDayCustomRecurrence[IOptionDayCustomRecurrence["Friday"] = 4] = "Friday";
    IOptionDayCustomRecurrence[IOptionDayCustomRecurrence["Saturday"] = 5] = "Saturday";
    IOptionDayCustomRecurrence[IOptionDayCustomRecurrence["Sunday"] = 6] = "Sunday";
})(IOptionDayCustomRecurrence || (IOptionDayCustomRecurrence = {}));
export var IEventSelection;
(function (IEventSelection) {
    IEventSelection[IEventSelection["Occurrence"] = 0] = "Occurrence";
    IEventSelection[IEventSelection["Serie"] = 1] = "Serie";
})(IEventSelection || (IEventSelection = {}));
export var IDuration;
(function (IDuration) {
    IDuration["Fifteen"] = "15";
    IDuration["Thirty"] = "30";
    IDuration["FourtyFive"] = "45";
    IDuration["Sixty"] = "60";
    IDuration["SeventyFive"] = "75";
    IDuration["Ninety"] = "90";
    IDuration["HundredFive"] = "105";
    IDuration["HundredTwenty"] = "120";
    IDuration["HundredThirtyFive"] = "135";
    IDuration["HundredFifty"] = "150";
})(IDuration || (IDuration = {}));
