import { PublicHoliday } from "../../../apis/apiGoogleCalendar";
import { capitalizeString, generateDropdownItems } from "../../event/eventUtils";
import { CompareModule, OverwriteObjectDataWithWatchData, findKeyPath, getKeyRegistered, manageWatchHookForm, openPremiumDialog, setValuesHookForm, useMsTeamsSelector, usePermissionsSelector, useTranslate, userHasRightsToEdit } from "front";
import { DisplayMode, SecurityMode } from "../../../interfaces/Enums";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useCalendarCache } from "../../../services/cacheService/calendarCache";
import { translations } from "../../../translations";
import { useDispatch, useSelector } from "react-redux";
import { setNavbar } from "../../../redux/reducers/navbarReducer";
import { useForm } from "react-hook-form";
export const weekDayNames = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];
export const labelsMaxWidth = "180px";
export const useLogic = (props) => {
    const t = useTranslate(translations);
    const publicHolidaysRegionsItems = Object.keys(PublicHoliday).map((key) => ({ header: t(key), value: key }));
    const { userPerm } = usePermissionsSelector("userPerm");
    const appInsightInstance = useSelector((s) => s.infosApp.appInsightInstance);
    const { calendar, mutateCalendar, mutateCalendarIsLoading } = useCalendarCache();
    const { userMail } = useMsTeamsSelector("userMail");
    const dispatch = useDispatch();
    const { register, handleSubmit, watch, setValue, control } = useForm();
    const [holidaysSearchQuery, setHolidaysSearchQuery] = useState("");
    const securityModes = generateDropdownItems(SecurityMode, ["InfoUser", "OnlyMe"]);
    const displayModes = generateDropdownItems(DisplayMode, ["Month", "Week", "Day"]);
    const calendarFilter = {
        showWeekend: calendar.showWeekend,
        showWeekNumbers: calendar.showWeekNumbers,
        firstDay: calendar.firstDay,
        businessDays: calendar.businessDays,
        publicHolidays: calendar.publicHolidays,
        defaultDisplayMode: calendar.defaultDisplayMode,
    };
    const fieldToRegister = getKeyRegistered(calendarFilter);
    const watchData = manageWatchHookForm(watch, fieldToRegister);
    /**
   * Get hour label from value
   */
    const [state, setState] = useState({ loading: true });
    useEffect(() => {
        if (!calendar || !userPerm['id'])
            return;
        setState({
            isSaving: false,
            weekdays: moment
                .weekdays()
                .map((day) => t(capitalizeString(day))),
            requireRefresh: false,
            readOnly: !userHasRightsToEdit(userPerm),
            loading: false
        });
        setValuesHookForm(setValue, fieldToRegister, calendar);
    }, [calendar, userPerm]);
    /**
   * Save settings
   */
    const saveSettings = async () => {
        const stateObject = JSON.parse(JSON.stringify({ ...calendar, updatedBy: userMail }));
        ;
        OverwriteObjectDataWithWatchData(fieldToRegister, watchData, stateObject);
        setState((prev) => ({ ...prev, isSaving: true, object: stateObject }));
        mutateCalendar(stateObject, { onSuccess: async () => {
                dispatch(setNavbar({ displayMode: stateObject.defaultDisplayMode }));
                await props.refreshPublicHolidays();
                props.onHide();
            } });
    };
    const showPremiumDialog = () => dispatch(openPremiumDialog(appInsightInstance));
    const canSave = useMemo(() => {
        if (!calendar || state.loading)
            return false;
        const actualCalendarSettings = JSON.parse(JSON.stringify(calendar));
        ;
        OverwriteObjectDataWithWatchData(fieldToRegister, watchData, actualCalendarSettings);
        return (!CompareModule.areObjectsEqual(calendar, actualCalendarSettings));
    }, [calendar, watchData]);
    /**
     * On toggle show weekend
     * @param event
     * @param data
     */
    const onToggleShowWeekend = (event, data) => {
        const value = data?.checked ?? false;
        setValue(findKeyPath(calendarFilter, 'showWeekend'), value);
    };
    /**
     * On toggle show week numbers
     * @param event
     * @param data
     */
    const onToggleShowWeekNumbers = (event, data) => {
        const value = data?.checked ?? false;
        setValue(findKeyPath(calendarFilter, 'showWeekNumbers'), value);
    };
    /**
   * Format display mode
   */
    const formatDisplayMode = () => {
        return displayModes.find((f) => f.value === watchData[findKeyPath(calendarFilter, 'defaultDisplayMode')]);
    };
    /**
 * On change display mode
 * @param event
 * @param data
 */
    const onChangeDisplayMode = (event, data) => {
        let displayMode = DisplayMode.Month;
        if (data.value !== undefined) {
            let idx = displayModes.findIndex((w) => w === data.value);
            displayMode = idx;
        }
        setValue(findKeyPath(calendarFilter, 'defaultDisplayMode'), displayMode);
        setState((prev) => ({ ...prev,
            requireRefresh: true,
        }));
    };
    /**
   * Format first day
   */
    const formatFirstDay = () => {
        return capitalizeString(state.weekdays[watchData[findKeyPath(calendarFilter, 'firstDay')]]);
    };
    /**
     * On change first day of week
     * @param event
     * @param data
     */
    const onChangeFirstDayWeek = (event, data) => {
        if (data.value !== undefined) {
            let idx = state.weekdays.findIndex((w) => w === data.value);
            setValue(findKeyPath(calendarFilter, 'firstDay'), idx);
            setState((prev) => ({ ...prev,
                requireRefresh: true,
            }));
        }
    };
    return {
        state,
        saveSettings,
        onToggleShowWeekend,
        onToggleShowWeekNumbers,
        formatDisplayMode,
        onChangeDisplayMode,
        formatFirstDay,
        onChangeFirstDayWeek,
        showPremiumDialog,
        publicHolidaysRegionsItems,
        securityModes,
        displayModes,
        setState,
        register,
        handleSubmit,
        setValue,
        calendar,
        watchData,
        mutateCalendarIsLoading,
        canSave,
        fieldToRegister,
        calendarFilter,
        control,
        holidaysSearchQuery,
        setHolidaysSearchQuery
    };
};
