import { AxiosConfig, tryCatch } from "front";
import { ITrip } from "../../types/ITrip/ITrips";

export const getTripsAction = async ({ queryKey }: { queryKey: any[] }): Promise<ITrip[]> => {
  const [_, mapId, tenantId] = queryKey;
  const res = await AxiosConfig.instance.get(`/maps/${mapId}/trips/tenantId/${tenantId}`);
  return res.data;
};

export const createTripAction = async (data: { trip: ITrip }): Promise<ITrip> => {
  const res = await AxiosConfig.instance.post(`/maps/${data.trip.mapId}/trips`, data.trip);
  return res.data;
};

export const editTripAction = async (data: { trip: ITrip }): Promise<void> => {
  await AxiosConfig.instance.put(`/maps/${data.trip.mapId}/trips/${data.trip.id}`, data.trip);
};

export const deleteTripAction = async (data: { mapId: string; tripId: string }): Promise<void> => {
  await AxiosConfig.instance.delete(`/maps/${data.mapId}/trips/${data.tripId}`);
};

export const getTrips = tryCatch(getTripsAction);
export const createTrip = tryCatch(createTripAction);
export const editTrip = tryCatch(editTripAction);
export const deleteTrip = tryCatch(deleteTripAction);
