import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, Flex, Header, Text } from "@fluentui/react-northstar";
import { useOptionsPanelLogic } from "./OptionsPanel.logic";
import { findKeyPath, useMsTeamsSelector, usePermissionsSelector, useTranslate } from "front";
import { translations } from "../../../translations";
import { Controller } from "react-hook-form";
import { constInputsTag } from "../../../const/InputsTag";
import { InputTagName } from "../../../interfaces/InputTag";
export const disabledTag = [InputTagName.Email, InputTagName.FirstName, InputTagName.LastName];
export const OptionsPanel = (props) => {
    const logic = useOptionsPanelLogic(props);
    const t = useTranslate(translations);
    const { userPerm } = usePermissionsSelector("userPerm");
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    const questionsListCheckbox = () => (_jsx(Flex, { column: true, children: constInputsTag.map((tag, index) => (_jsx(Controller, { name: findKeyPath(props.keysRegistered, "inputsTag"), control: props.control, render: ({ field }) => (_jsx(Checkbox, { label: t("MeetingScheduler:InputsTag:" + tag.name), disabled: disabledTag.includes(tag.name) || !userPerm.perm.allowEditMeetingScheduler, checked: props.watchData[findKeyPath(props.keysRegistered, 'inputsTag')][index].isChecked, onChange: (_, data) => { logic.onToggleInputsTag(index, data.checked, field); } }, "inputs_tag_" + tag.name)) }, "inputs_tag_controller_" + tag.name))) }));
    return (_jsxs(Flex, { column: true, fill: true, gap: "gap.medium", styles: {
            display: "flex",
            height: "100%",
            width: "100%",
            marginLeft: "20px",
        }, children: [_jsx(Header, { as: "h4", content: t("MeetingScheduler:BookingPageOptionsTitle"), styles: { paddingBottom: "10px", width: "100%", whiteSpace: 'pre-wrap', wordWrap: "break-word" } }), _jsxs(Flex, { column: true, gap: "gap.small", styles: { marginLeft: "20px" }, children: [_jsx(Text, { content: t("MeetingScheduler:InputsTagComponent"), weight: "semibold" }), questionsListCheckbox()] })] }));
};
