import { getEvents } from "../../apis/apiEvent";
import { fetchPublicHolidays } from "../../apis/apiGoogleCalendar";
import { DisplayMode, IOptionRecurrence } from "../../interfaces/Enums";
import { Frequency, RRule as RRulePlugin } from "rrule";
import moment from "moment-timezone";
import html2canvas from "html2canvas";
import { calculateDatesRangeDuration } from "../event/eventUtils";
/**
 * Create event from an existing event
 * @param eventFullcalendar fullcalendar event
 * @param existingEvent existing event
 * @param calendarTimeZone calendar timezone
 */
export const createEventWithID = (eventFullcalendar, existingEvent, calendarTimeZone) => {
    const event = { ...existingEvent };
    if (!!eventFullcalendar.newResource)
        event.resourceId = eventFullcalendar.newResource.id;
    if (!!calendarTimeZone) {
        if (event.allDay) {
            event.startDate = moment.utc(eventFullcalendar.event.start).format();
            event.endDate = !!eventFullcalendar.event.end ?
                moment.utc(eventFullcalendar.event.end).format()
                :
                    moment.utc(eventFullcalendar.event.start).add(1, "days").format();
            event.startDate = convertUTCDateToTimezone(event.startDate, event.timeZone, false).format();
            event.endDate = convertUTCDateToTimezone(event.endDate, event.timeZone, false).add(-1, "minutes").format();
            const currentTimeZoneOffset = moment().tz(calendarTimeZone).utcOffset();
            event.startDate = moment.utc(event.startDate).add(currentTimeZoneOffset, "minutes").format();
            event.endDate = moment.utc(event.endDate).add(currentTimeZoneOffset, "minutes").format();
            event.startDate = convertUTCDateToTimezone(event.startDate, event.timeZone).format();
            event.endDate = convertUTCDateToTimezone(event.endDate, event.timeZone).format();
        }
        else {
            event.startDate = convertUTCDateToTimezone(eventFullcalendar.event.start, event.timeZone, false).format();
            event.endDate = convertUTCDateToTimezone(eventFullcalendar.event.end ?? "", event.timeZone, false).format();
        }
    }
    else {
        event.startDate = moment.utc(event.startDate).format();
        event.endDate = moment.utc(event.endDate).format();
        const isUtcStartDateHourAtMidnight = moment.utc(event.startDate).format("HH:mm:ss") === "00:00:00";
        const isUtcEndDateHourAtMidnight = moment.utc(event.endDate).format("HH:mm:ss") === "00:00:00";
        // Only applies for old events with endDate finishing at midnight
        // new events are now finishing at 23:59h
        if (isUtcStartDateHourAtMidnight && isUtcEndDateHourAtMidnight)
            event.endDate = moment.utc(event.endDate).add(-1, "minutes").format();
        if (event.allDay) {
            event.startDate = convertUTCDateToTimezone(event.startDate, event.timeZone, false).format();
            event.endDate = convertUTCDateToTimezone(event.endDate, event.timeZone, false).format();
        }
    }
    return event;
};
/**
 * Convert HTML element to png
 * @param selector css selector
 */
export const convertHtmlElementToPng = async (selector, defaultTheme) => {
    let backGroundCanvas = (defaultTheme ? "#fff" : "#000");
    let fullcalendarHtmlElement = document.querySelector(selector);
    if (!fullcalendarHtmlElement)
        return "";
    let base64 = await html2canvas(fullcalendarHtmlElement, {
        backgroundColor: backGroundCanvas,
        onclone: doc => {
            const element = doc.querySelector(selector);
            if (!element)
                return;
            element.id = "fullcalendar-print";
        }
    });
    return base64.toDataURL("image/png");
};
/**
 * Create a new event from fullcalendar event
 * @param eventFullcalendar fullcalendar event
 * @param calendarId calendar id
 * @param userUpn user upn
 * @param calendarTimezone calendar timezone
 */
export const createEventWithoutId = (eventFullcalendar, calendarId, userUpn, calendarTimezone) => {
    let isResourceEvent = !!eventFullcalendar.resource;
    let startDate;
    let endDate;
    let allDay = eventFullcalendar.allDay;
    const format = "YYYY-MM-DDTHH:mm:00";
    if (allDay) {
        startDate = moment(eventFullcalendar.startStr).set({ hours: 0, minutes: 0 }).format(format);
        endDate = moment(eventFullcalendar.endStr).set({ hours: 0, minutes: 0 }).add(-1, "minutes").format(format);
    }
    else {
        startDate = moment(eventFullcalendar.startStr).toISOString();
        endDate = moment(eventFullcalendar.endStr).toISOString();
    }
    const eventData = {
        modeRecurrence: IOptionRecurrence.NotRepeat,
        isRecurrenceValid: true,
        startDate,
        endDate,
        calendarId: calendarId,
        title: eventFullcalendar.title ? eventFullcalendar.title : "",
        created: moment().toDate(),
        //updated: null,
        createdBy: userUpn,
        allDay,
        timeZone: calendarTimezone,
        tags: [],
        resourceId: isResourceEvent ? eventFullcalendar.resource._resource.id : undefined
    };
    return eventData;
};
export const getFullcalendarDisplayMode = (displayMode, isResourceMode) => {
    if (isResourceMode) {
        switch (displayMode) {
            case DisplayMode.Day:
                return "resourceTimelineDay";
            case DisplayMode.Month:
                return "resourceTimelineMonth";
            case DisplayMode.Week:
                return "resourceTimelineWeek";
            default:
                return "resourceTimelineMonth";
        }
    }
    else {
        switch (displayMode) {
            case DisplayMode.Day:
                return "timeGridDay";
            case DisplayMode.Month:
                return "dayGridMonth";
            case DisplayMode.Week:
                return "timeGridWeek";
            default:
                return "dayGridMonth";
        }
    }
};
/**
 * Get local date with rounded hour
 * @param hoursToAdd
 */
export const getLocalDateRoundHour = (hoursToAdd = 0) => {
    const date = moment().add({ hour: hoursToAdd }).toDate();
    date.setMinutes(Math.round(date.getMinutes() / 30) * 30);
    return moment(date).format("YYYY-MM-DDTHH:00:00");
};
/**
 * Initialize a new event
 */
export const initializeNewEvent = (calendar, userUpn, calendarTimezone, resources) => {
    const startDate = getLocalDateRoundHour();
    const endDate = getLocalDateRoundHour(1);
    const eventData = {
        modeRecurrence: IOptionRecurrence.NotRepeat,
        isRecurrenceValid: true,
        title: "",
        calendarId: calendar.id,
        startDate: startDate,
        endDate: endDate,
        allDay: false,
        created: moment().toDate(),
        //updated: null,
        createdBy: userUpn,
        timeZone: calendarTimezone,
        tags: [],
        resourceId: calendar.isResourceMode ? (resources && resources.length > 0 ? resources[0]?.id : undefined) : undefined
    };
    return eventData;
};
export const loadEvents = async ({ queryKey }) => {
    const { calendar, start, end, currentTimezone, tagFilter, locale, googleMapApiKey, isPlatinum } = queryKey[1];
    if (!calendar?.id || calendar?.isResourceMode == undefined)
        return new Array();
    const events = await getEvents(calendar.id, calendar.isResourceMode, start, end, tagFilter, currentTimezone);
    const publicHolidays = await fetchPublicHolidays(calendar.publicHolidays, currentTimezone, locale, start, end, googleMapApiKey);
    let eventsFiltered = events;
    if (eventsFiltered && isPlatinum !== true)
        eventsFiltered = [...events?.filter((e) => e.isExternal !== true)];
    return [...eventsFiltered, ...publicHolidays];
};
/**
 * Convert UTC date to timezone
 * @param utcDate UTC date
 * @param timeZone timeZone
 * @param keepLocalTime keep local time ?
 */
export function convertUTCDateToTimezone(utcDate, timeZone, keepLocalTime = true) {
    return moment.utc(utcDate).tz(timeZone, keepLocalTime);
}
/**
 * Convert local date to timezone
 * @param localDate local date
 * @param timeZone timeZone
 * @param keepLocalTime keep local time ?
 */
export function convertLocalDateToTimezone(localDate, timeZone, keepLocalTime = true) {
    return moment(localDate).tz(timeZone, keepLocalTime);
}
/**
 * Format database event to compatible fullcalendar event
 * @param databaseEvent event to format
 * @param allEvents all available events
 */
export function formatDatabaseEventToFullCalendar(databaseEvent, allEvents, timeZone) {
    const event = { ...databaseEvent };
    event.color = event.color ?? "#6264a7";
    if (event.emoji)
        event.title = event.emoji + " " + event.title;
    if (event.files && event.files.length > 0)
        event.title = "📎 " + event.title;
    if (event.allDay) {
        event.startDate = moment(event.startDate).tz(event.timeZone).format();
        event.endDate = moment(event.endDate).tz(event.timeZone).format();
        const isLocalStartDateHourAtMidnight = convertUTCDateToTimezone(event.startDate, event.timeZone, false)
            .format("HH:mm:ss") === "00:00:00";
        const isLocalEndDateAtMidnightMinusOneMinute = convertUTCDateToTimezone(event.endDate, event.timeZone, false)
            .format("HH:mm:ss") === "23:59:00";
        // Add one minute to events with good timezone, finishing at midnight minus one minute
        // We add one minute because fullcalendar end date is exclusive
        // https://fullcalendar.io/docs/event-parsing
        if (isLocalStartDateHourAtMidnight && isLocalEndDateAtMidnightMinusOneMinute) {
            event.endDate = moment(event.endDate).tz(event.timeZone).add(1, "minutes").format();
        }
        // We remove time from date to avoid all day events to shift
        event.startDate = event.startDate.substring(0, event.startDate.indexOf("T"));
        event.endDate = event.endDate.substring(0, event.endDate.indexOf("T"));
    }
    //--gestion des excludeDate
    let exDate = [];
    allEvents.forEach(e => {
        if (e.groupId == event.id) {
            const format = e.allDay ? "YYYYMMDD" : "YYYYMMDDTHHmmss";
            const eDate = e.excludeDate?.map((ex) => {
                if (event.allDay) {
                    return moment.utc(ex).format(format);
                }
                return moment(deleteDateLocalTimezone(moment(ex).toDate(), timeZone)).format(format);
            });
            exDate = [...exDate, ...eDate ?? []];
        }
    });
    //--fin de gestion des excludeDate
    return {
        id: event.id,
        calendarName: event.calendarName,
        resourceId: event.resourceId,
        description: event.description,
        start: event.startDate,
        end: event.endDate,
        duration: calculateDatesRangeDuration(event.startDate, event.endDate),
        title: event.title,
        address: event.address,
        color: event.color,
        allDay: event.allDay,
        modeRecurrence: event.modeRecurrence,
        rrule: event.rrule ? { ...event.rrule,
            dtstart: event.rrule?.dtstart ?
                event.allDay ?
                    event.rrule.dtstart
                    :
                        deleteDateLocalTimezone(moment(event.rrule.dtstart).toDate(), timeZone)
                :
                    undefined,
            // ici on gère le cas où l'utilisateur a sélectionné plusieurs jours dans le mois ou la semaine en tant qu'occurence
            // et qu'il a décidé d'un certains nombre d'occurence de cet évènement. Pour faire en sorte d'avoir le résultat
            // attendu par l'utilisateur on modifie le count en le multipliant avec le nombre de jours sélectionné. On appelle ça
            // un nombre de série et non plus un nombre d'occurence.
            count: event.rrule.freq === "weekly" && event.rrule.count && event.rrule.byweekday ?
                event.rrule.count * event.rrule.byweekday.length
                : event.rrule.freq === "monthly" && event.rrule.count && event.rrule.bymonthday ?
                    event.rrule.count * event.rrule.bymonthday.length
                    : event.rrule.count,
        }
            : undefined,
        exdate: exDate,
        created: event.created,
        updated: event.updated,
        createdBy: event.createdBy,
        updatedBy: event.updatedBy ? event.updatedBy : null,
        timeZone: event.timeZone,
        textColor: getTextColor(event.color),
        isRecurrenceValid: event.isRecurrenceValid,
        groupId: event.groupId,
        tags: event.tags,
        display: event.calendarId?.startsWith("google_calendar") ? "background" : "auto",
        participants: event.participants ?? [],
        readOnly: event.readOnly
    };
}
/**
 * Get rrule exclude date string from event
 * @param event event
 * @param allEvents all available events
 */
export const getRruleAsStringWithExcludedDates = (event, allEvents, appTimezone) => {
    let rrule = formatEventRRuleToRRulePluginForCalendly(event);
    if (!rrule)
        return;
    let rruleString = rrule.toString();
    if (event.modeRecurrence === IOptionRecurrence.NotRepeat)
        return { rruleString, customOccurencesExludedDates: "", excludeDates: "" };
    const customOccurencesExludedDates = allEvents
        .filter(e => e.groupId === event.id && e.id !== event.id)
        .map(e => e.excludeDate?.map(d => generateRruleExcludeDateString(d, event.timeZone, event.allDay)).join("")).join("");
    const excludeDates = event.excludeDate?.map(d => generateRruleExcludeDateString(d, event.timeZone, event.allDay)).join("") ?? "";
    return { rruleString, customOccurencesExludedDates, excludeDates };
};
export const fixEvents = (e, calendarTimeZone) => {
    // Fix events with rrule object but no recurrence
    if (e.modeRecurrence === IOptionRecurrence.NotRepeat) {
        e.rrule = undefined;
        e.isRecurrenceValid = true;
    }
    // Fix events without timezone
    if (!e.timeZone) {
        e.timeZone = calendarTimeZone;
    }
    // Fix event groupId for recurrences without group id
    if (e.modeRecurrence !== IOptionRecurrence.NotRepeat && !!e.id) {
        e.groupId = e.id;
    }
    return e;
};
/**
 * Generate rrule exclude date
 * @param excludeDate date to exclude
 * @param timeZone timezone
 * @param allDay is all day ?
 */
const generateRruleExcludeDateString = (excludeDate, timeZone, allDay) => {
    const format = "YYYYMMDDTHHmmss";
    const newExcludeDate = excludeDate.replace(" ", "T").replace(/\//g, "").replace(/:/g, "") + "Z";
    const date = moment(excludeDate).utc().format(format);
    return `\nEXDATE:${allDay ? date : newExcludeDate}`;
};
/**
 * Define text color from background
 * @param hexcolor hex color
 */
const getTextColor = (hexcolor) => {
    hexcolor = hexcolor.replace("#", "");
    const r = parseInt(hexcolor.substring(0, 2), 16);
    const g = parseInt(hexcolor.substring(2, 4), 16);
    const b = parseInt(hexcolor.substring(4, 6), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return (yiq >= 128) ? '#000' : '#FFF';
};
/**
 * Get rrule plugin frequency from event rrule
 * @param rrule
 */
export const getRRulePluginFrequencyFromEventRrule = (rrule) => {
    if (!rrule)
        rrule = {};
    switch (rrule.freq?.toUpperCase()) {
        case "DAILY":
            return Frequency.DAILY;
        case "WEEKLY":
            return Frequency.WEEKLY;
        case "MONTHLY":
            return Frequency.MONTHLY;
        case "YEARLY":
            return Frequency.YEARLY;
        default:
            return Frequency.WEEKLY;
    }
};
/**
 * Format event rrule to rrule plugin
 * @param event event
 */
export const formatEventRRuleToRRulePlugin = (event) => {
    if (!event.rrule)
        return null;
    let freqIndex = getRRulePluginFrequencyFromEventRrule(event.rrule);
    let byweekday = new Array();
    if (!!event.rrule.byweekday)
        byweekday.push(...event.rrule.byweekday.filter(i => i !== null && i !== undefined));
    let bymonthday = new Array();
    if (!!event.rrule.bymonthday)
        bymonthday.push(...event.rrule.bymonthday.filter(i => i !== null && i !== undefined));
    let byyearday = new Array();
    if (!!event.rrule.byyearday)
        byyearday.push(...event.rrule.byyearday.filter(i => i !== null && i !== undefined));
    // This is a fix for existing events with bad rrule config
    switch (event.modeRecurrence) {
        case IOptionRecurrence.Workweek:
            const startUTCTimezone = convertUTCDateToTimezone(event.startDate, "UTC", false).format("YYYY-MM-DD");
            const startEventTimezone = convertUTCDateToTimezone(event.startDate, event.timeZone, false).format("YYYY-MM-DD");
            if (startUTCTimezone === startEventTimezone)
                break;
            if (moment(startUTCTimezone) < moment(startEventTimezone)) {
                byweekday.unshift(6);
                byweekday.pop();
            }
            else if (moment(startUTCTimezone) > moment(startEventTimezone)) {
                byweekday.shift();
                byweekday.push(5);
            }
            break;
        case IOptionRecurrence.Weekly:
            byweekday = null;
            break;
        case IOptionRecurrence.Monthly:
            bymonthday = null;
            break;
        case IOptionRecurrence.Yearly:
            byyearday = null;
            break;
    }
    const rrulePlugin = new RRulePlugin({
        freq: freqIndex,
        interval: event.rrule.interval || 1,
        dtstart: event.rrule?.dtstart ?
            event.allDay ?
                moment(event.rrule.dtstart).toDate()
                :
                    deleteDateLocalTimezone(moment(event.rrule.dtstart).toDate(), event.timeZone)
            :
                null,
        until: event.rrule.until ? new Date(Date.UTC(moment(event.rrule.until).year(), moment(event.rrule.until).month(), moment(event.rrule.until).date(), moment(event.rrule.until).hours(), moment(event.rrule.until).minutes(), moment(event.rrule.until).seconds())) : null,
        byweekday: byweekday,
        bymonthday: bymonthday,
        byyearday: byyearday,
        count: event.modeRecurrence === IOptionRecurrence.Custom && event.rrule.count ? event.rrule.count : 0,
        bysetpos: event.modeRecurrence === IOptionRecurrence.Custom && event.rrule.bysetpos ? event.rrule.bysetpos : null,
    });
    return rrulePlugin;
};
/**
 * Format event rrule to rrule plugin
 * @param event event
 */
export const formatEventRRuleToRRulePluginForCalendly = (event) => {
    if (!event.rrule)
        return null;
    let freqIndex = getRRulePluginFrequencyFromEventRrule(event.rrule);
    let byweekday = new Array();
    if (!!event.rrule.byweekday) {
        byweekday.push(...event.rrule.byweekday.filter(i => i !== null && i !== undefined));
    }
    let bymonthday = new Array();
    if (!!event.rrule.bymonthday) {
        bymonthday.push(...event.rrule.bymonthday.filter(i => i !== null && i !== undefined));
        bymonthday = bymonthday.map((day) => day - 1);
    }
    let byyearday = new Array();
    if (!!event.rrule.byyearday)
        byyearday.push(...event.rrule.byyearday.filter(i => i !== null && i !== undefined));
    // This is a fix for existing events with bad rrule config
    switch (event.modeRecurrence) {
        case IOptionRecurrence.Workweek:
            const startUTCTimezone = convertUTCDateToTimezone(event.startDate, "UTC", false).format("YYYY-MM-DD");
            const startEventTimezone = convertUTCDateToTimezone(event.startDate, event.timeZone, false).format("YYYY-MM-DD");
            if (startUTCTimezone === startEventTimezone)
                break;
            if (moment(startUTCTimezone) < moment(startEventTimezone)) {
                byweekday.unshift(6);
                byweekday.pop();
            }
            else if (moment(startUTCTimezone) > moment(startEventTimezone)) {
                byweekday.shift();
                byweekday.push(5);
            }
            break;
        case IOptionRecurrence.Weekly:
            byweekday = null;
            break;
        case IOptionRecurrence.Monthly:
            bymonthday = null;
            break;
        case IOptionRecurrence.Yearly:
            byyearday = null;
            break;
    }
    const rrulePlugin = new RRulePlugin({
        freq: freqIndex,
        interval: event.rrule.interval || 1,
        dtstart: event.rrule?.dtstart ?
            event.allDay ?
                moment(event.rrule.dtstart).toDate()
                :
                    moment(event.rrule.dtstart).toDate()
            :
                null,
        until: event.rrule.until ? new Date(Date.UTC(moment(event.rrule.until).year(), moment(event.rrule.until).month(), moment(event.rrule.until).date(), moment(event.rrule.until).hours(), moment(event.rrule.until).minutes(), moment(event.rrule.until).seconds())) : null,
        byweekday: byweekday,
        bymonthday: bymonthday,
        byyearday: byyearday,
        //count: event.modeRecurrence === IOptionRecurrence.Custom && event.rrule.count  ? event.rrule.count : 0,
        bysetpos: event.modeRecurrence === IOptionRecurrence.Custom && event.rrule.bysetpos ? event.rrule.bysetpos : null,
    });
    return rrulePlugin;
};
/**
 * Delete local timezone from Date object
 * @param date date
 */
export const deleteDateLocalTimezone = (date, timeZone) => {
    const timeZoneOffset = moment(date).tz(timeZone).utcOffset() * 60000;
    return new Date(date.getTime() + timeZoneOffset);
};
