import React from "react";

const BikeIcon = () => {
  return (
    <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 19.3C4.07174 19.3 3.1815 18.9313 2.52513 18.2749C1.86875 17.6185 1.5 16.7283 1.5 15.8C1.5 14.8717 1.86875 13.9815 2.52513 13.3251C3.1815 12.6687 4.07174 12.3 5 12.3C5.92826 12.3 6.8185 12.6687 7.47487 13.3251C8.13125 13.9815 8.5 14.8717 8.5 15.8C8.5 16.7283 8.13125 17.6185 7.47487 18.2749C6.8185 18.9313 5.92826 19.3 5 19.3ZM5 10.8C3.67392 10.8 2.40215 11.3268 1.46447 12.2645C0.526784 13.2021 0 14.4739 0 15.8C0 17.1261 0.526784 18.3979 1.46447 19.3355C2.40215 20.2732 3.67392 20.8 5 20.8C6.32608 20.8 7.59785 20.2732 8.53553 19.3355C9.47322 18.3979 10 17.1261 10 15.8C10 14.4739 9.47322 13.2021 8.53553 12.2645C7.59785 11.3268 6.32608 10.8 5 10.8ZM14.8 8.8H19V7H15.8L13.86 3.73C13.57 3.23 13 2.9 12.4 2.9C11.93 2.9 11.5 3.09 11.2 3.4L7.5 7.09C7.19 7.4 7 7.8 7 8.3C7 8.93 7.33 9.46 7.85 9.77L11.2 11.8V16.8H13V10.3L10.75 8.65L13.07 6.3M19 19.3C18.0717 19.3 17.1815 18.9313 16.5251 18.2749C15.8687 17.6185 15.5 16.7283 15.5 15.8C15.5 14.8717 15.8687 13.9815 16.5251 13.3251C17.1815 12.6687 18.0717 12.3 19 12.3C19.9283 12.3 20.8185 12.6687 21.4749 13.3251C22.1313 13.9815 22.5 14.8717 22.5 15.8C22.5 16.7283 22.1313 17.6185 21.4749 18.2749C20.8185 18.9313 19.9283 19.3 19 19.3ZM19 10.8C17.6739 10.8 16.4021 11.3268 15.4645 12.2645C14.5268 13.2021 14 14.4739 14 15.8C14 17.1261 14.5268 18.3979 15.4645 19.3355C16.4021 20.2732 17.6739 20.8 19 20.8C19.6566 20.8 20.3068 20.6707 20.9134 20.4194C21.52 20.1681 22.0712 19.7998 22.5355 19.3355C22.9998 18.8712 23.3681 18.32 23.6194 17.7134C23.8707 17.1068 24 16.4566 24 15.8C24 15.1434 23.8707 14.4932 23.6194 13.8866C23.3681 13.28 22.9998 12.7288 22.5355 12.2645C22.0712 11.8002 21.52 11.4319 20.9134 11.1806C20.3068 10.9293 19.6566 10.8 19 10.8ZM16 3.6C17 3.6 17.8 2.8 17.8 1.8C17.8 0.8 17 0 16 0C15 0 14.2 0.8 14.2 1.8C14.2 2.8 15 3.6 16 3.6Z"
        fill="black"
      />
    </svg>
  );
};

export default BikeIcon;
