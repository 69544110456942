import React from "react";
import { Flex, Image, Text } from "@fluentui/react-northstar";
import { useConfigure } from "./Configure.logic";

const Configure = () => {
  const logic = useConfigure();
  return (
    <Flex styles={{ height: "100vh" }} column gap={"gap.small"} hAlign={"center"} vAlign={"center"}>
      <Image styles={{ height: "250px" }} src={"/assets/images/config.svg"} />
      <Text weight={"semibold"} size={"large"} content={logic.t("MessageConfiguration")} />
      <Text weight={"light"} content={logic.t("MessageConfigurationSubtitle")} />
    </Flex>
  );
};

export default Configure;
