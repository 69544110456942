import { Button, CloseIcon, Dialog, Flex, Text } from "@fluentui/react-northstar";
import React, { memo, useMemo } from "react";
import { useValidateCancelDialog } from "./ValidateCancelDialog.logic";

const ValidateCancelDialog = memo(() => {
  const logic = useValidateCancelDialog();

  const content = useMemo(
    () => (
      <Flex column gap="gap.medium" style={{ padding: ".5rem 0" }} data-testid="validateCancelDialog-content">
        <Text content={`${logic.t("ContentDiscardModal")}`} />
      </Flex>
    ),
    []
  );

  const footer = useMemo(
    () => (
      <Flex hAlign="end">
        <Button content={logic.t("ButtonCancelDiscard")} onClick={logic.handleSubmit} style={{ marginRight: ".5rem" }} />
        <Button primary content={logic.t("ButtonContinueDiscard")} onClick={logic.onClose} />
      </Flex>
    ),
    []
  );

  return (
    <Dialog
      header={logic.t("HeaderDiscardModal")}
      headerAction={{ icon: <CloseIcon />, title: "Close", onClick: logic.onClose }}
      content={content}
      footer={footer}
      defaultOpen={true}
      onCancel={logic.onClose}
      closeOnOutsideClick
    />
  );
});

export default ValidateCancelDialog;
