import { AxiosConfig, ISubscription, tryCatch } from "front";
import { IMap } from "../../types/IMap/IMap";
import * as microsoftTeams from "@microsoft/teams-js";

const ROUTE = "/maps";

export const createMapAction = async (data: IMap) => {
  const res = await AxiosConfig.instance.post(`${ROUTE}/${data.tenantId}`, data);
  return res.data;
};

export const editMapAction = async (data: IMap): Promise<void> => {
  await AxiosConfig.instance.put(`${ROUTE}/${data.id}`, data);
};

export const getMapAction = async (): Promise<IMap> => {
  const { entityId } = await microsoftTeams.pages.getConfig();
  const res = await AxiosConfig.instance.get(`${ROUTE}/${entityId}`);
  return res.data;
};

export const deleteMapAction = async (data: { appId: string }) => {
  await AxiosConfig.instance.delete(`${ROUTE}/${data.appId}`);
};

export const isMapExistAction = async (id: string): Promise<void> => {
  const res = await AxiosConfig.instance.get(`${ROUTE}/${id}/check`);
  if (res.status !== 200) throw new Error("Wrong app ID");
};

const getLimitationAction = async (tenantId: string): Promise<{ subscription: ISubscription; maxPlace: number; maxTrip: number }> => {
  const res = await AxiosConfig.instance.get(`${ROUTE}/tenantId/${tenantId}/limitation`);
  return res.data;
};

export const createMap = tryCatch(createMapAction);
export const getMap = tryCatch(getMapAction);
export const editMap = tryCatch(editMapAction);
export const deleteMap = tryCatch(deleteMapAction);
export const isMapExist = tryCatch(isMapExistAction);
export const getLimitation = tryCatch(getLimitationAction);
