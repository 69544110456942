import { useMemo } from "react";
export const useTranslate = (translations) => {
    const t = useMemo(() => {
        const memoizedTranslations = new Map();
        return (label, params = undefined) => {
            if (!memoizedTranslations.has(label)) {
                memoizedTranslations.set(label, translations.get(label, params));
            }
            return memoizedTranslations.get(label);
        };
    }, [translations]);
    return t;
};
