import { useEffect, useMemo, useRef } from "react";
import { OverlappingMarkerSpiderfier } from "ts-overlapping-marker-spiderfier";
import { useDispatch, useSelector } from "react-redux";
import { IAppDispatch, ReduxStoreState } from "../../redux/store";
import { setGoogleMap } from "../../redux/reducers/googleMap.reducer";
import { usePremiumSelector } from "front";
import { useConnectedAppSelector } from "../../redux/reducers/connectedApp.reducer";
import { useContactCache } from "../../hooks/useContactCache/useContactCache";
import { setPagination } from "../../redux/reducers/PaginationReducer";
import { IConnectedApp } from "../../types/IConnectedApp/IConnectedApp";

type GoogleLatLng = google.maps.LatLng;

export const useGoogleMap = () => {
  const dispatchCtx = useDispatch<IAppDispatch>();

  const { isPremium } = usePremiumSelector("isPremium");
  const { connectedApp } = useConnectedAppSelector("connectedApp");

  const pagination = useSelector((s: ReduxStoreState) => s.pagination);
  const { queryContacts } = useContactCache({ initializer: true });

  const changePage = (page: number) => {
    const skip = (page - 1) * pagination.take;
    dispatchCtx(setPagination({ ...pagination, skip, currentPage: page }));
  };

  const mapRef = useRef<HTMLDivElement>(null);

  useEffect(() => initializeMap(), []);

  const initializeMap = () => {
    if (!mapRef.current) return;
    const defaultAddress: GoogleLatLng = new google.maps.LatLng(0, 0);
    const isLightTheme = false;
    const mapStyles: google.maps.MapTypeStyle[] = [
      { featureType: "poi", stylers: [{ visibility: "off" }] },
      { featureType: "transit", elementType: "labels.icon", stylers: [{ visibility: "off" }] },
      // Turns off highway labels
      { featureType: "road.highway", elementType: "labels", stylers: [{ visibility: "off" }] },
      // Turns off arterial roads labels
      { featureType: "road.arterial", elementType: "labels", stylers: [{ visibility: "off" }] },
      // Turns off local roads labels
      { featureType: "road.local", elementType: "labels", stylers: [{ visibility: "off" }] },
    ];
    if (!isLightTheme) {
      mapStyles.push(
        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        { featureType: "road", elementType: "geometry", stylers: [{ color: "#38414e" }] },
        { featureType: "road", elementType: "geometry.stroke", stylers: [{ color: "#212a37" }] },
        { featureType: "road", elementType: "labels.text.fill", stylers: [{ color: "#9ca5b3" }] },
        { featureType: "road.highway", elementType: "geometry", stylers: [{ color: "#746855" }] },
        { featureType: "road.highway", elementType: "geometry.stroke", stylers: [{ color: "#1f2835" }] },
        { featureType: "road.highway", elementType: "labels.text.fill", stylers: [{ color: "#f3d19c" }] },
        { featureType: "water", elementType: "geometry", stylers: [{ color: "#17263c" }] },
        { featureType: "water", elementType: "labels.text.fill", stylers: [{ color: "#515c6d" }] },
        { featureType: "water", elementType: "labels.text.stroke", stylers: [{ color: "#17263c" }] }
      );
    }

    let map = new google.maps.Map(mapRef.current as HTMLDivElement, {
      zoom: 2,
      center: defaultAddress,
      mapTypeControl: true,
      streetViewControl: true,
      zoomControl: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      styles: mapStyles,
      minZoom: 2,
    });

    const oms = new OverlappingMarkerSpiderfier(map, {
      markersWontMove: true, // we promise not to move any markers, allowing optimizations
      markersWontHide: true, // we promise not to change visibility of any markers, allowing optimizations
      basicFormatEvents: true, // allow the library to skip calculating advanced formatting information
    });
    dispatchCtx(setGoogleMap({ googleMap: map, oms }));

    // props.onInstanceChange(map, oms);
  };

  const calculateMapHeight = useMemo((): string => {
    let acc: number = 0;
    let defaultHeight = "100vh";
    acc = isPremium ? 4 : 5.75;
    acc += connectedApp === IConnectedApp.CONTACTS_PRO ? 2 : 0;
    return `calc(${defaultHeight} - ${acc}rem)`;
  }, [isPremium, connectedApp]);

  return { mapRef, isPremium, connectedApp, pagination, total: queryContacts.data?.total, changePage, calculateMapHeight };
};
