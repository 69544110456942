import { isValidElement } from "react";
export function areObjectsEqual(obj1, obj2) {
    if (!obj1 && !obj2)
        return true;
    if ((!obj1 && !!obj2) || (!!obj1 && !obj2))
        return false;
    if (typeof obj1 !== typeof obj2)
        return false;
    if (Object.keys(obj1 ?? {}).length !== Object.keys(obj2 ?? {}).length)
        return false;
    for (const key in obj1) {
        if (obj1.hasOwnProperty(key)) {
            const val1 = obj1?.[key];
            const val2 = obj2?.[key];
            if (isValidElement(val1) && isValidElement(val2)) {
                const reactElement1 = val1;
                const reactElement2 = val2;
                const isSameKey = reactElement1.key === reactElement2.key;
                if (!isSameKey)
                    return false;
                const isSameType = reactElement1.type === reactElement2.type;
                if (!isSameType)
                    return false;
                const areSameProps = reactElement1.props === reactElement2.props;
                if (!areSameProps)
                    return false;
            }
            else if (Array.isArray(val1) && Array.isArray(val2)) {
                if (!areArraysEqual(val1, val2))
                    return false;
            }
            else if (typeof val1 === 'object' && typeof val2 === 'object') {
                if (!areObjectsEqual(val1, val2))
                    return false;
            }
            else if (val1 !== val2) {
                return false;
            }
        }
    }
    return true;
}
export function areArraysEqual(arr1, arr2) {
    if (!arr1 && !arr2)
        return true;
    if ((!arr1 && !!arr2) || (!!arr1 && !arr2))
        return false;
    if (arr1?.length !== arr2?.length)
        return false;
    if (arr1?.length === 0 && arr2?.length === 0)
        return true;
    const firstItemType = typeof arr1?.[0];
    if (firstItemType === 'object') {
        const visitedIndices = new Set();
        for (let i = 0; i < (arr1?.length ?? 0); i++) {
            const val1 = arr1?.[i];
            let foundMatch = false;
            for (let j = 0; j < (arr2?.length ?? 0); j++) {
                if (visitedIndices.has(j))
                    continue;
                const val2 = arr2?.[j];
                if (areObjectsEqual(val1, val2)) {
                    visitedIndices.add(j);
                    foundMatch = true;
                    break;
                }
            }
            if (!foundMatch)
                return false;
        }
    }
    else {
        const sortedArr1 = [...(arr1 ?? [])].sort();
        const sortedArr2 = [...(arr2 ?? [])].sort();
        for (let i = 0; i < sortedArr1.length; i++) {
            const val1 = sortedArr1[i];
            const val2 = sortedArr2[i];
            if (val1 !== val2)
                return false;
        }
    }
    return true;
}
export const CompareModule = {
    areObjectsEqual,
    areArraysEqual,
};
