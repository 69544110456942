import { createRef, useCallback, useEffect, useRef, useState } from "react";
import { useCalendarCache } from "../../services/cacheService/calendarCache";
import moment from "moment-timezone";
import "@fullcalendar/react/dist/vdom";
import esLocale from "@fullcalendar/core/locales/es";
import frLocale from "@fullcalendar/core/locales/fr";
import deLocale from "@fullcalendar/core/locales/de";
import itLocale from "@fullcalendar/core/locales/it";
import ptLocale from "@fullcalendar/core/locales/pt";
import timeZonePlugin from "@fullcalendar/moment-timezone";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import rrulePlugin from "@fullcalendar/rrule";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import { GraphService, fetchShareCalendar, openPremiumDialog, useMsTeamsSelector, useNotificationsSelector, usePermissionsSelector, usePremiumSelector, useTranslate, userHasRightsToEdit, GetCalendarSyncByIcsUrl, useConfigurationSelector, } from "front";
import { createEventWithID, createEventWithoutId, formatDatabaseEventToFullCalendar, getFullcalendarDisplayMode, initializeNewEvent, loadEvents, } from "./Calendar.utils";
import { useQuery } from "react-query";
import { DisplayMode, IEventSelection, IOptionRecurrence } from "../../interfaces/Enums";
import { fetchPublicHolidays } from "../../apis/apiGoogleCalendar";
import { setNavbar } from "../../redux/reducers/navbarReducer";
import { useDispatch, useSelector } from "react-redux";
import { formatDateWithoutTimezone } from "../../components/event/eventUtils";
import { Subject } from "rxjs";
import { localItemName } from "./navBar/Navbar.logic";
import { translations } from "../../translations";
import { useOptionsSelector } from "../../redux/reducers/optionsReducer";
import { localTimezone } from "../../utils/localTimezone";
import { CheckCopyStatus } from "../../apis/apiCalendar";
import { usePersistantAlertHook } from "front";
import { ADD_EVENT, PATH_TO_SHARE } from "../event/Event.logic";
import * as microsoftTeams from "@microsoft/teams-js";
export let defaultPlugin = [rrulePlugin, timeZonePlugin, dayGridPlugin, timeGridPlugin, listPlugin];
const subject$ = new Subject();
export const fullCalendarLocales = [esLocale, frLocale, deLocale, itLocale, ptLocale];
export const useLogic = (props) => {
    const { showView } = props;
    const fullcalendarRef = createRef();
    const premiumBannerRef = createRef();
    const { isOnMobile, locale, userMail, userId, groupId, channelId, tabInternalId, userName, chatId } = useMsTeamsSelector("isOnMobile", "locale", "userMail", "userId", "groupId", "channelId", "tabInternalId", "userName", "chatId");
    const { isPremium, isPlatinum } = usePremiumSelector("isPremium", "isPlatinum");
    const { delete: deleteNotif } = useNotificationsSelector("delete");
    const t = useTranslate(translations);
    const { allSharedCalendar, userPerm } = usePermissionsSelector("allSharedCalendar", "userPerm");
    const navBar = useSelector((s) => s.navBar);
    const { nameApp } = useOptionsSelector("nameApp");
    const appInsightInstance = useSelector((s) => s.infosApp.appInsightInstance);
    const { data } = useConfigurationSelector("data");
    const { calendar, resources, mutateDeleteOccurenceGroupEvent, mutateDeleteEvent, mutateUpdateEvent, mutateCalendar } = useCalendarCache();
    const [deleteState, setDeleteState] = useState({
        showDeleteDialog: false,
        fullcalendarEventToDelete: undefined,
        deleteChoice: IEventSelection.Occurrence,
        isDeletingEvent: false,
    });
    const [calendarApi, setCalendarApi] = useState();
    const [displayMode, setDisplayMode] = useState();
    const [userCanEditCalendar, setUserCanEditCalendar] = useState(false);
    const [readyToLoadConfig, setReadyToLoadConfig] = useState(false);
    const [navbarHeight, setNavbarHeight] = useState(0);
    const [config, setConfig] = useState();
    const [forceRender, setForceRender] = useState([]);
    const [currentCalendarTitle, setCurrentCalendarTitle] = useState("");
    const [shownEvent, setshownEvent] = useState([]);
    const [displayedCalendars, setDisplayedCalendars] = useState([]);
    const dispatch = useDispatch();
    const calendarSyncRef = useRef([]);
    const eventsRef = useRef([]);
    const { persistentAlertHeight } = usePersistantAlertHook();
    const queryKeyMemo = useCallback(() => {
        return [
            "events",
            {
                calendar,
                start: moment(calendarApi?.currentDataManager?.getCurrentData().viewApi.activeStart).utc().format("YYYY-MM-DDTHH:mm:ss"),
                end: moment(calendarApi?.currentDataManager?.getCurrentData().viewApi.activeEnd).utc().format("YYYY-MM-DDTHH:mm:ss"),
                currentTimezone: navBar.calendarTimeZone ?? localTimezone,
                tagFilter: navBar.searchTag ?? "",
                locale,
                googleMapApiKey: data.googleMapApiKey,
                isPlatinum: isPlatinum,
            },
        ];
    }, [calendar, calendarApi, locale, navBar.searchTag, navBar.calendarTimeZone, isPlatinum]);
    const { data: events, refetch: refetchEvents } = useQuery(queryKeyMemo(), loadEvents, {
        staleTime: Infinity,
        enabled: !!calendar && !!calendarApi,
        retry: 1,
    });
    useEffect(() => {
        const sub = subject$.subscribe({
            next: (v) => {
                setshownEvent([...(eventsRef.current ?? []), ...calendarSyncRef.current]);
            },
        });
        return sub.unsubscribe.bind(sub);
    }, []);
    useEffect(() => {
        if (!isPlatinum || displayedCalendars.length === 0)
            return;
        addICalEventsToShownEvents(displayedCalendars);
    }, [isPlatinum, displayedCalendars, navBar.calendarTimeZone]);
    // 
    useEffect(() => {
        if (!calendar?.id || !calendar.isCopying)
            return;
        const intervalId = setInterval(async () => {
            try {
                const status = await CheckCopyStatus(calendar.urlCopyStatus);
                if (status === "Completed") {
                    await refetchEvents();
                    mutateCalendar({ ...calendar, isCopying: false }, {
                        onSuccess: () => {
                            clearInterval(intervalId);
                        },
                        onError: () => {
                            clearInterval(intervalId);
                        }
                    });
                }
            }
            catch (error) {
                console.error('Error fetching function status:', error);
            }
        }, 60000);
        return () => clearInterval(intervalId);
    }, [calendar]);
    useEffect(() => {
        if (!calendar?.id)
            return;
        let unifiedCalendars = [...allSharedCalendar];
        const stored = localStorage.getItem(localItemName(calendar.id)) ?? "";
        const storedData = stored ? JSON.parse(stored) : {};
        if (userPerm.synchros && isPlatinum) {
            unifiedCalendars = [...userPerm.synchros, ...allSharedCalendar];
            unifiedCalendars = unifiedCalendars.map((u) => ({
                ...u,
                display: storedData[u.id] ?? true,
            }));
        }
        else {
            unifiedCalendars = unifiedCalendars.map((u) => ({
                ...u,
                display: false,
            }));
        }
        //Set Timeout pour ne pas avoir de dupplicata des events lors du changement de sharing à true vers false et inversement dans le manage des syncs
        const timeout = setTimeout(() => setDisplayedCalendars(unifiedCalendars), 1000);
        return () => {
            clearTimeout(timeout);
        };
    }, [allSharedCalendar, userPerm.synchros, calendar, isPlatinum]);
    useEffect(() => {
        if (calendar && config) {
            const scrollToDate = () => {
                let todayDate = moment().format("YYYY-MM-DD");
                if (moment().date() + 4 <= moment().daysInMonth()) {
                    todayDate = moment().set({ date: moment().date() + 4 }).format("YYYY-MM-DD");
                }
                const targetDateElement = document.querySelector(`[data-date="${todayDate}"].fc-timeline-slot-lane`);
                if (targetDateElement) {
                    targetDateElement.scrollIntoView();
                }
            };
            scrollToDate();
        }
    }, [calendar, config]);
    const fetchShareCalendarData = useCallback(() => {
        if (isPlatinum && calendar?.id && userId) {
            dispatch(fetchShareCalendar({ projectId: calendar.id, userId }));
        }
    }, [dispatch, isPlatinum, calendar, userId]);
    useEffect(() => {
        fetchShareCalendarData();
        const intervalId = setInterval(fetchShareCalendarData, 15 * 60 * 1000);
        return () => clearInterval(intervalId);
    }, [fetchShareCalendarData]);
    useEffect(() => {
        if (config) {
            if (!fullcalendarRef.current)
                return;
            setCalendarApi(fullcalendarRef.current.getApi());
            currentCalendarTitle == "" && setCalendarTitle();
        }
    }, [config]);
    useEffect(() => {
        if (!calendar?.id)
            return;
        const display = navBar.displayMode == DisplayMode.Loading ? calendar.defaultDisplayMode : navBar.displayMode;
        dispatch(setNavbar({ displayMode: display }));
    }, [calendar, navBar]);
    useEffect(() => {
        if (userPerm["id"] == undefined)
            return;
        const isEdit = userHasRightsToEdit(userPerm);
        setUserCanEditCalendar(isEdit);
        setReadyToLoadConfig(true);
    }, [userPerm]);
    useEffect(() => {
        if (!calendar?.id)
            return;
        const display = getFullcalendarDisplayMode(navBar.displayMode, calendar.isResourceMode);
        if (calendarApi)
            calendarApi.changeView(display);
        setDisplayMode(display);
        setCalendarTitle();
    }, [calendar, calendarApi, navBar.displayMode]);
    useEffect(() => {
        setCalendarTitle();
    }, [navBar.activeStartDate]);
    /*useEffect(()=>{
      mutateLocalDisplayMode(navBar.displayMode)
    },[navBar.displayMode])*/
    useEffect(() => {
        if (!calendar || !readyToLoadConfig)
            return;
        const conf = getFullCalendarConfig();
        setConfig(conf);
    }, [
        navbarHeight,
        shownEvent,
        calendar,
        navBar.calendarTimeZone,
        readyToLoadConfig,
        resources,
        navBar.displayMode,
        navBar.activeStartDate,
        persistentAlertHeight
    ]);
    useEffect(() => {
        if (events) {
            eventsRef.current = [...events];
            subject$.next([]);
        }
    }, [events]);
    const addICalEventsToShownEvents = async (calendars) => {
        calendarSyncRef.current = [];
        calendars.map(async (calendarSync) => {
            try {
                if (displayedCalendars.some((d) => d.id == calendarSync.id && d.display)) {
                    const response = await GetCalendarSyncByIcsUrl(calendarSync.url, navBar.calendarTimeZone);
                    const newEvents = response?.map((event) => ({
                        ...event,
                        color: calendarSync.color,
                        calendarName: calendarSync.name,
                        createdBy: calendarSync.creator,
                        calendarId: calendarSync.id ?? "",
                        timeZone: event.timeZone ?? navBar.calendarTimeZone,
                        excludeDate: event.excludeDate?.map((ed) => moment(ed).utc().format("YYYY/MM/DD HH:mm:ss")),
                    }));
                    //To do : faire une méthode pour ne pas push de dupplicata dans le calendarSyncRef.current
                    calendarSyncRef.current = [...calendarSyncRef.current, ...newEvents];
                    subject$.next([]);
                }
                else {
                    const updatedCalendarSync = calendarSyncRef.current.filter((item) => item.calendarId !== calendarSync.id);
                    calendarSyncRef.current = [...updatedCalendarSync];
                    subject$.next([]);
                }
            }
            catch (error) {
                console.error("Erreur lors de la récupération des événements iCal : ", error);
            }
        });
    };
    /**
     * Update navbar height
     * @param height height
     */
    const updateNavbarHeight = useCallback((height) => {
        if (height > 0 && height !== navbarHeight)
            setNavbarHeight(height);
    }, []);
    /**
     * Set calendar title from current displayed month / week / day
     */
    const setCalendarTitle = () => {
        if (fullcalendarRef.current) {
            const api = fullcalendarRef.current.getApi();
            const title = api.view.title[0].toUpperCase() + api.view.title.slice(1);
            setCurrentCalendarTitle(title);
        }
    };
    const onShareEvent = (fullcalendarEvent) => {
        if (!isPremium) {
            dispatch(openPremiumDialog(appInsightInstance));
            return;
        }
        let queryObject = {
            path: PATH_TO_SHARE,
            rru: ADD_EVENT,
        };
        if (!fullcalendarEvent.event.allDay)
            queryObject.enddt = fullcalendarEvent.event.endStr;
        queryObject.startdt = fullcalendarEvent.event.startStr;
        queryObject.subject = fullcalendarEvent.event.title;
        queryObject.allday = fullcalendarEvent.event.allDay ? "true" : "false";
        const query = new URLSearchParams(queryObject);
        microsoftTeams.app.openLink("https://outlook.office.com/calendar/0/action/compose?" + query.toString());
    };
    /**
     * Get fullcalendar config
     */
    const getFullCalendarConfig = () => {
        let premiumBannerHeight = premiumBannerRef.current?.offsetHeight ?? 0;
        if (premiumBannerHeight > 0)
            premiumBannerHeight += 10;
        const navHeight = navbarHeight + premiumBannerHeight + persistentAlertHeight;
        const height = window.innerHeight - navHeight + "px";
        const eventsDisplay = shownEvent?.map((e) => formatDatabaseEventToFullCalendar(e, shownEvent, navBar.calendarTimeZone));
        let businessHours = {};
        if (calendar?.businessDays) {
            businessHours = getFullcalendarBusinessHoursFromCalendar(calendar.businessDays);
        }
        let config = {
            isResourceMode: calendar?.isResourceMode ?? false,
            timeZone: navBar.calendarTimeZone ?? localTimezone,
            height,
            plugins: [...defaultPlugin],
            events: eventsDisplay ?? [],
            slotDuration: "00:30:00",
            showWeekend: calendar?.showWeekend ?? false,
            businessHours: businessHours,
            showWeekNumbers: calendar?.showWeekNumbers ?? false,
            initialDate: navBar.activeStartDate !== "" ? navBar.activeStartDate : Date.now(),
        };
        if (userCanEditCalendar)
            config.plugins.push(interactionPlugin);
        if (!config.isResourceMode)
            return config;
        return updateFullCalendarConfigInResourceMode(config);
    };
    /**
     * Update fullcalendar config if in resource mode
     */
    const updateFullCalendarConfigInResourceMode = (config) => {
        config.plugins.push(resourceTimelinePlugin);
        config.resources = resources ?? new Array();
        if (!isPremium)
            config.resources = config.resources.slice(0, 3);
        config.resourceAreaWidth = 250;
        if (isOnMobile)
            config.resourceAreaWidth = 75;
        config.resourceOrder = "orderBy";
        const displayMode = navBar.displayMode;
        switch (displayMode) {
            case DisplayMode.Month:
                config.slotLabelFormat = [{ month: "long" }, { day: "numeric", weekday: "short" }];
                config.slotDuration = "00:30:00";
                config.slotMinWidth = 100;
                break;
            case DisplayMode.Week:
                config.slotLabelFormat = [{ day: "numeric", weekday: "short" }, { hour: "numeric" }];
                config.slotDuration = "04:00:00";
                config.slotMinWidth = 100;
                break;
            case DisplayMode.Day:
                config.slotLabelFormat = [{ hour: "numeric" }];
                config.slotDuration = "00:30:00";
                config.slotMinWidth = 50;
                break;
            default:
                break;
        }
        return config;
    };
    /**
     * Get fullcalendar business hours from calendar business days
     */
    const getFullcalendarBusinessHoursFromCalendar = (businessDays) => {
        // Fullcalendar consider Sunday with number 0, in data of calendar Sunday is 6
        const selectedDays = businessDays.days.map((d) => d + 1);
        // Includes sunday ?
        if (selectedDays.includes(7)) {
            const sundayIndex = selectedDays.indexOf(7);
            selectedDays[sundayIndex] = 0;
        }
        return {
            startTime: businessDays.startHour + ":00",
            endTime: businessDays.endHour + ":00",
            daysOfWeek: selectedDays,
        };
    };
    /**
     * Get an existing event
     * @param eventId event id
     */
    const getExistingEvent = useCallback((eventId) => {
        const event = shownEvent?.find((e) => e.id === eventId);
        if (!event)
            throw new Error("Can't find existing event with id : " + eventId);
        return event;
    }, [shownEvent]);
    /**
     * Create new event
     */
    const onCreateNewEvent = useCallback(async () => {
        const currentTimezone = navBar.calendarTimeZone ?? localTimezone;
        if (!calendar || !resources)
            return;
        const event = initializeNewEvent(calendar, userMail, currentTimezone, resources);
        await showView(event, false, events, queryKeyMemo());
    }, [calendar, userMail, resources, queryKeyMemo]);
    /**
     * Create custom occurence from a serie
     * @param fullcalendarEvent fullcalendar event
     * @param calendarTimezone calendar timezone
     */
    const createOccurenceFromSerie = (fullcalendarEvent, calendarTimezone) => {
        const serieEvent = getExistingEvent(fullcalendarEvent.event.id);
        const event = createEventWithID(fullcalendarEvent, serieEvent, calendarTimezone);
        event.id = undefined;
        event.groupId = fullcalendarEvent.event.groupId;
        event.modeRecurrence = IOptionRecurrence.NotRepeat;
        event.timeZone = serieEvent.timeZone;
        event.rrule = undefined;
        event.excludeDate = undefined;
        return event;
    };
    /**
     * On edit calendar event
     * @param fullcalendarEvent fullcalendar event
     * @param selection selection
     */
    const onEditCalendarEvent = useCallback((fullcalendarEvent, selection) => async () => {
        const currentTimezone = navBar.calendarTimeZone ?? localTimezone;
        let existingEvent;
        let event;
        // If selected event is a serie
        if (!!fullcalendarEvent.event.groupId) {
            const serieEvent = getExistingEvent(fullcalendarEvent.event.groupId);
            if (!serieEvent?.id)
                return;
            if (selection === IEventSelection.Occurrence) {
                // If id is same as serie id, we will create a new occurence
                if (fullcalendarEvent.event.id === fullcalendarEvent.event.groupId) {
                    event = createOccurenceFromSerie(fullcalendarEvent, currentTimezone);
                }
                else {
                    existingEvent = getExistingEvent(fullcalendarEvent.event.id);
                    event = createEventWithID(fullcalendarEvent, existingEvent);
                }
            }
            else {
                existingEvent = serieEvent;
                event = createEventWithID(fullcalendarEvent, existingEvent);
            }
        }
        else {
            existingEvent = getExistingEvent(fullcalendarEvent.event.id);
            event = createEventWithID(fullcalendarEvent, existingEvent);
        }
        await showView(event, false, events, queryKeyMemo());
    }, [showView, getExistingEvent, queryKeyMemo]);
    /**
     * On delete event
     * @param fullcalendarEvent fullcalendar event
     * @param selection selection
     */
    const onDeleteEvent = (fullcalendarEvent, selection) => async () => {
        if (!deleteState.showDeleteDialog) {
            setDeleteState((prev) => ({
                ...prev,
                showDeleteDialog: true,
                fullcalendarEventToDelete: fullcalendarEvent,
                deleteChoice: selection,
            }));
            return;
        }
        setDeleteState((prev) => ({
            ...prev,
            isDeletingEvent: true,
        }));
        let event = events?.find((e) => e.id === fullcalendarEvent.event.id);
        if (!event?.id || !calendar?.id)
            throw new Error("Can't delete event, event is undefined");
        // Delete simple event
        if (!event.groupId)
            mutateDeleteEvent({ calendarId: calendar.id, event: event, deleteOnlyOccurence: true, queryKey: queryKeyMemo() }, {
                onSuccess: () => {
                    setDeleteState((prev) => ({
                        ...prev,
                        isDeletingEvent: false,
                        showDeleteDialog: false,
                        fullcalendarEventToDelete: undefined,
                        deleteChoice: IEventSelection.Occurrence,
                    }));
                    sendDeleteNotif(event);
                },
            });
        else {
            // Delete occurence from serie
            if (selection === IEventSelection.Occurrence) {
                // Custom occurence
                if (event.id !== event.groupId) {
                    event = events?.find((e) => e.id === event?.groupId);
                    if (!event?.groupId)
                        return;
                    mutateDeleteEvent({ calendarId: calendar.id, event: event, deleteOnlyOccurence: false, queryKey: queryKeyMemo() }, {
                        onSuccess: () => {
                            setDeleteState((prev) => ({
                                ...prev,
                                isDeletingEvent: false,
                                showDeleteDialog: false,
                                fullcalendarEventToDelete: undefined,
                                deleteChoice: IEventSelection.Occurrence,
                            }));
                            sendDeleteNotif(event);
                        },
                    });
                    return;
                }
                const realStartTime = event.startDate;
                const realEndTime = event.endDate;
                const currentTimezone = navBar.calendarTimeZone ?? localTimezone;
                // Create a copy of event modifying startDate and endDate to selected date
                event = createEventWithID(fullcalendarEvent, event, currentTimezone);
                const excludeDate = event.allDay
                    ? formatDateWithoutTimezone(moment(event.startDate).tz(event.timeZone).format("YYYY/MM/DD HH:mm:ss"))
                    : moment.utc(event.startDate).set("milliseconds", 0).format("YYYY/MM/DD HH:mm:ss");
                // Occurence directly from serie
                event.excludeDate = [...(event.excludeDate ?? []), excludeDate];
                event.startDate = realStartTime;
                event.endDate = realEndTime;
                event.id &&
                    mutateUpdateEvent({ calendarId: calendar.id, event: event, queryKey: queryKeyMemo(), allowDoubleBooking: true, calendarTimeZone: navBar.calendarTimeZone, initialEvent: event, tags: calendar.tags }, {
                        onSuccess: () => {
                            setDeleteState((prev) => ({
                                ...prev,
                                isDeletingEvent: false,
                                showDeleteDialog: false,
                                fullcalendarEventToDelete: undefined,
                                deleteChoice: IEventSelection.Occurrence,
                            }));
                            sendDeleteNotif(event);
                        },
                    });
            }
            else {
                if (event.groupId && event.id !== event.groupId) {
                    mutateDeleteOccurenceGroupEvent({ calendarId: calendar.id, event: event, queryKey: queryKeyMemo() }, {
                        onSuccess: () => {
                            setDeleteState((prev) => ({
                                ...prev,
                                isDeletingEvent: false,
                                showDeleteDialog: false,
                                fullcalendarEventToDelete: undefined,
                                deleteChoice: IEventSelection.Occurrence,
                            }));
                            sendDeleteNotif(event);
                        },
                    });
                }
                else {
                    mutateDeleteEvent({ calendarId: calendar.id, event: event, deleteOnlyOccurence: false, queryKey: queryKeyMemo() }, {
                        onSuccess: () => {
                            setDeleteState((prev) => ({
                                ...prev,
                                isDeletingEvent: false,
                                showDeleteDialog: false,
                                fullcalendarEventToDelete: undefined,
                                deleteChoice: IEventSelection.Occurrence,
                            }));
                            sendDeleteNotif(event);
                        },
                    });
                }
            }
        }
    };
    const sendDeleteNotif = (event) => {
        const participantsNotif = event.participants?.filter((p) => p.id != userId);
        if (participantsNotif?.length > 0 && deleteNotif) {
            let ressourceName = "";
            if (event.resourceId) {
                ressourceName = resources.find((r) => r.id == event.resourceId).title;
            }
            const webUrl = encodeURIComponent(`${window.location.origin}/calendars?id=${calendar.id ?? ""}`);
            GraphService.sendNotification(participantsNotif, !!channelId ? groupId : chatId, channelId, t("deleteEventTitle", { userName, ressourceName }), event.title, data.manifestId, calendar.id ?? "", nameApp, webUrl, !!channelId);
        }
    };
    /**
     * Refresh public holidays
     */
    const refreshPublicHolidays = async () => {
        if (!fullcalendarRef.current)
            return;
        let calendarApi = fullcalendarRef.current.getApi();
        const currentTimezone = navBar.calendarTimeZone ?? localTimezone;
        let startDay = moment(calendarApi.currentDataManager?.getCurrentData().viewApi.activeStart).format("YYYY-MM-DD");
        let endDay = moment(calendarApi.currentDataManager?.getCurrentData().viewApi.activeEnd).format("YYYY-MM-DD");
        const publicHolidaysEvents = await fetchPublicHolidays(calendar?.publicHolidays, currentTimezone, locale, startDay, endDay, data.googleMapApiKey);
        let eventsFiltered = new Array();
        if (events)
            eventsFiltered = [...events?.filter((e) => !e.calendarId.startsWith("google_calendar"))];
        eventsFiltered.push(...publicHolidaysEvents);
        setshownEvent(eventsFiltered);
    };
    /**
     * Handle fullcalendar event selection
     * @param fullCalendarEvent fullcalendar selection event
     */
    const onSelectEvent = useCallback(async (fullCalendarEvent) => {
        const calendarId = calendar?.id;
        if (!calendarId || !userPerm.perm.add)
            return;
        const userUpn = userMail;
        const currentTimezone = navBar.calendarTimeZone ?? localTimezone;
        let event = createEventWithoutId(fullCalendarEvent, calendarId, userUpn, currentTimezone);
        showView(event, false, events, queryKeyMemo());
    }, [showView, calendar, userMail, queryKeyMemo, userPerm, createEventWithoutId]);
    /**
     * Close delete dialog
     */
    const closeDeleteDialog = useCallback(() => setDeleteState((prev) => ({
        ...prev,
        showDeleteDialog: false,
    })), []);
    /**
     * Clear events
     */
    const clearEvents = () => {
        if (shownEvent.length > 0)
            setshownEvent([]);
    };
    const filterEvents = (events) => {
        setshownEvent(events);
    };
    /**
     * Show premium dialog
     */
    const showPremiumDialog = () => dispatch(openPremiumDialog(appInsightInstance));
    /**
     * Resize calendar
     */
    const onResizeCalendar = useCallback(() => {
        if (calendarApi) {
            calendarApi.updateSize();
            setForceRender([]);
        }
    }, [calendarApi]);
    return {
        fullcalendarRef,
        calendar,
        displayMode,
        userCanEditCalendar,
        updateNavbarHeight,
        config,
        forceRender,
        onResizeCalendar,
        calendarApi,
        getExistingEvent,
        onSelectEvent,
        onCreateNewEvent,
        onEditCalendarEvent,
        onDeleteEvent,
        closeDeleteDialog,
        deleteState,
        events,
        refetchEvents,
        refreshPublicHolidays,
        setCalendarTitle,
        currentCalendarTitle,
        clearEvents,
        filterEvents,
        shownEvent,
        queryKey: queryKeyMemo(),
        showPremiumDialog,
        premiumBannerRef,
        userPerm,
        displayedCalendars,
        setDisplayedCalendars,
        onShareEvent,
        setCurrentCalendarTitle
    };
};
