import { useMutation, useQuery } from "react-query";
import { IMap } from "../../types/IMap/IMap";
import { IQueryKey } from "../../types/IQueryKey/IQueryKey";
import { editMap, getMap } from "../../apis/mapsActions/mapsActions";
import { queryOptions } from "../../utils/queryOptions";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "redux/store";
import { useCallback } from "react";
import { handleExpiredToken, setAppConfiguration } from "front";
import { queryClient } from "../../index";
import { setAppIds, setConnectedApp } from "../../redux/reducers/connectedApp.reducer";
import { convertNumberToAppEnum } from "../../utils/convertNumberToAppEnum";

export const useMapCache = () => {
  const dispatch = useDispatch<IAppDispatch>();

  const onSuccess = useCallback((res: IMap) => {
    if (!res.id || !res.createdBy) return;
    dispatch(setAppConfiguration({ appId: res.id, appOwner: res.createdBy, appPathName: "Map", app:"Map Pro", from: "Map"}));

    if (!res.connect) return;
    const connectedApp = convertNumberToAppEnum(res.connect.app as any);
    if (!connectedApp) return;
    dispatch(setConnectedApp(connectedApp));
    dispatch(setAppIds(res.connect.guids));
  }, []);

  const queryMap = useQuery<IMap>([IQueryKey.MAP], getMap, {
    ...queryOptions,
    staleTime: Infinity,
    onSuccess,
    ...handleExpiredToken,
  });

  const editMapMutation = useMutation(editMap, {
    ...handleExpiredToken,
    onSuccess: (_: undefined, variable: IMap) => {
      queryClient.setQueryData([IQueryKey.MAP], (oldData: IMap | undefined) => {
        if (!oldData) return {};
        return variable;
      });
    },
  });

  return { queryMap: { ...queryMap, data: queryMap.data || ({} as IMap) }, editMapMutation };
};
