import { IframeSize, IframeTracking } from "../interfaces/Iframe";
const UpgradeConfig = {
    width: IframeSize.LargeSize,
    height: IframeSize.LowerSize,
    trackingIds: {
        open: IframeTracking.PremiumManageOpen,
        cancel: IframeTracking.PremiumManageCancel,
        validate: IframeTracking.PremiumManageAssign
    }
};
const PremiumAssignLicenceConfig = {
    width: IframeSize.LargeSize,
    height: IframeSize.LowerSize,
    trackingIds: {
        open: null,
        cancel: IframeTracking.ManageLicenceCancel,
        validate: IframeTracking.LicenceAssign
    }
};
const PremiumPricingConfig = {
    width: IframeSize.LargeSize,
    height: IframeSize.LowSize,
    trackingIds: {
        open: null,
        cancel: IframeTracking.PremiumPricingCancel,
        validate: IframeTracking.PremiumPricingPaid
    }
};
const CatalogConfig = {
    width: IframeSize.LargeSize,
    height: IframeSize.LowSize,
    trackingIds: {
        open: IframeTracking.MoreAppsOpen,
        cancel: IframeTracking.MoreAppsCancel,
        validate: IframeTracking.MoreAppsRedirected
    }
};
export const configIframe = {
    Upgrade: UpgradeConfig,
    PremiumAssign: PremiumAssignLicenceConfig,
    PremiumPricing: PremiumPricingConfig,
    Catalog: CatalogConfig,
};
