import { Button, Divider, EditIcon, EyeIcon, EyeSlashIcon, Flex, Text, Tooltip, TrashCanIcon } from "@fluentui/react-northstar";
import { shouldTruncate } from "front";
import React from "react";

const SideViewItem = (props: {
  id: string;
  title: string;
  handleEdit: (id: string) => void;
  handleDelete: (id: string) => void;
  handleShow: (id: string) => void;
  show: boolean;
  isDisabled: boolean | undefined;
}) => {
  return (
    <>
      <Divider />
      <Flex style={{ padding: "0.25rem 0.5rem" }} vAlign="center" hAlign="center" space="between" data-testid="sideViewItem-container">
        <Tooltip content={props.title} trigger={<Text content={shouldTruncate(props.title, 25) ? `${props.title.substring(0, 25)}...` : props.title} disabled={!props.show || !!props.isDisabled} />} />
        <Flex gap="gap.smaller">
          <Button
            data-testid="sideViewItem-show-btn"
            iconOnly
            icon={props.show ? <EyeIcon size="medium" /> : <EyeSlashIcon size="medium" />}
            size="small"
            primary
            text
            onClick={() => props.handleShow!(props.id)}
            disabled={!!props.isDisabled}
          />

          <Button data-testid="sideViewItem-edit-btn" iconOnly icon={<EditIcon size="medium" />} size="small" primary text onClick={() => props.handleEdit(props.id)} disabled={!!props.isDisabled} />
          <Button
            data-testid="sideViewItem-delete-btn"
            iconOnly
            icon={<TrashCanIcon size="medium" />}
            size="small"
            primary
            text
            onClick={() => props.handleDelete(props.id)}
            disabled={!!props.isDisabled}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default SideViewItem;
