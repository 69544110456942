export const removeOldCache = (queryClient, currentQuery) => {
    try {
        if (!queryClient)
            throw new Error("No queryClient found.");
        const key = currentQuery[0];
        const allCaches = queryClient.getQueryCache();
        if (!key)
            throw new Error("No query key found.");
        allCaches.findAll().forEach((q) => {
            if (q.queryKey[0] !== key)
                return;
            if (JSON.stringify(currentQuery) === JSON.stringify(q.queryKey))
                return;
            queryClient.removeQueries(q.queryKey, { exact: true });
        });
    }
    catch (error) {
        throw error;
    }
};
