import { Flex, Loader, Text } from "@fluentui/react-northstar";
import { useRemove } from "./Remove.logic";

const Remove = () => {
  const logic = useRemove();

  if (logic.isLoading) return <Loader />;

  return (
    <Flex fill column hAlign="center" vAlign="center" style={{ height: "100%" }}>
      <Text weight="bold" content={logic.t("RemoveMapsTitle")} align="center" />
      <Flex>
        <Text content={logic.t("RemoveMapsDescription")} align="center" />
      </Flex>
    </Flex>
  );
};

export default Remove;
