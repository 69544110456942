import { useQuery } from "react-query";
import { IQueryKey } from "../interfaces/IQueryKey";
import { getNews, getNewsBanner } from "../apis/News/NewsApi";
import { useMsTeamsSelector } from "../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { usePremiumSelector } from "../redux/reducers/PremiumReducer/PremiumReducer";
import { useCallback } from "react";
import { isObjectEmpty } from "../utils/isObjectEmpty";
export const useNewsCache = (props) => {
    const { isOnMobile, locale, tenantId } = useMsTeamsSelector("isOnMobile", "locale", "tenantId");
    const { isPremium, isPlatinum, loaded } = usePremiumSelector("isPremium", "isPlatinum", "loaded");
    const getSubscription = useCallback(() => {
        if (isPlatinum)
            return "platinum";
        if (isPremium)
            return "premium";
        return "freemium";
    }, [isPremium, isPlatinum]);
    const campaignQuery = {
        subscription: getSubscription(),
        language: locale,
        tenantId: tenantId,
        isOnMobile,
    };
    const queryNews = useQuery([IQueryKey.NEWS, props.appType, campaignQuery], getNews, {
        staleTime: Infinity,
        enabled: !isObjectEmpty(campaignQuery) && !!props.appType && loaded,
        retry: false,
        retryOnMount: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
    const queryNewsBanner = useQuery([IQueryKey.NEWS_BANNER, props.appType, campaignQuery], getNewsBanner, {
        staleTime: Infinity,
        enabled: !isObjectEmpty(campaignQuery) && !!props.appType && loaded,
        retry: false,
        retryOnMount: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });
    return { queryNews, queryNewsBanner };
};
