import { createSlice } from "@reduxjs/toolkit";
import { getSliceSelector } from "../../utils";
const initialState = {
    update: false,
    delete: false
};
const slice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        setNotifConfig: (state, action) => {
            Object.assign(state, action.payload);
        },
    }
});
export const { setNotifConfig } = slice.actions;
export const useNotificationsSelector = getSliceSelector(slice);
export const notificationsReducer = slice.reducer;
