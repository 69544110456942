import React from "react";
import { useMap } from "./Map.logic";
import Navbar from "../../components/Navbar/Navbar";
import GoogleMap from "../../components/GoogleMap/GoogleMap";
import { Flex, Loader } from "@fluentui/react-northstar";
import { PremiumBanner } from "front";

const Map = () => {
  const logic = useMap();

  if (logic.isLoading)
    return (
      <Flex hAlign="center" vAlign="center" fill style={{ height: "100vh" }}>
        <Loader />
      </Flex>
    );

  return (
    <div style={{ position: "relative" }}>
      <PremiumBanner message1={logic.limitIsReached() ? logic.t("PremiumMessage") : logic.t("PremiumMessage1")} message2={logic.t("PremiumMessage2")} messageClick={logic.t("ClickHere")} />
      <Navbar />
      <GoogleMap />
    </div>
  );
};

export default Map;
