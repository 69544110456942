import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useMemo } from "react";
import { Dialog, Flex, Text } from "@fluentui/react-northstar";
import { useMsTeamsSelector } from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { translations } from "../../../translations";
import { useDispatch } from "react-redux";
import { setModalWithData, useModalSelector } from "../../../redux/reducers/ModalReducer/ModalReducer";
import { useTranslate } from "../../../hook/useTranslate";
export const ConsentDialog = () => {
    const t = useTranslate(translations);
    const { data } = useModalSelector("data");
    const { isOnMobile } = useMsTeamsSelector("isOnMobile");
    const dispatch = useDispatch();
    const closeDialog = useCallback(() => {
        dispatch(setModalWithData({ isOpen: undefined, data: undefined }));
    }, []);
    const closeDialogByConfirm = useCallback(() => {
        data.actionDialog();
        dispatch(setModalWithData({ isOpen: undefined, data: undefined }));
    }, [data.actionDialog]);
    const actionDialog = useMemo(() => {
        return closeDialogByConfirm;
    }, [closeDialogByConfirm]);
    const renderDelete = () => {
        return (_jsx(Flex, { fill: true, column: true, gap: "gap.medium", wrap: true, styles: {
                marginTop: "10px",
                overflowY: "scroll",
                overflowX: "hidden",
            }, children: _jsx(Text, { content: t("NeedConsent") }) }));
    };
    return (_jsx(Dialog, { styles: { width: isOnMobile ? "95vw" : "500px" }, defaultOpen: true, closeOnOutsideClick: true, header: _jsx(Flex, { gap: "gap.small", vAlign: "center", children: _jsx(Text, { content: t("NeedConsentTitle") }) }), confirmButton: t("Continue"), content: renderDelete(), cancelButton: t("Return"), onCancel: closeDialog, onConfirm: actionDialog }));
};
