import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getSliceSelector } from "front";
import { OverlappingMarkerSpiderfier } from "ts-overlapping-marker-spiderfier";

const initialState: { googleMap: google.maps.Map<HTMLDivElement> | undefined; oms: OverlappingMarkerSpiderfier | undefined } = {
  googleMap: undefined,
  oms: undefined,
};

const slice = createSlice({
  name: "googleMap",
  initialState,
  reducers: {
    setGoogleMap: (
      state,
      action: PayloadAction<{ googleMap: google.maps.Map<HTMLDivElement>; oms: OverlappingMarkerSpiderfier }>
    ) => {
      state.googleMap = action.payload.googleMap;
      state.oms = action.payload.oms;
    },
  },
});

export const { setGoogleMap } = slice.actions;

export const useGoogleMapSelector = getSliceSelector(slice);

export const googleMapReducer = slice.reducer;
