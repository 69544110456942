import { ISideView } from "types/ISideView/ISideView";

export let initialState: { sideView: ISideView | undefined } = {
  sideView: undefined,
};

const getDefaultState = () => ({
  sideView: undefined,
});

export type IState = typeof initialState;

export enum IAction {
  TOGGLE_SIDE_VIEW = "toggle_side_view",
}
export const componentIsUnmounting = () => {
  initialState = getDefaultState();
};

const actionHandlers = Object.fromEntries(
  Object.values(IAction).map((action) => [action, (_: IState, payload: IState) => ({ ...payload })])
);

export function reducer(state: IState, { type, payload }: { type: IAction; payload: IState }): IState {
  const actionHandler = actionHandlers[type] || ((state) => state);
  return actionHandler(state, payload);
}
