import { setModalWithData, useTranslate } from "front";
import { useCallback } from "react";
import { translations } from "../../translations";
import { useDispatch, useSelector } from "react-redux";
import { IAppDispatch, ReduxStoreState } from "../../redux/store";
import { usePlaceCache } from "../../hooks/usePlaceCache/usePlaceCache";
import { useMapCache } from "../../hooks/useMapCache/useMapCache";

export const useDeletePlaceDialog = () => {
  const t = useTranslate(translations);
  const dispatchCtx = useDispatch<IAppDispatch>();

  const data = useSelector((s: ReduxStoreState) => s.modal.data);
  const { googleMap, oms } = useSelector((s: ReduxStoreState) => s.googleMap);

  const { queryPlace, deletePlaceMutation } = usePlaceCache();
  const { queryMap } = useMapCache();

  const onClose = useCallback(() => {
    dispatchCtx(setModalWithData({ isOpen: undefined, data: undefined }));
  }, []);

  const handleSubmit = async () => {
    if (!data) return;
    const params = {
      mapId: queryMap.data.id!,
      placeId: data.placeId,
    };
    await deletePlaceMutation.mutateAsync(params, {
      onSuccess: () => {
        if (!googleMap || !oms) return;
        const filteredPlaces = queryPlace.data?.filter((qp) => !qp.isDisabled);
        if (!filteredPlaces?.length) return;
        const lastDisabled = filteredPlaces[filteredPlaces?.length - 1];
        if (!lastDisabled) return;
        data.handleShow(lastDisabled.id);
      },
    });
    const marker = data.markers.get(data.placeId);
    marker.setMap(null);
    onClose();
  };

  return { t, isLoading: deletePlaceMutation.isLoading, onClose, handleSubmit };
};
