import axios from "axios";
import { IOptionRecurrence } from "../interfaces/Enums";
let baseGoogleCalendarApiUrl = "https://www.googleapis.com/calendar/v3/calendars";
const basePublicHolidaysCalendarId = "holiday@group.v.calendar.google.com";
const axiosInstance = axios.create();
/**
 * Regex for all day date
 */
const allDayRegex = new RegExp(/\d\d\/\d\d\/\d\d\d\d/);
/**
 * Public holidays
 */
export var PublicHoliday;
(function (PublicHoliday) {
    PublicHoliday["Australian"] = "Australian";
    PublicHoliday["Austrian"] = "Austrian";
    PublicHoliday["Brazilian"] = "Brazilian";
    PublicHoliday["Canadian"] = "Canadian";
    PublicHoliday["China"] = "China";
    PublicHoliday["Christian"] = "Christian";
    PublicHoliday["Danish"] = "Danish";
    PublicHoliday["Dutch"] = "Dutch";
    PublicHoliday["Finnish"] = "Finnish";
    PublicHoliday["French"] = "French";
    PublicHoliday["German"] = "German";
    PublicHoliday["Greek"] = "Greek";
    PublicHoliday["HongKongC"] = "HongKongC";
    PublicHoliday["HongKong"] = "HongKong";
    PublicHoliday["Indian"] = "Indian";
    PublicHoliday["Indonesian"] = "Indonesian";
    PublicHoliday["Iranian"] = "Iranian";
    PublicHoliday["Irish"] = "Irish";
    PublicHoliday["Islamic"] = "Islamic";
    PublicHoliday["Italian"] = "Italian";
    PublicHoliday["Japanese"] = "Japanese";
    PublicHoliday["Jewish"] = "Jewish";
    PublicHoliday["Malaysian"] = "Malaysian";
    PublicHoliday["Mexican"] = "Mexican";
    PublicHoliday["NewZealand"] = "NewZealand";
    PublicHoliday["Norwegian"] = "Norwegian";
    PublicHoliday["Philippines"] = "Philippines";
    PublicHoliday["Polish"] = "Polish";
    PublicHoliday["Portuguese"] = "Portuguese";
    PublicHoliday["Russian"] = "Russian";
    PublicHoliday["Singapore"] = "Singapore";
    PublicHoliday["SouthAfrica"] = "SouthAfrica";
    PublicHoliday["SouthKorean"] = "SouthKorean";
    PublicHoliday["Spain"] = "Spain";
    PublicHoliday["Swedish"] = "Swedish";
    PublicHoliday["Taiwan"] = "Taiwan";
    PublicHoliday["Thai"] = "Thai";
    PublicHoliday["UK"] = "UK";
    PublicHoliday["US"] = "US";
    PublicHoliday["Vietnamese"] = "Vietnamese";
})(PublicHoliday || (PublicHoliday = {}));
/**
 * Public holidays regions codes
 */
const publicHolidaysRegionsCodes = {
    Australian: "australian",
    Austrian: "austrian",
    Brazilian: "brazilian",
    Canadian: "canadian",
    China: "china",
    Christian: "christian",
    Danish: "danish",
    Dutch: "dutch",
    Finnish: "finnish",
    French: "french",
    German: "german",
    Greek: "greek",
    HongKongC: "hong_kong_c",
    HongKong: "hong_kong",
    Indian: "indian",
    Indonesian: "indonesian",
    Iranian: "iranian",
    Irish: "irish",
    Islamic: "islamic",
    Italian: "italian",
    Japanese: "japanese",
    Jewish: "jewish",
    Malaysian: "malaysia",
    Mexican: "mexican",
    NewZealand: "new_zealand",
    Norwegian: "norwegian",
    Philippines: "philippines",
    Polish: "polish",
    Portuguese: "portuguese",
    Russian: "russian",
    Singapore: "singapore",
    SouthAfrica: "sa",
    SouthKorean: "south_korea",
    Spain: "spain",
    Swedish: "swedish",
    Taiwan: "taiwan",
    Thai: "th",
    UK: "uk",
    US: "usa",
    Vietnamese: "vietnamese",
};
/**
 * Fetch public holidays of a specific region
 * @param region
 * @param timeZone
 * @param locale
 * @param startTime
 * @param endTime
 */
export const fetchPublicHolidays = (region, timeZone, locale, startTime, endTime, googleMapApiKey) => {
    const googleApiKey = googleMapApiKey ?? "";
    return new Promise((resolve) => {
        if (!region)
            return resolve(new Array());
        const localEvents = retrieveLocalEvents(region, locale, startTime, endTime);
        if (!!localEvents)
            return resolve(localEvents);
        const regionCode = publicHolidaysRegionsCodes[region];
        let fixedStartTime = new Date(startTime).toISOString();
        let fixedEndTime = new Date(endTime).toISOString();
        let fixedLocale = locale;
        // Fix portuguese locale for google API
        if (fixedLocale === "pt")
            fixedLocale = "pt_PT";
        const url = baseGoogleCalendarApiUrl + "/" + locale + "." + regionCode + "%23" + basePublicHolidaysCalendarId + "/events?" +
            encodeURI("key=" + googleApiKey + "&" +
                "fields=items(summary,start/date,end/date)" + "&" +
                "timezone=" + timeZone + "&" +
                "locale=" + fixedLocale + "&" +
                "timeMin=" + fixedStartTime + "&" +
                "timeMax=" + fixedEndTime);
        axiosInstance.get(url).then(response => {
            if (response.status !== 200) {
                console.error("Can't fetch public holidays from Google API");
                return resolve(new Array());
            }
            const holidays = response.data.items;
            const events = formatGoogleCalendarEventsToEvents(holidays, timeZone, locale, region, startTime, endTime);
            saveEventsToLocalStorage(events);
            return resolve(events);
        }).catch(error => {
            console.error("Can't fetch public holidays from Google API");
            return resolve(new Array());
        });
    });
};
/**
 * Format google calendar events to events
 * @param googleCalendarEvents
 * @param timeZone
 * @param locale
 * @param region
 * @param startTime
 * @param endTime
 */
const formatGoogleCalendarEventsToEvents = (googleCalendarEvents, timeZone, locale, region, startTime, endTime) => {
    let events = googleCalendarEvents.map((gce, index) => ({
        calendarId: "google_calendar_public_holidays_" + region + "_" + locale + "_" + startTime + "_" + endTime,
        allDay: true,
        timeZone: timeZone,
        id: "google_calendar_event_" + index,
        groupId: "",
        startDate: gce.start.date,
        endDate: gce.end.date,
        title: gce.summary,
        modeRecurrence: IOptionRecurrence.NotRepeat,
        isRecurrenceValid: true,
    }));
    events.forEach(event => {
        const eventsAtSameDates = events.filter(e => e.id !== event.id &&
            e.startDate === event.startDate && e.endDate === event.endDate);
        if (eventsAtSameDates.length > 0) {
            eventsAtSameDates.forEach(esd => {
                event.title += ", " + esd.title;
                delete events[events.indexOf(esd)];
            });
        }
    });
    events = events.filter(e => !!e);
    return events;
};
/**
 * Save local events
 * @param events
 */
const saveEventsToLocalStorage = (events) => {
    if (!localStorage || events.length <= 0)
        return;
    if (events[0])
        localStorage.setItem(events[0].calendarId, JSON.stringify(events));
};
/**
 * Retrieve local events
 */
const retrieveLocalEvents = (region, locale, startTime, endTime) => {
    if (!localStorage)
        return undefined;
    const stringEvents = localStorage.getItem("google_calendar_public_holidays_" + region + "_" + locale + "_" + startTime + "_" + endTime);
    if (!stringEvents)
        return undefined;
    return JSON.parse(stringEvents);
};
