import { PublicClientApplication } from "@azure/msal-browser";
import * as microsoftTeams from "@microsoft/teams-js";
import { ErrorModule } from "../../components/ErrorBoundary/ErrorBoundary";
export const msalRedirectionKey = "msal_redirecting";
export const IsTeamsApp = "is_team_app";
export const initMsalInstance = (clientId, inIframe) => {
    const redirectUri = window.location.origin + (inIframe ? "/blank-auth-end.html" : "");
    const authData = {
        clientId: clientId,
        authority: "https://login.microsoftonline.com/organizations",
        navigateToLoginRequestUrl: true,
        redirectUri: redirectUri,
        postLogoutRedirectUri: redirectUri,
    };
    const msalInstance = new PublicClientApplication({
        auth: authData,
        cache: { cacheLocation: "sessionStorage", storeAuthStateInCookie: false },
    });
    if (inIframe)
        return msalInstance;
    msalInstance
        .handleRedirectPromise()
        .then((tokenResponse) => {
        if (!tokenResponse)
            return;
        sessionStorage.setItem(msalRedirectionKey, JSON.stringify(tokenResponse));
    })
        .catch((error) => {
        console.error(error);
    });
    return msalInstance;
};
const getRedirectionResult = () => {
    const stringItem = sessionStorage.getItem(msalRedirectionKey) ?? "";
    if (stringItem.length === 0)
        return null;
    const parsedItem = JSON.parse(stringItem);
    if (parsedItem === null)
        return { redirecting: true };
    return parsedItem;
};
export const getAccessToken = async (clientId, isTeamsApp, msalInstance, inIframe) => {
    return new Promise(async (resolve) => {
        const accessScope = `api://${window.location.host}/${clientId}/access_as_user`;
        if (!isTeamsApp)
            return resolve(await getTokenWithScopesFromWeb([accessScope], msalInstance, inIframe));
        else
            return resolve(await getAccessTokenFromTeams());
    });
};
export const msalLogin = (scopes, prompt, msalInstance, inIframe) => {
    return new Promise(async (resolve, reject) => {
        if (inIframe) {
            const authResult = await msalInstance.loginPopup({ scopes, prompt });
            return resolve(extractTokenResult(authResult));
        }
        else {
            await msalInstance.loginRedirect({ scopes, prompt });
            return resolve(undefined);
        }
    });
};
export const getTokenWithScopesFromWeb = (scopes, msalInstance, inIframe) => {
    return new Promise(async (resolve) => {
        try {
            let redirectionResult = getRedirectionResult();
            if (redirectionResult && "redirecting" in redirectionResult && redirectionResult?.redirecting) {
                const resetTimeout = setTimeout(() => {
                    sessionStorage.removeItem(msalRedirectionKey);
                    window.location.reload();
                }, 3000);
                const interval = setInterval(() => {
                    redirectionResult = getRedirectionResult();
                    if (!("authority" in (redirectionResult ?? {})))
                        return;
                    clearTimeout(resetTimeout);
                    clearInterval(interval);
                    sessionStorage.removeItem(msalRedirectionKey);
                    return resolve(extractTokenResult(redirectionResult));
                }, 500);
            }
            else {
                const userAccount = msalInstance.getAllAccounts()[0];
                if (!userAccount)
                    throw new Error("User not logged in");
                const loginResponse = await msalInstance.ssoSilent({
                    scopes,
                    loginHint: userAccount.username,
                    extraQueryParameters: { domain_hint: "organizations" },
                });
                sessionStorage.removeItem(msalRedirectionKey);
                return resolve(extractTokenResult(loginResponse));
            }
        }
        catch (err) {
            sessionStorage.setItem(msalRedirectionKey, JSON.stringify(null));
            try {
                const result = await msalLogin(scopes, "select_account", msalInstance, inIframe);
                if (!!result)
                    return resolve(result);
            }
            catch (err) {
                const result = await msalLogin(scopes, "consent", msalInstance, inIframe);
                if (!!result)
                    return resolve(result);
            }
        }
    });
};
export const extractTokenResult = (result) => {
    return {
        authResult: true,
        token: result.accessToken,
        tenantId: result.tenantId,
        userId: result.uniqueId,
        userMail: result.account?.username ?? "",
        userName: result.account?.name ?? "",
    };
};
export const getAccessTokenFromTeams = async () => {
    try {
        const token = await microsoftTeams.authentication.getAuthToken({ silent: true });
        return { authResult: true, token };
    }
    catch (e) {
        microsoftTeams.app.notifySuccess();
        const token = await microsoftTeams.authentication.getAuthToken({ silent: false });
        if (!token) {
            ErrorModule.showErrorAlert("User cancelled consent");
            return;
        }
        return { authResult: true, token };
    }
};
