import { createSlice } from "@reduxjs/toolkit";
import { getSliceSelector } from "front";
import { DisplayMode } from "../../interfaces/Enums";
const initialState = {
    displayMode: DisplayMode.Loading,
    searchTag: "",
    calendarTimeZone: "",
    activeStartDate: "",
};
const navbarSlice = createSlice({
    name: "navBar",
    initialState,
    reducers: {
        setNavbar: (state, action) => {
            Object.assign(state, action.payload);
        },
    },
});
export const { setNavbar } = navbarSlice.actions;
export const useNavBarSelector = getSliceSelector(navbarSlice);
export const navBarReducer = navbarSlice.reducer;
