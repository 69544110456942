import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Flex, Text } from "@fluentui/react-northstar";
import { useCallback, memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePremiumSelector } from "../../../redux/reducers/PremiumReducer/PremiumReducer";
import { openPremiumDialog } from "../../../redux/reducers/ModalReducer/ModalReducer";
export const PremiumBanner = memo((props) => {
    const dispatch = useDispatch();
    const { isPremium } = usePremiumSelector("isPremium");
    const appInsightInstance = useSelector((s) => s.infosApp.appInsightInstance);
    const openPremium = useCallback(() => {
        dispatch(openPremiumDialog(appInsightInstance));
    }, [appInsightInstance]);
    if (isPremium)
        return;
    return (_jsx(Alert, { content: _jsxs(Flex, { children: [_jsx(Text, { styles: {
                        textDecoration: "none",
                        cursor: "default",
                        marginRight: "5px",
                    }, content: props.message1 }), _jsx(Text, { styles: {
                        textDecoration: "underline",
                        cursor: "pointer",
                    }, onClick: openPremium, weight: "bold", content: _jsx(_Fragment, { children: props.messageClick }) }), _jsx(Text, { styles: {
                        textDecoration: "none",
                        cursor: "default",
                        marginLeft: "5px",
                    }, content: props.message2 })] }), visible: true, warning: true }));
});
