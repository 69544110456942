import moment from "moment";
import { WeekDay } from "../interfaces/WeekDay";
const getFirstDayOfWeek = () => {
    return getWeekDaysOrderPerCulture()[0];
};
const getWeekDaysOrderPerCulture = () => {
    const currentLocaleData = moment.localeData();
    return moment.weekdays(true).map(d => currentLocaleData.weekdaysParse(d, "dddd", false));
};
const defaultBusinessDays = new Array(WeekDay.Monday, WeekDay.Tuesday, WeekDay.Wednesday, WeekDay.Thursday, WeekDay.Friday);
const getDateOfDayXWeeksAfter = (weeksToAdd, date) => {
    const momDate = !date ? moment() : moment(date);
    momDate.add(weeksToAdd, "weeks").startOf("day");
    return momDate.toISOString(false);
};
export const WeekDaysModule = {
    getWeekDaysOrderPerCulture,
    defaultBusinessDays,
    getDateOfDayXWeeksAfter,
    getFirstDayOfWeek,
};
