import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useState, useRef } from "react";
import { Flex, LocationIcon, Text, Dropdown } from "@fluentui/react-northstar";
import { GoogleMap } from "./GoogleMapComponent";
import { useMsTeamsSelector } from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { useTranslate } from "../../hook/useTranslate";
import { translations } from "../../translations";
import { usePremiumSelector } from "../../redux/reducers/PremiumReducer/PremiumReducer";
import { loadGoogleMapScript } from "../../utils/google";
import { useConfigurationSelector } from "../../redux/reducers/ConfigurationReducer/ConfigurationReducer";
import { IGoogleMapsLibraries } from "../../interfaces/IGoogleMapsLibrairies";
import { IGoogleVersion } from "../../interfaces/IGoogleMapVersion";
export const labelsMaxWidth = "180px";
export const GoogleRenderComponent = (props) => {
    const { isOnMobile, themeClass } = useMsTeamsSelector("isOnMobile", "themeClass");
    const { isPremium } = usePremiumSelector("isPremium");
    const { data } = useConfigurationSelector("data");
    const [isScriptLoading, setIsScriptLoading] = useState(false);
    const [address, setAddress] = useState(props.address?.fullAddress);
    const [predictionsAdr, setPredictionAdr] = useState([]);
    const autocompleteServiceRef = useRef();
    const placeServiceRef = useRef();
    const isAdrSelectedByAction = useRef(false);
    const t = useTranslate(translations);
    useEffect(() => {
        if (data.googleMapApiKey == "")
            return;
        const googleMapScript = loadGoogleMapScript(data.googleMapApiKey, [IGoogleMapsLibraries.PLACES, IGoogleMapsLibraries.GEOMETRY], IGoogleVersion.WEEKLY);
        if (googleMapScript.ready)
            setIsScriptLoading(true);
        if (!googleMapScript.script) {
            console.error("Can't load google maps script");
            return;
        }
        const onScriptLoaded = () => {
            setIsScriptLoading(true);
            googleMapScript.script.removeEventListener("load", onScriptLoaded);
        };
        googleMapScript.script.addEventListener("load", onScriptLoaded);
    }, [data]);
    useEffect(() => {
        setAddress(props.address?.fullAddress);
    }, [props.address]);
    useEffect(() => {
        if (!isScriptLoading)
            return;
        autocompleteServiceRef.current = new google.maps.places.AutocompleteService();
        const map = document.getElementById("google-map");
        placeServiceRef.current = new google.maps.places.PlacesService(map);
    }, [isScriptLoading]);
    const renderGoogleMap = () => {
        return !props.hideLabel ? (_jsxs(Flex, { column: isOnMobile, children: [_jsx(Flex, { styles: { width: isOnMobile ? 0 : labelsMaxWidth } }), _jsx(Flex.Item, { grow: true, children: _jsx(Flex, { vAlign: "center", style: { height: "200px" }, children: _jsx(GoogleMap, { labelIsHidden: props.hideLabel, theme: themeClass, mapType: google.maps.MapTypeId.ROADMAP, mapTypeControl: false, lat: props.address?.lat, lng: props.address?.lng }) }) })] })) : (_jsx(GoogleMap, { labelIsHidden: props.hideLabel, theme: themeClass, mapType: google.maps.MapTypeId.ROADMAP, mapTypeControl: false, lat: props.address?.lat, lng: props.address?.lng }));
    };
    const handleAddress = async (e, { searchQuery }) => {
        setAddress(searchQuery);
        if (autocompleteServiceRef.current && placeServiceRef.current) {
            const preds = await autocompleteServiceRef.current.getPlacePredictions({ input: searchQuery });
            const adrInfos = [];
            if (preds && Array.isArray(preds.predictions))
                preds.predictions.forEach((pred, i) => {
                    const request = {
                        placeId: pred.place_id,
                        fullAddress: pred.description,
                    };
                    adrInfos.push(request);
                    if (i == preds.predictions.length - 1) {
                        setPredictionAdr(adrInfos);
                    }
                });
        }
        if (searchQuery === "") {
            props.updateAddress({
                fullAddress: "",
                lat: undefined,
                lng: undefined,
            });
        }
    };
    const updateAdress = (_, items) => {
        if (items.value) {
            const adrInfo = JSON.parse(items.value.key);
            placeServiceRef.current.getDetails({ placeId: adrInfo.placeId, fields: ["formatted_address", "geometry"] }, (place, status) => {
                const addr = {
                    fullAddress: place.formatted_address,
                    lat: place.geometry?.location.lat(),
                    lng: place.geometry?.location.lng(),
                };
                props.updateAddress(addr);
            });
            return;
        }
        props.updateAddress({
            fullAddress: "",
            lat: undefined,
            lng: undefined,
        });
    };
    const hasPermissions = () => {
        if (!props.checkPremium)
            return false;
        return !isPremium;
    };
    /**
     * Render address input
     */
    const renderAddressInput = () => (_jsxs(Flex, { column: isOnMobile, gap: isOnMobile ? "gap.small" : "gap.medium", fill: props.fill, children: [!props.hideLabel && (_jsx(Flex.Item, { styles: { minWidth: labelsMaxWidth }, children: _jsxs(Flex, { vAlign: "center", gap: "gap.small", children: [_jsx(LocationIcon, { outline: true, className: "Icon" }), _jsx(Text, { truncated: true, content: t("AddressTitle") })] }) })), _jsx(Flex.Item, { grow: true, children: _jsx(Flex, { vAlign: "center", fill: props.fill, children: _jsx(Dropdown, { search: true, fluid: isOnMobile, inverted: props.inverted ?? !isOnMobile, disabled: hasPermissions() || props.disabled, items: predictionsAdr.map((pred) => ({
                            key: JSON.stringify(pred),
                            header: pred.fullAddress,
                        })), placeholder: address ?? t("AddAddress"), noResultsMessage: t("NoAddress"), onChange: updateAdress, onSearchQueryChange: handleAddress }) }) })] }));
    return (_jsxs(_Fragment, { children: [renderAddressInput(), isScriptLoading && renderGoogleMap()] }));
};
