import { createSlice } from "@reduxjs/toolkit";
import { getSliceSelector } from "../../utils";
import * as array from "../../../utils/array";
const initialState = {
    messages: [],
};
const errorSlice = createSlice({
    name: "error",
    initialState: initialState,
    reducers: {
        setErrorMessage(state, action) {
            state.messages = array._addArray(state.messages, action.payload) ?? state.messages;
        },
        removeErrorMessage(state, action) {
            state.messages = array._removeArray(state.messages, action.payload) ?? state.messages;
        },
        removeAllMessage(state) {
            state.messages = [];
        },
    },
});
export const { setErrorMessage, removeErrorMessage, removeAllMessage } = errorSlice.actions;
export const errorReducer = errorSlice.reducer;
export const useErrorSelector = getSliceSelector(errorSlice);
