import { createSlice } from "@reduxjs/toolkit";
import { getSliceSelector } from "front";
const initialState = {
    allowShowWeekend: true,
    allowShowNumberOfWeek: true,
    allowBuisnessDay: true,
    allowPublicHolidays: true,
    allowPerms: true,
    allowSync: true,
    forceRessource: false,
    allowNotification: false,
    allowAddFile: false,
    allowMeetingScheduler: false,
    allowCopy: false,
    allowWhatNew: false,
    allowDoubleBooking: true,
    nameApp: ""
};
const optionsSlice = createSlice({
    name: "optionsApp",
    initialState,
    reducers: {
        setOptions: (state, action) => {
            Object.assign(state, action.payload);
        },
    },
});
export const { setOptions } = optionsSlice.actions;
export const useOptionsSelector = getSliceSelector(optionsSlice);
export const optionsReducer = optionsSlice.reducer;
