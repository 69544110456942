import React, { ReactElement, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import TelemetryService, { appInsightInstance } from "./services/TelemetryService/TelemetryService";
import { AppViews } from "./const/AppViews";
import { AppView } from "./interfaces/AppView/AppView";
import { Loader, Provider, ThemeInput } from "@fluentui/react-northstar";
import { AppViewData } from "./interfaces/AppView/AppViewData";
import {
  useAppInitializer,
  wrapWithErrorBoundary,
  Consent,
  useMsTeamsSelector,
  useAuthSelector,
  UpgradePremiumDialog,
  useConfigurationSelector,
  useGoogleInitScript,
  IGoogleMapsLibraries,
  NewsDialog,
  IAppType,
} from "front";
import { translations } from "./translations";
import Dialogs from "./components/Dialogs/Dialogs";

export const App = () => {
  const { loaded } = useAppInitializer({ needInitializegraph: false });
  const msTeamsContext = useMsTeamsSelector("locale", "theme");
  translations.locale = msTeamsContext.locale;
  const { isTeamsApp } = useAuthSelector("isTeamsApp");
  const { data: config } = useConfigurationSelector("data");

  const { scriptIsLoading } = useGoogleInitScript({
    libraries: [IGoogleMapsLibraries.PLACES, IGoogleMapsLibraries.DIRECTIONS],
  });

  const wrapWithFluentUI = getFluentUIWrapper(msTeamsContext.locale, msTeamsContext.theme);

  if (!loaded) return wrapWithFluentUI(<Consent />);

  return wrapWithErrorBoundary(
    wrapWithFluentUI(
      <TelemetryService>
        {loaded && scriptIsLoading ? (
          <>
            <NewsDialog AppType={IAppType.MAP} />
            <UpgradePremiumDialog appId={config.adminCenterAppId} appInsight={appInsightInstance} />
            <Dialogs />
            <Routes>
              {renderRoutes(AppViews)}
              {!isTeamsApp && <Route path="*" element={<Navigate to={"home/" + process.env.REACT_APP_CALENDAR_MOCK_ID} replace />} />}
            </Routes>
          </>
        ) : (
          <Loader styles={{ height: "100vh" }} />
        )}
      </TelemetryService>
    )
  );
};
const renderRoutes = (appViews: Record<AppView, AppViewData>) => {
  return Object.values(appViews).map((view) => <Route key={view.path} path={view.path} element={<view.element />} />);
};

const getFluentUIWrapper = (locale: string, theme: ThemeInput) => (children: ReactElement) =>
  (
    <Provider lang={locale} theme={theme}>
      {children}
    </Provider>
  );
