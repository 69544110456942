import { z } from "zod";
import { guidSchema } from "./GUID.schema";
export const errorSchema = z.object({
    id: z.string(),
    message: z.string(),
    functionName: z.string().optional().default(""),
    functionArgs: z.any().optional(),
    stack: z.string().optional().default(""),
    appId: guidSchema.optional(),
    tenantId: guidSchema.optional(),
    date: z.date(),
});
