import { useEffect, useRef, useState } from "react";
import { Provider, teamsDarkTheme, teamsHighContrastTheme, teamsTheme, ThemePrepared } from "@fluentui/react-northstar";
import { HomePro } from "@witivio_teamspro/home-pro-sdk";
import { useHomeProToken, useTranslate } from "front";
import { translations } from "../../translations";
import { IPlace } from "../../types/IPlace/IPlace";
import GoogleMap from "../../components/GoogleMap/GoogleMap";
import { createMarker } from "../../utils/createMarker";
import { ReduxStoreState } from "../../redux/store";
import { useSelector } from "react-redux";
import axios from "axios";

export interface ITranslations {
  en: Record<string, string>;
  fr: Record<string, string>;
  de: Record<string, string>;
  it: Record<string, string>;
  es: Record<string, string>;
  pt: Record<string, string>;
}

export interface IWidgetTheme {
  theme: ThemePrepared;
  locale: string;
  className: string;
  lang: Record<string, string>;
  onMobile: boolean;
  tenantDomain: string;
}

const THEMES = {
  default: teamsTheme,
  dark: teamsDarkTheme,
  contrast: teamsHighContrastTheme,
};

interface ITHEMES {
  default: ThemePrepared;
  dark: ThemePrepared;
  contrast: ThemePrepared;
}

const Widget = () => {
  const t = useTranslate(translations);
  const [context, setContext] = useState<IWidgetTheme>({
    locale: "en",
    theme: teamsTheme,
    lang: t,
    tenantDomain: "",
    onMobile: false,
    className: "default",
  });

  // Selector
  const { googleMap, oms } = useSelector((s: ReduxStoreState) => s.googleMap);

  // Hooks
  const { token } = useHomeProToken();

  // States
  const [markers, setMarkers] = useState<Map<string, google.maps.Marker>>(new Map());

  // Ref
  const initialPlacesAreInit = useRef<boolean>(false);

  useEffect(() => {
    if (initialPlacesAreInit.current || !token) return;
    initInitialMarkers();
  }, [googleMap, oms, token]);

  const initInitialMarkers = async () => {
    if (!googleMap || !oms) return;
    const places = await getPlaces();
    if (!places) return;
    places.forEach(addMarker);
    initialPlacesAreInit.current = true;
  };

  const getPlaces = async (): Promise<IPlace[]> => {
    const mapId = (await HomePro.settings.getSettings()).id;
    const res = await axios.get(`/api/v1/maps/${mapId}/places`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return res.data;
  };

  const addMarker = (place: IPlace) => {
    if (!googleMap || !oms) return;
    const { id, title, description, address, lat, lng } = place as any;
    const marker = createMarker(googleMap, oms, { id, title, description, address, lat, lng });
    markers.set(place.id as string, marker);
    setMarkers(new Map(markers));
  };

  const getThemeClassname = (theme: string) => {
    switch (theme) {
      case "default":
        return "";
      case "dark":
        return "darkContainer";
      case "contrast":
        return "highContrastContainer";
      default:
        return "";
    }
  };

  return (
    <Provider theme={context.theme}>
      <div style={{ height: "100vh", width: "100vw" }} className={getThemeClassname(context.className)}>
        <GoogleMap />
      </div>
    </Provider>
  );
};

export default Widget;
