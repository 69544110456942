import { IOptionTrip } from "../types/IOptionTrip/IOptionTrip";

export const convertToGoogleMapsTravelMode = (option: IOptionTrip): google.maps.TravelMode => {
  const map: Record<IOptionTrip, google.maps.TravelMode> = {
    [IOptionTrip.Car]: google.maps.TravelMode.DRIVING,
    [IOptionTrip.Train]: google.maps.TravelMode.TRANSIT,
    [IOptionTrip.Foot]: google.maps.TravelMode.WALKING,
    [IOptionTrip.Bicycle]: google.maps.TravelMode.BICYCLING,
  };
  const res = map[option];
  if (!res) throw new Error("No travel mode option found");
  return res;
};
