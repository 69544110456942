import React from "react";
import { IOptionTrip } from "../../../types/IOptionTrip/IOptionTrip";
import CarIcon from "../../../icons/CarIcon";
import TrainIcon from "../../../icons/TrainIcon";
import WalkIcon from "../../../icons/WalkIcon";
import BikeIcon from "../../../icons/BikeIcon";

export const typeInputs = [
  {
    key: IOptionTrip.Car,
    icon: <CarIcon />,
  },
  {
    key: IOptionTrip.Train,
    icon: <TrainIcon />,
  },
  {
    key: IOptionTrip.Foot,
    icon: <WalkIcon />,
  },
  {
    key: IOptionTrip.Bicycle,
    icon: <BikeIcon />,
  },
];
