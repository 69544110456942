import { useMutation, useQuery } from "react-query";
import { useAppConfigurationSelector } from "../../redux/reducers/AppConfigurationReducer/AppConfigurationReducer";
import { getNotificationsConfigByCalendarId, patchNotificiationConfig } from "../../apis/Notifications/NotificationsApi";
import { useDispatch, useSelector } from "react-redux";
import { setNotifConfig } from "../../redux/reducers/NotificationsReducer/NotificationsReducer";
import { handleExpiredToken } from "../../utils/handleExpiredToken";
export const useNotificationCache = () => {
    const { appId } = useAppConfigurationSelector("appId");
    const queryClient = useSelector((s) => s.infosApp.queryClient);
    const dispatch = useDispatch();
    const { data: notif } = useQuery("app_notifications", () => getNotificationsConfigByCalendarId(appId), {
        staleTime: Infinity,
        enabled: !!appId,
        ...handleExpiredToken,
        onSuccess: (res) => {
            dispatch(setNotifConfig(res));
        }
    });
    const { mutate: mutateNotif } = useMutation((notification) => patchNotificiationConfig(notification.projectId, notification), {
        ...handleExpiredToken,
        onSuccess: (data, variable) => {
            queryClient.setQueryData("app_notifications", variable);
        }
    });
    return {
        notif,
        mutateNotif
    };
};
