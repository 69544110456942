import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    queryClient: undefined,
    appViews: undefined,
    appInsightInstance: {}
};
const infosSlice = createSlice({
    name: "infosApp",
    initialState,
    reducers: {
        setInfos: (state, action) => {
            Object.assign(state, action.payload);
        },
    },
});
export const { setInfos } = infosSlice.actions;
export const infosReducer = infosSlice.reducer;
