const defaultErrorMessage = "errorOccured";
export const tryCatch = (callback) => async (data) => {
    try {
        return await callback(data);
    }
    catch (err) {
        throw catchError(err);
    }
};
export const catchError = (err) => {
    let error;
    if (err.response) {
        error = err.response.data;
    }
    else if (err.request) {
        error = defaultErrorMessage;
    }
    else if (typeof err === "string") {
        error = err;
    }
    else {
        error = defaultErrorMessage;
    }
    return error || defaultErrorMessage;
};
