import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useRef, useState } from "react";
//import "./ErrorBoundary.styles.scss";
import { Alert, CloseIcon, Dialog, Divider, ExclamationTriangleIcon, Flex, Provider, teamsTheme, Text, Tooltip } from "@fluentui/react-northstar";
const defaultShowErrorAlertFunc = (title) => {
    if (title)
        console.error(title);
};
const defaultShowErrorDialogFunc = (data) => {
    console.error(data.title, ":", data.subtitle);
};
export const ErrorModule = {
    showErrorAlert: defaultShowErrorAlertFunc,
    showErrorDialog: defaultShowErrorDialogFunc,
};
const ErrorBoundary = () => {
    const [errorData, setErrorData] = useState();
    const timeoutRef = useRef();
    useEffect(function onMount() {
        ErrorModule.showErrorAlert = showErrorAlert(setErrorData, timeoutRef);
        ErrorModule.showErrorDialog = showErrorDialog(setErrorData);
        return () => {
            ErrorModule.showErrorAlert = defaultShowErrorAlertFunc;
            ErrorModule.showErrorDialog = defaultShowErrorDialogFunc;
        };
    }, []);
    const handleClose = (e) => {
        e?.stopPropagation();
        setErrorData(undefined);
    };
    const handleCancelTimeout = () => {
        if (!timeoutRef.current)
            return;
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
    };
    return renderError(errorData, handleClose, handleCancelTimeout);
};
///////////////////////////////////////////////////// PURE METHODS /////////////////////////////////////////////////////
const showErrorAlert = (setErrorData, timeoutRef) => (title, error) => {
    setErrorData({ ...(title && { title }), error, inDialog: false });
    timeoutRef.current = setTimeout(() => {
        if (!timeoutRef.current)
            return;
        setErrorData(undefined);
    }, 5000);
};
const showErrorDialog = (setErrorData) => (data) => {
    setErrorData({ ...data, inDialog: true });
};
const renderError = (errorData, onClose, onCancelTimeout) => {
    if (!errorData)
        return null;
    return errorData.inDialog ? renderErrorDialog(errorData, onClose) : renderErrorAlert(errorData, onClose, onCancelTimeout);
};
const renderAxiosErrorDetails = (error) => {
    const endpoint = error.config?.method?.toUpperCase() + " " + error.request?.responseURL;
    const body = JSON.stringify(error.config?.data);
    const responseData = error.response?.data?.detail;
    return (_jsxs(Flex, { column: true, gap: "gap.smaller", children: [_jsx(Divider, {}), endpoint && _jsx(Text, { size: "medium", content: endpoint }), body && _jsx(Text, { size: "medium", content: body }), endpoint && responseData && _jsx(Divider, {}), responseData && _jsx(Text, { size: "medium", content: responseData })] }));
};
const renderErrorDetails = (errorData) => {
    const axiosError = errorData.error?.name === "AxiosError" ? errorData.error : undefined;
    return (_jsxs(Flex, { column: true, gap: "gap.smaller", style: { maxHeight: "50vh", maxWidth: "100vw", overflow: "scroll" }, children: [!errorData.title ? null :
                _jsx(Text, { weight: "semibold", size: "medium", content: errorData.title }), !errorData.error ? null :
                _jsxs(_Fragment, { children: [_jsx(Text, { size: "medium", content: errorData.error?.message }), !axiosError ? null : renderAxiosErrorDetails(axiosError)] })] }));
};
const renderErrorAlert = (errorData, onClose, onCancelTimeout) => {
    return (_jsx("div", { className: "errors-container", children: _jsx(Tooltip, { mouseLeaveDelay: 500, trigger: _jsx(Alert, { onClick: onCancelTimeout, styles: { padding: "0 5px" }, danger: true, content: _jsx(Flex, { vAlign: "center", styles: { gap: "-10px" }, children: _jsx(CloseIcon, { styles: { padding: "5px" }, className: "cursor-pointer", size: "small", onClick: onClose }) }) }), content: renderErrorDetails(errorData), position: "above", align: "end" }) }));
};
const renderErrorDialog = (errorData, onClose) => {
    const title = errorData.title;
    return (_jsx(Dialog, { open: true, styles: { width: "400px" }, content: _jsxs(Flex, { fill: true, className: "h-100", column: true, vAlign: "center", hAlign: "center", gap: "gap.small", children: [_jsx(ExclamationTriangleIcon, { size: "largest", styles: { color: "rgb(196, 49, 75)", transform: "scale(2)", padding: "40px 0 30px 0" } }), _jsx(Text, { content: title, size: "large", align: "center" }), !errorData.subtitle ? null : _jsx(Text, { content: errorData.subtitle, align: "center" })] }), headerAction: errorData.canDismiss === false ? null : {
            icon: _jsx(CloseIcon, {}),
            onClick: onClose,
        } }));
};
let errorBoundaryInstance = null;
export const wrapWithErrorBoundary = (children) => {
    if (!errorBoundaryInstance)
        errorBoundaryInstance = _jsx(ErrorBoundary, {});
    return (_jsxs(Provider, { lang: "en", theme: teamsTheme, children: [children, errorBoundaryInstance] }));
};
