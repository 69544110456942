import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSliceSelector } from "../../utils";
import { PREMIUM_GET } from "./PremiumReducer.interfaces";
import { getAdminCenterData } from "../../../apis/AdminCenter/adminCenterApi";
import { manageExpiration } from "../PersistentAlertReducer/PersistentAlertReducer";
const initialState = {
    loaded: false,
    isPremium: !!process.env.REACT_APP_ISPREMIUM,
    isPlatinum: !!process.env.REACT_APP_ISPLATINUM,
    isAdmin: false,
    subscriptionLicenceId: "",
    admins: new Array(),
    adminCenterData: undefined,
    subscriptionCardExpiring: false,
    isSubscriptionOwner: false
};
const slice = createSlice({
    name: "premium",
    initialState,
    reducers: {
        setPremium: (state, action) => {
            Object.assign(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getPremium.fulfilled, (state, action) => {
            Object.assign(state, action.payload);
        });
        builder.addCase(getPremium.rejected, (state) => {
            Object.assign(state, {
                loaded: true,
                isPremium: !!process.env.REACT_APP_ISPREMIUM,
                isPlatinum: !!process.env.REACT_APP_ISPLATINUM,
                isAdmin: !!process.env.REACT_APP_ISPREMIUM || !!process.env.REACT_APP_ISPLATINUM,
                subscriptionLicenceId: "",
                admins: new Array(),
            });
        });
    },
});
export const getPremium = createAsyncThunk(PREMIUM_GET, async (data, { getState, dispatch }) => {
    const conf = getState().configuration;
    const acdata = await getAdminCenterData(data.tid, data.userId, conf.data.adminCenterAppId, conf.data.adminCenterBaseUrl);
    dispatch(manageExpiration({
        adminCenterBaseUrl: conf.data.adminCenterBaseUrl,
        subscriptionCardExpiring: acdata.subscriptionCardExpiring,
        userId: data.userId,
        subscriptionOwner: acdata.subscriptionOwner,
        subscriptionLicenceId: acdata.subscriptionLicenceId,
    }));
    return {
        isPremium: !!process.env.REACT_APP_ISPREMIUM || acdata.isUserPremium,
        isPlatinum: !!process.env.REACT_APP_ISPLATINUM || acdata.isUserPlatinum,
        isAdmin: acdata.isUserAdmin,
        subscriptionLicenceId: acdata.subscriptionLicenceId,
        admins: acdata.admins,
        loaded: true,
        adminCenterData: acdata,
    };
});
export const { setPremium } = slice.actions;
export const usePremiumSelector = getSliceSelector(slice);
export const premiumReducer = slice.reducer;
