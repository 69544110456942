import { OverlappingMarkerSpiderfier } from "ts-overlapping-marker-spiderfier";
import ReactDOMServer from "react-dom/server";
import { IMarkerIcon } from "../types/IMarkerIcon/IMarkerIcon";

let allInfoWindows = [] as any[];

export const createMarker = (
  map: google.maps.Map<HTMLDivElement>,
  oms: OverlappingMarkerSpiderfier,
  values: {
    id: string;
    title: string;
    description?: string;
    lat: number;
    lng: number;
    address: string;
    icon?: IMarkerIcon;
    color?: string;
  }
): google.maps.Marker => {
  const fillColor = values.color ?? "#6264a7";
  const { id, title, description, lat, lng, address, icon } = values;
  const iconOverSvg = icon ? icon : "";

  const svg =
    '<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="16 5 48 56" xmlns:xlink="http://www.w3.org/1999/xlink">' +
    '   <path transform="scale(2.5)" d="M12 2C8.13 2 5 5.13 5 9 5 14.25 12 22 12 22S19 14.25 19 9C19 5.13 15.87 2 12 2Z" style="fill:white"/>' +
    '   <path transform="scale(2.5)" d="M12 2.5C8.5 2.5 5.5 5.5 5.5 9 5.4 14 12 21.3 12 21.3S18.5 14 18.5 9C18.5 5.5 15.5 2.5 12 2.5Z" style="fill:' +
    fillColor +
    '"/>' +
    '   <image xlink:href="' +
    iconOverSvg +
    '" x="21" y="15" height="18" width="18" />' +
    "</svg>";

  const marker = new google.maps.Marker({
    map,
    title: title ?? "",
    icon: {
      url: "data:image/svg+xml;charset=utf-8," + encodeURIComponent(svg),
      anchor: new google.maps.Point(19, 53),
    },
    position: { lat: lat ?? 0, lng: lng ?? 0 },
  });
  marker.set("id", id);

  oms.addMarker(marker, () => {
    allInfoWindows.forEach((iw) => iw.close());
    infoWindow.setContent(renderInfoWindow({ title, description, address }));
    infoWindow.open(map, marker);
  });

  const infoWindow = new google.maps.InfoWindow({
    pixelOffset: new google.maps.Size(-10, 0),
  });
  allInfoWindows.push(infoWindow);

  return marker;
};

const renderInfoWindow = (values: { title: string; description: string | undefined; address: string }): string => {
  const formatDescription = (description: string | undefined) => {
    return (
      description &&
      description.split("\n").map((line, index, array) => (
        <div key={index}>
          {line}
          {index < array.length - 1 && <br />}
        </div>
      ))
    );
  };

  const content = (
    <>
      <span style={{ fontWeight: "bold", wordWrap: "break-word", overflowWrap: "break-word", whiteSpace: "pre-wrap" }}>{values.title}</span>
      {values.description && <span style={{ wordWrap: "break-word", overflowWrap: "break-word", whiteSpace: "pre-wrap" }}>{formatDescription(values.description)}</span>}
      <span style={{ color: "darkgrey" }}>{values.address}</span>
    </>
  );
  return ReactDOMServer.renderToString(<div className={"google-map-info-window"}>{content}</div>);
};
