;
export var InputTagName;
(function (InputTagName) {
    InputTagName["Email"] = "email";
    InputTagName["FirstName"] = "firstName";
    InputTagName["LastName"] = "lastName";
    InputTagName["CompanyName"] = "companyName";
    InputTagName["JobTitle"] = "jobTitle";
    InputTagName["PhoneNumber"] = "phoneNumber";
    InputTagName["Note"] = "note";
})(InputTagName || (InputTagName = {}));
;
