export const uniqueString = (strings) => {
    let values = [];
    strings.forEach((s) => {
        if (!s)
            return;
        if (values.includes(s))
            throw `The string "${s}" is duplicated.`;
        values.push(s);
    });
};
