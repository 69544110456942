import { Button, CloseIcon, Flex, Text } from "@fluentui/react-northstar";
import React from "react";

const SideViewTop = (props: { title: string; onClose: () => void; showCloseBtn: boolean }) => {
  return (
    <Flex hAlign="center" vAlign="center" fill style={{ position: "relative" }}>
      <Text content={props.title} size="larger" weight="semibold" />
      {props.showCloseBtn && (
        <Button
          onClick={props.onClose}
          secondary
          text
          iconOnly
          icon={<CloseIcon />}
          style={{ position: "absolute", right: "0px" }}
          data-testid={"sideViewTop-close-btn"}
        />
      )}
    </Flex>
  );
};

export default SideViewTop;
