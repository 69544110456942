import { configureStore } from "@reduxjs/toolkit";
import { authReducer, configurationReducer, modalReducer, msTeamsReducer, premiumReducer, scopesReducer, userReducer, permissionsReducer, appConfigurationReducer, notificationsReducer, errorReducer, infosReducer, draftsReducer, persitantAlertReducer } from "front";
import { navBarReducer } from "./reducers/navbarReducer";
import { optionsReducer } from "./reducers/optionsReducer";
const reducer = {
    configuration: configurationReducer,
    msTeams: msTeamsReducer,
    auth: authReducer,
    scopes: scopesReducer,
    user: userReducer,
    premium: premiumReducer,
    modal: modalReducer,
    permissions: permissionsReducer,
    navBar: navBarReducer,
    appConfiguration: appConfigurationReducer,
    infosApp: infosReducer,
    optionsApp: optionsReducer,
    error: errorReducer,
    persistentAlert: persitantAlertReducer,
    notifications: notificationsReducer,
    draft: draftsReducer
};
export const initStore = () => {
    return configureStore({
        reducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: false,
        }),
    });
};
export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});
export default store;
