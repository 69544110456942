import { IOptionTrip } from "../types/IOptionTrip/IOptionTrip";
import { ITrip } from "../types/ITrip/ITrips";

export const createTripDTO = (data: {
  title: string;
  description: string;
  id: string | undefined;
  from: {
    fullAddress: string;
    lat: number;
    lng: number;
  };
  to: {
    fullAddress: string;
    lat: number;
    lng: number;
  };
  type: IOptionTrip;
  mapId: string;
}): ITrip => {
  let tripDTO: ITrip = {};

  const { title, description, id, from, to, type, mapId } = data;
  tripDTO = {
    title,
    description,
    from: { ...from, address: from.fullAddress },
    to: { ...to, address: to.fullAddress },
    mapId,
    type,
  };
  if (id) tripDTO.id = id;
  return tripDTO;
};
