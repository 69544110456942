import { AxiosConfig } from "../AxiosConfig/AxiosConfig";
export const getPermissionsByProjectId = (projectId) => new Promise(async (resolve, reject) => {
    try {
        const response = await AxiosConfig.instance.get(`/permissions/${projectId}`);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get Permission");
    }
});
export const getUsersPermissionsByProjectId = (projectId, page, search, take = 20) => new Promise(async (resolve, reject) => {
    try {
        const content_ = search;
        const response = await AxiosConfig.instance.post(`/permissions/${projectId}/users/list?page=${page}&take=${take}`, content_);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get  users Permission");
    }
});
export const getDefaultPermissionsByProjectId = (projectId) => new Promise(async (resolve, reject) => {
    try {
        const response = await AxiosConfig.instance.get(`/permissions/${projectId}/default`);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get  user default Permission");
    }
});
export const getGlobalPermissionsByProjectId = (projectId) => new Promise(async (resolve, reject) => {
    try {
        const response = await AxiosConfig.instance.get(`/permissions/${projectId}/globalperms`);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get  user global Permission");
    }
});
export const getUserPermissionsByUserId = (projectId, userId) => new Promise(async (resolve, reject) => {
    try {
        const response = await AxiosConfig.instance.get(`/permissions/${projectId}/users/${userId}`);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get  user Permission");
    }
});
export const createUserPerm = (projectId, userPermissions) => new Promise(async (resolve, reject) => {
    try {
        const content_ = JSON.stringify(userPermissions);
        const response = await AxiosConfig.instance.post(`/permissions/${projectId}/users`, content_);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't create user perm");
    }
});
export const patchUserPerm = (projectId, userPermissions) => new Promise(async (resolve, reject) => {
    try {
        const content_ = JSON.stringify(userPermissions);
        const response = await AxiosConfig.instance.patch(`/permissions/${projectId}/users`, content_);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't update user perm");
    }
});
export const patchDefaultPerm = (projectId, defaultperms) => new Promise(async (resolve, reject) => {
    try {
        const content_ = JSON.stringify(defaultperms);
        const response = await AxiosConfig.instance.patch(`/permissions/${projectId}/default`, content_);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't update user default perm");
    }
});
export const postGlobalPerms = (projectId, globalPermissions) => new Promise(async (resolve, reject) => {
    try {
        const content_ = JSON.stringify(globalPermissions);
        const response = await AxiosConfig.instance.post(`/permissions/${projectId}/globalperms`, content_);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't update user default perm");
    }
});
export const getShareCalendarByProjectId = (projectId, userId) => new Promise(async (resolve, reject) => {
    try {
        const response = await AxiosConfig.instance.get(`/permissions/${projectId}/share/${userId}`);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get  user Permission");
    }
});
export const patchUserCalendarSync = (projectId, userId, calendarSync) => new Promise(async (resolve, reject) => {
    try {
        const content_ = JSON.stringify(calendarSync);
        const response = await AxiosConfig.instance.patch(`/permissions/${projectId}/share/${userId}`, content_);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't update user calendarSync");
    }
});
export const getAllUsersPermissionsByProjectId = (projectId) => new Promise(async (resolve, reject) => {
    try {
        const response = await AxiosConfig.instance.get(`/permissions/${projectId}/users/all`);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get all users Permissions");
    }
});
export const GetAllUsersPermissionsByProjectIdByExternalisedApp = (projectId) => new Promise(async (resolve, reject) => {
    try {
        const response = await AxiosConfig.instance.get(`/permissions/externalisedapp/${projectId}/users/all`);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get all users Permissions");
    }
});
