import { AxiosConfig } from "../AxiosConfig/AxiosConfig";
const prefix = "/miscs/";
export const billingNotifySuperAdmin = (userId, appId, subscriptionId) => new Promise(async (resolve, reject) => {
    if (!userId || !appId || !subscriptionId)
        return reject("Missing parameters");
    try {
        const data = new FormData();
        data.append("userId", userId);
        data.append("appId", appId);
        data.append("subscriptionId", subscriptionId);
        const response = await AxiosConfig.instance.post(prefix + `billing-notify-superadmin`, data, { headers: { 'Content-Type': 'multipart/form-data' } });
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't notify super admin");
    }
});
