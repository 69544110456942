import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MODAL_OPEN_PREMIUM } from "./ModalReducer.interfaces";
import { getSliceSelector } from "../../utils";
import { Modal } from "../../../interfaces/Modal";
const initialState = {
    isOpen: undefined,
    data: undefined,
};
const slice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        openModal: (state, action) => {
            state.isOpen = action.payload;
        },
        setModalWithData: (state, action) => {
            const { isOpen, data } = action.payload;
            state.isOpen = isOpen;
            state.data = data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(openPremiumDialog.fulfilled, (state, action) => {
            state.isOpen = action.payload;
        });
    },
});
export const openPremiumDialog = createAsyncThunk(MODAL_OPEN_PREMIUM, async (insight) => {
    //if (config) this.upgradePremiumDialogRef?.updateConfig(config);
    if (insight && insight.trackEvent)
        insight.trackEvent({ name: "premium_dialog_open" });
    return Modal.PREMIUM;
});
export const { openModal, setModalWithData } = slice.actions;
export const useModalSelector = getSliceSelector(slice);
export const modalReducer = slice.reducer;
