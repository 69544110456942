import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPagination } from "types/IPagination/IPagination";

const initialState: IPagination = {
  skip: 0,
  take: 20,
  firstLetter: "",
  searchPattern: "",
  currentPage: 1,
};

const paginationSlice = createSlice({
  name: "pagination",
  initialState: initialState,
  reducers: {
    setPagination(state, action: PayloadAction<IPagination>) {
      Object.assign(state, action.payload);
    },
  },
});

export const { setPagination } = paginationSlice.actions;
export default paginationSlice.reducer;
