import { useMutation, useQuery } from "react-query";
import { IQueryKey } from "../../types/IQueryKey/IQueryKey";
import { queryOptions } from "../../utils/queryOptions";
import { createPlace, deletePlace, editPlace, getPlaces } from "../../apis/placesActions/placesActions";
import { useSelector } from "react-redux";
import { ReduxStoreState } from "../../redux/store";
import { IPlace } from "../../types/IPlace/IPlace";
import { queryClient } from "../../index";
import { useCallback } from "react";
import { handleExpiredToken, useMsTeamsSelector } from "front";

export const usePlaceCache = () => {
  const { appId } = useSelector((s: ReduxStoreState) => s.appConfiguration);
  const { tenantId } = useMsTeamsSelector("tenantId");

  const KEY = [IQueryKey.PLACE, appId, tenantId];

  const queryPlace = useQuery<IPlace[]>(KEY, getPlaces, {
    ...queryOptions,
    ...handleExpiredToken,
    enabled: !!appId,
    staleTime: Infinity,
  });

  const createPlaceMutation = useMutation(createPlace, {
    ...handleExpiredToken,
    onSuccess: (res: IPlace) => {
      queryClient.setQueryData(KEY, (oldData: IPlace[] | undefined) => {
        if (!oldData) return [];
        const newData = [...oldData, res];
        return newData;
      });
    },
  });

  const editPlaceMutation = useMutation(editPlace, {
    ...handleExpiredToken,
    onSuccess: (_: undefined, variable: { place: IPlace }) => {
      queryClient.setQueryData(KEY, (oldData: IPlace[] | undefined) => {
        if (!oldData) return [];
        const newData = oldData.map((item) => (item.id === variable.place.id ? { ...item, ...variable.place } : item));
        return newData;
      });
    },
  });

  const deletePlaceMutation = useMutation(deletePlace, {
    ...handleExpiredToken,
    onSuccess: (_: undefined, variable: { mapId: string; placeId: string }) => {
      queryClient.setQueryData(KEY, (oldData: IPlace[] | undefined) => {
        if (!oldData) return [];
        const newData = oldData.filter((item) => item.id !== variable.placeId);
        const firstDisabledElement = oldData.findIndex((od) => od.isDisabled);
        if (firstDisabledElement !== -1) oldData[firstDisabledElement]!.isDisabled = false;
        return newData;
      });
    },
  });

  const getPlaceById = useCallback(
    (id: string): IPlace | undefined => {
      const places: IPlace[] | undefined = queryClient.getQueryData(KEY);
      if (!places) return;
      return places.find((p) => p.id === id);
    },
    [appId]
  );

  return { queryPlace, createPlaceMutation, editPlaceMutation, deletePlaceMutation, getPlaceById };
};
