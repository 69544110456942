import axios from "axios";
const getConfiguration = () => new Promise(async (resolve, reject) => {
    try {
        const response = await axios.get("/api/settings");
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get client configuration");
    }
});
export const ConfigurationApi = {
    getConfiguration,
};
