import { useSelector } from "react-redux";
import { CompareModule } from "../modules/Compare.module";
export const getSliceSelector = (slice) => {
    return (...arg) => {
        if (arg.length === 1 && typeof arg[0] === "function")
            return useSelector(arg[0]);
        return useSelector((state) => {
            const object = {};
            arg.forEach(i => object[i] = state[slice.name][i]);
            return object;
        }, CompareModule.areObjectsEqual);
    };
};
