import { useQuery } from "react-query";
import { getConfig } from "../ConfigurationService/ConfigurationService";
export const useConfigurationCache = () => {
    const { data: config } = useQuery("app_confguration", getConfig, {
        staleTime: Infinity,
    });
    return {
        config,
    };
};
