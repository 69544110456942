import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, Carousel, Loader, Text, Flex, CloseIcon, Button } from "@fluentui/react-northstar";
import { useNewsLogic } from "./NewsDialog.logic";
import { Modal } from "../../../interfaces/Modal";
import "./NewsDialog.styles.scss";
import "react-quill/dist/quill.snow.css";
export const NewsDialog = (props) => {
    const logic = useNewsLogic(props);
    const header = () => (_jsxs(Flex, { fill: true, space: "between", vAlign: "center", hAlign: "center", children: [_jsx(Text, { content: logic.getCurrentNewsInfo()?.title ?? logic.t("WhatsNewsOn") }), _jsx(Button, { icon: _jsx(CloseIcon, {}), iconOnly: true, onClick: logic.closeDialog, flat: true, text: true })] }));
    const renderNews = () => {
        if (logic.isLoading)
            return _jsx(Loader, { styles: { height: "100%" } });
        if (!logic.allNews.length)
            return _jsx(Text, { align: "center", size: "medium", content: logic.t("NewsSoon") });
        return (_jsx(Carousel, { className: "apps-carousel ql-editor", id: "carroussel-news", "aria-roledescription": "carousel", "aria-label": "Portrait collection", navigation: {
                "aria-label": logic.getCurrentNewsInfo()?.title,
                items: logic.allNews.map((item) => ({
                    key: item.key,
                    "aria-controls": item.key,
                    "aria-label": item.key,
                })),
            }, items: logic.allNews, getItemPositionText: (index, size) => `${index + 1} of ${size}`, onActiveIndexChange: (e, { activeIndex }) => {
                logic.setTitleIndex(activeIndex);
            } }));
    };
    if (logic.isOpen !== Modal.NEWS)
        return;
    if (!logic.isNotInsideRestrictedRoutes)
        return;
    return (_jsx(Dialog, { styles: {
            width: logic.isOnMobile ? "95vw" : "50vw",
            maxWidth: logic.isOnMobile ? "95vw" : "50vw",
            height: logic.isOnMobile ? "95vh" : "calc(100vh - 100px)",
            maxHeight: logic.isOnMobile ? "95vh" : "65vh",
            display: "flex",
            flexDirection: "column",
        }, defaultOpen: true, closeOnOutsideClick: false, header: header(), content: {
            content: renderNews(),
            styles: {
                overflow: "scroll",
                display: "flex",
                height: "100%",
            },
        }, footer: {
            styles: {
                marginTop: "0px",
                marginBottom: "0px",
                display: "flex",
            },
        }, confirmButton: logic.getCurrentNewsInfo()?.ctaUrl ? (logic.getCurrentNewsInfo()?.ctaLabel ? logic.getCurrentNewsInfo().ctaLabel : logic.t("VisitLink")) : false, onConfirm: logic.openCTALink }));
};
