export var GlobalPermsString;
(function (GlobalPermsString) {
    GlobalPermsString["isEveryone"] = "isEveryone";
    GlobalPermsString["isCreator"] = "isCreator";
    GlobalPermsString["isCustom"] = "isCustom";
})(GlobalPermsString || (GlobalPermsString = {}));
export var Role;
(function (Role) {
    Role["creator"] = "Creator";
    Role["member"] = "Member";
})(Role || (Role = {}));
export var PermsTable;
(function (PermsTable) {
    PermsTable["Add"] = "add";
    PermsTable["Delete"] = "delete";
    PermsTable["Update"] = "update";
    PermsTable["AllowShareSynchro"] = "allowShareSynchro";
    PermsTable["AllowEditMeetingScheduler"] = "allowEditMeetingScheduler";
})(PermsTable || (PermsTable = {}));
