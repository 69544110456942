/**
 * Load google map script
 */
export const loadGoogleMapScript = (apiKey, libraries, version) => {
    const scripts = document.getElementsByTagName("script");
    let url = "";
    const baseUrl = "https://maps.googleapis.com/maps/api/js";
    url = `${baseUrl}?key=${apiKey}&libraries=${libraries.join(",")}`;
    if (version) {
        url = `${url}&v=${version}`;
    }
    if (!scripts)
        return;
    for (let i = 0; i < scripts.length; i++)
        if (scripts[i]?.src.indexOf(baseUrl) === 0) {
            return { script: scripts[i], ready: true };
        }
    const googleMapsScript = document.createElement("script");
    googleMapsScript.src = url;
    googleMapsScript.async = true;
    googleMapsScript.defer = true;
    window.document.body.appendChild(googleMapsScript);
    return { script: googleMapsScript, ready: false };
};
