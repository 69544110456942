export var IGoogleMapsLibraries;
(function (IGoogleMapsLibraries) {
    IGoogleMapsLibraries["MAPS"] = "maps";
    IGoogleMapsLibraries["PLACES"] = "places";
    IGoogleMapsLibraries["DIRECTIONS"] = "directions";
    IGoogleMapsLibraries["DISTANCE_MATRIX"] = "distanceMatrix";
    IGoogleMapsLibraries["GEOCODING"] = "geocoding";
    IGoogleMapsLibraries["GEOMETRY"] = "geometry";
    IGoogleMapsLibraries["GEOLOCATION"] = "geolocation";
    IGoogleMapsLibraries["MAPS_STATIC"] = "staticmap";
    IGoogleMapsLibraries["STREET_VIEW"] = "streetview";
    IGoogleMapsLibraries["ELEVATION"] = "elevation";
    IGoogleMapsLibraries["TIME_ZONE"] = "timezone";
})(IGoogleMapsLibraries || (IGoogleMapsLibraries = {}));
