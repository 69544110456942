import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { DEFAULT_PERMISSIONS, GLOBAL_PERMISSIONS, SHARECALENDAR, USERPERM } from "./Permissions.interfaces";
import { getSliceSelector } from "../../utils";
import { Role } from "../../../interfaces/Permissions";
import { getDefaultPermissionsByProjectId, getUserPermissionsByUserId, createUserPerm, getGlobalPermissionsByProjectId, getShareCalendarByProjectId, patchUserPerm } from "../../../apis/Permissions/PermissionsApi";
import { getAdminCenterData } from "../../../apis/AdminCenter/adminCenterApi";
const initialState = {
    loaded: false,
    defaultRight: {},
    userPerm: {},
    globalPermissions: { isEveryone: true, isCreator: false, isCustom: false },
    allSharedCalendar: []
};
export const fetchDefaultPerm = createAsyncThunk(DEFAULT_PERMISSIONS, async (projectId) => {
    try {
        const defaultRight = await getDefaultPermissionsByProjectId(projectId);
        return defaultRight;
    }
    catch {
        throw new Error("no defaultRight");
    }
});
export const fetchGlobalPerm = createAsyncThunk(GLOBAL_PERMISSIONS, async (projectId) => {
    try {
        const globalPerm = await getGlobalPermissionsByProjectId(projectId);
        return globalPerm;
    }
    catch {
        throw new Error("no globalPerms");
    }
});
export const fetchShareCalendar = createAsyncThunk(SHARECALENDAR, async (data) => {
    try {
        const calendarShare = await getShareCalendarByProjectId(data.projectId, data.userId);
        return calendarShare;
    }
    catch {
        throw new Error("no calendar sync");
    }
});
export const fetchUserPerm = createAsyncThunk(USERPERM, async (data, { getState }) => {
    const { projectId, userId, createdBy } = data;
    const defaultRight = getState().permissions.defaultRight;
    const teams = getState().msTeams;
    const conf = getState().configuration;
    try {
        const userPerm = await getUserPermissionsByUserId(projectId, userId);
        //besoin d'aller chercher les données du server de façon synchrones pour éviter une désynchronisation des droits
        const acdata = await getAdminCenterData(teams.tenantId, teams.userId, conf.data.adminCenterAppId, conf.data.adminCenterBaseUrl);
        if (!acdata.isUserPlatinum && !!!process.env.REACT_APP_ISPLATINUM) {
            userPerm.synchros = [];
            patchUserPerm(projectId, userPerm);
        }
        if (!userPerm.upn) {
            userPerm.upn = teams.userMail;
            patchUserPerm(projectId, userPerm);
        }
        return userPerm;
    }
    catch {
        const createPermissions = {
            id: teams.userId,
            name: teams.userName,
            upn: teams.userMail,
            perm: createdBy.toLowerCase() == teams.userMail.toLowerCase() || createdBy.toLowerCase() == teams.userName.toLowerCase() ? { add: true, update: true, delete: true, allowShareSynchro: true, allowEditMeetingScheduler: true } : defaultRight,
            synchros: [],
            role: createdBy.toLowerCase() == teams.userMail.toLowerCase() || createdBy.toLowerCase() == teams.userName.toLowerCase() ? Role.creator : Role.member,
            isPlatinum: undefined,
            isPremium: undefined
        };
        const userPerm = createUserPerm(projectId, createPermissions);
        return userPerm;
    }
});
const slice = createSlice({
    name: "permissions",
    initialState,
    reducers: {
        setDefaultRight: (state, action) => {
            state.defaultRight = action.payload;
        },
        setUserPerm: (state, action) => {
            state.userPerm = action.payload;
        },
        setGlobalPermissions: (state, action) => {
            state.globalPermissions = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchDefaultPerm.fulfilled, (state, action) => {
            if (!action.payload)
                throw new Error("No default right User");
            state.defaultRight = action.payload;
            state.loaded = true;
        });
        builder.addCase(fetchUserPerm.fulfilled, (state, action) => {
            if (!action.payload)
                throw new Error("No user Perm");
            if (!action.payload.synchros)
                action.payload.synchros = [];
            state.userPerm = action.payload;
        });
        builder.addCase(fetchGlobalPerm.fulfilled, (state, action) => {
            if (!action.payload)
                throw new Error("No user Perm");
            state.globalPermissions = action.payload;
        });
        builder.addCase(fetchShareCalendar.fulfilled, (state, action) => {
            if (!action.payload)
                throw new Error("No calendar share");
            state.allSharedCalendar = action.payload;
        });
    }
});
export const { setDefaultRight, setUserPerm, setGlobalPermissions } = slice.actions;
export const usePermissionsSelector = getSliceSelector(slice);
export const permissionsReducer = slice.reducer;
