import { HomePro } from "@witivio_teamspro/home-pro-sdk";
/**
 * Check if user can edit calendar and events
 * @param calendar calendar data
 * @param userUpn user upn
 */
export const userHasRightsToEdit = (userPermissions) => {
    return userPermissions.perm.add || userPermissions.perm.update || userPermissions.perm.delete;
};
export const getHomeToken = () => {
    return new Promise((resolve) => {
        if (window.location.pathname.startsWith("/widget")) {
            HomePro.authentication.getAuthToken({
                successCallback: (token) => resolve(token),
                failureCallback: (reason) => {
                    console.error(reason);
                    return;
                },
            });
        }
    });
};
