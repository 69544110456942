import { useCallback, useEffect, useState } from "react";
const initialState = {
    fullAddress: "",
    lat: 0,
    lng: 0,
};
export const useGoogleInput = (props) => {
    // State
    const [address, setAddress] = useState({ ...initialState });
    const [isValid, setIsValid] = useState(false);
    const [_, setRender] = useState([]);
    const forceValidity = (validity) => setIsValid(validity);
    const reset = useCallback(() => {
        forceValidity(false);
        setAddress({ ...initialState });
    }, []);
    const setValues = (values) => setAddress(values);
    useEffect(() => {
        if (!props.initialValues)
            return;
        setAddress(props.initialValues);
    }, []);
    const render = useCallback(() => setRender([]), []);
    useEffect(() => {
        if (!address.fullAddress)
            return setIsValid(false);
        setIsValid(true);
    }, [address]);
    return { isValid, values: address, forceValidity, reset, setValues, render };
};
