import { Button, Flex } from "@fluentui/react-northstar";
import { IArrowPaginationButton } from "./types/IArrowPaginationButton";
import { IPaginationProps } from "./types/IPaginationProps";
import { usePagination } from "./Pagination.logic";
import React, { memo } from "react";
import "./Pagination.scss";

const Pagination = memo((props: IPaginationProps) => {
  const logic = usePagination(props);

  if (!props.total || !logic.totalPages) return;

  return (
    <Flex>
      {logic.renderExtremityButton(IArrowPaginationButton.BEFORE)}
      {logic.totalPages > logic.MAXIMUM_PAGINATION_LENGTH && props.page >= logic.MAXIMUM_PAGINATION_LENGTH && (
        <>
          {logic.generateButton({ content: String(1), disabled: false, primary: props.page === 1, value: 1 })}{" "}
          <Button size="small" className="pagination-btn" iconOnly content={"..."} disabled />
        </>
      )}
      {logic.generatePaginationStructure()}

      {logic.totalPages > logic.MAXIMUM_PAGINATION_LENGTH &&
        logic.shouldDisplay(props.page + 1) &&
        logic.shouldDisplay(props.page + 2) && <Button className="pagination-btn" size="small" iconOnly content={"..."} disabled />}
      {logic.totalPages > logic.MAXIMUM_PAGINATION_LENGTH && (
        <>
          {logic.generateButton({
            content: String(logic.totalPages),
            disabled: false,
            primary: props.page === logic.totalPages,
            value: logic.totalPages,
          })}{" "}
        </>
      )}

      {logic.renderExtremityButton(IArrowPaginationButton.AFTER)}
    </Flex>
  );
});

export default Pagination;
