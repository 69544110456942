export class CustomError extends Error {
    constructor(message, stack) {
        super(message);
        Object.setPrototypeOf(this, new.target.prototype);
        if (stack) {
            this.stack = stack;
        }
        else {
            this.stack = new Error(message).stack;
        }
    }
}
