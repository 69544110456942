import ConnectAppDialog from "../../components/ConnectAppDialog/ConnectAppDialog";
import DeletePlaceDialog from "../../components/DeletePlaceDialog/DeletePlaceDialog";
import DeleteTripDialog from "../../components/DeleteTripDialog/DeleteTripDialog";
import ValidateCancelDialog from "../../components/ValidateCancelDialog/ValidateCancelDialog";
import { AboutDialog, UpgradeDialog, useConfigurationSelector, useModalSelector } from "front";
import React, { useMemo } from "react";
import { IDialogs } from "../../types/IDialogs/IDialogs";
import { appInsightInstance } from "services/TelemetryService/TelemetryService";

export const useDialogs = () => {
  const { isOpen } = useModalSelector("isOpen");
  const { data: config } = useConfigurationSelector("data");

  const dialogs = useMemo(
    () => [
      {
        show: isOpen === IDialogs.ABOUT,
        component: <AboutDialog />,
      },
      {
        show: isOpen === IDialogs.UPGRADE,
        component: <UpgradeDialog appId={config.adminCenterAppId} appInsight={appInsightInstance} />,
      },
      {
        show: isOpen === IDialogs.VALIDATE_CANCEL,
        component: <ValidateCancelDialog />,
      },
      {
        show: isOpen === IDialogs.DELETE_PLACE,
        component: <DeletePlaceDialog />,
      },
      {
        show: isOpen === IDialogs.DELETE_TRIP,
        component: <DeleteTripDialog />,
      },
      {
        show: isOpen === IDialogs.CONNECT_APP,
        component: <ConnectAppDialog />,
      },
    ],
    [isOpen]
  );

  return { isOpen, dialogs };
};
