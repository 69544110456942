import { AxiosConfig } from "../AxiosConfig/AxiosConfig";
export const getNotificationsConfigByCalendarId = (projectId) => new Promise(async (resolve, reject) => {
    try {
        const response = await AxiosConfig.instance.get(`/notifications/${projectId}`);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't get notifications");
    }
});
export const patchNotificiationConfig = (projectId, notifications) => new Promise(async (resolve, reject) => {
    try {
        const content_ = JSON.stringify(notifications);
        const response = await AxiosConfig.instance.patch(`/notifications/${projectId}`, content_);
        return resolve(response.data);
    }
    catch (error) {
        console.error(error);
        reject("Can't update user perm");
    }
});
