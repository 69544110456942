import { useQuery } from "react-query";
import { IQueryKey } from "../../types/IQueryKey/IQueryKey";
import { queryOptions } from "../../utils/queryOptions";
import { useConnectedAppSelector } from "../../redux/reducers/connectedApp.reducer";
import { IConnectedApp } from "../../types/IConnectedApp/IConnectedApp";
import { getAllTags } from "../../apis/calendarActions/calendarActions";

export const useTagsCache = () => {
  const { connectedApp, appIds } = useConnectedAppSelector("connectedApp", "appIds");

  const queryTags = useQuery<string[]>([IQueryKey.TAGS, appIds], getAllTags, {
    ...queryOptions,
    enabled: connectedApp === IConnectedApp.CALENDAR_PRO && appIds.length > 0,
    staleTime: 3_600_000, // 1h
    refetchOnMount: false,
  });

  return { queryTags };
};
