import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import { App } from "./App";
import { Provider } from "react-redux";
import store from "./redux/store";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import * as microsoftTeams from "@microsoft/teams-js";
// import { ReactQueryDevtools } from "react-query/devtools";
microsoftTeams.app.notifySuccess();

export const queryClient = new QueryClient();

export const jsxElement = (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <App />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>
);

ReactDOM.render(
  jsxElement,
  document.getElementById("root") || document.createElement("div") // for testing purposes
);
