import React from "react";
import { AddIcon, Button, CloseIcon, Flex, SaveIcon } from "@fluentui/react-northstar";
import { PremiumBanner } from "front";
import { useSideViewBottom } from "./SideViewBottom.logic";
import { IView } from "../../../types/IView/IView";

const SideViewBottom = (props: { view: IView; content: string; toggleMode: (mode: 0 | 1) => void; mode: number; onAction: () => void; isDisabled: boolean }) => {
  const logic = useSideViewBottom(props);

  return (
    <Flex fill vAlign="end" column gap="gap.small" style={{ marginBottom: logic.isOnMobile ? "2rem" : "0" }}>
      {!logic.canAdd() && <PremiumBanner message1={logic.t("PremiumMessage1")} messageClick={logic.t("ClickHere")} />}
      {props.mode === 0 ? (
        <Button content={props.content} primary icon={<AddIcon />} onClick={() => props.toggleMode(1)} fluid disabled={!logic.canAdd()} />
      ) : (
        <Flex style={{ width: "100%" }} gap="gap.small">
          <Button content={logic.t("Save")} primary icon={<SaveIcon />} onClick={props.onAction} fluid disabled={props.isDisabled} />
          <Button content={logic.t("ButtonCancelModalPlace")} icon={<CloseIcon />} onClick={logic.handleClose} fluid data-testid="sideViewBottom-cancel-btn" />
        </Flex>
      )}
    </Flex>
  );
};

export default SideViewBottom;
