import { AxiosConfig, getEndDate, tryCatch } from "front";
import { IContact } from "types/IContact/IContact";
import moment from "moment";
import { convertDisplayModeEnum } from "calendarShared";

export const isCalendarExistAction = async (id: string): Promise<void> => {
  const res = await AxiosConfig.instance.post(`/internal/calendar/${id}/check`);
  if (res.status !== 200) throw new Error("Wrong app ID");
};

const getAllCalendarsAction = async ({ queryKey }: { queryKey: any[] }): Promise<IContact[]> => {
  const { activeStartDate, searchTag, displayMode } = queryKey[2];
  const startDate = moment(activeStartDate).utc().format();
  const unitOfTime = convertDisplayModeEnum(displayMode);
  const endDate = moment(getEndDate(activeStartDate, unitOfTime)).utc().format();
  const query = `guids=${queryKey[1]}&start=${startDate}&end=${endDate}&tag=${searchTag}`;
  const res = await AxiosConfig.instance.get(`/internal/calendar/events?${query}`);
  return res.data;
};

const getAllTagsAction = async ({ queryKey }: { queryKey: any[] }): Promise<string[]> => {
  const res = await AxiosConfig.instance.get(`/internal/calendar?ids=${queryKey[1]}`);
  return res.data;
};

export const isCalendarExist = tryCatch(isCalendarExistAction);
export const getAllCalendars = tryCatch(getAllCalendarsAction);
export const getAllTags = tryCatch(getAllTagsAction);
