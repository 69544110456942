import { useQuery } from "react-query";
import { IQueryKey } from "../../types/IQueryKey/IQueryKey";
import { queryOptions } from "../../utils/queryOptions";
import { useConnectedAppSelector } from "../../redux/reducers/connectedApp.reducer";
import { IConnectedApp } from "../../types/IConnectedApp/IConnectedApp";
import { useSelector } from "react-redux";
import { ReduxStoreState } from "redux/store";
import { useEffect, useState } from "react";
import { createMarker } from "../../utils/createMarker";
import { IMarkerIcon } from "../../types/IMarkerIcon/IMarkerIcon";
import { IEvent, useNavBarSelector } from "calendarShared";
import { getAllCalendars } from "../../apis/calendarActions/calendarActions";
import moment from "moment";
import { queryClient } from "../../index";
import { useTranslate } from "front";
import { translations } from "../../translations";

export const useCalendarCache = (props?: { initializer: boolean }) => {
  const [markers, setMarkers] = useState<Map<string, google.maps.Marker>>(new Map());
  const t = useTranslate(translations);

  const { connectedApp, appIds } = useConnectedAppSelector("connectedApp", "appIds");
  const { googleMap, oms } = useSelector((s: ReduxStoreState) => s.googleMap);
  const { activeStartDate, displayMode, calendarTimeZone, searchTag } = useNavBarSelector("activeStartDate", "displayMode", "calendarTimeZone", "searchTag");

  useEffect(() => {
    if (connectedApp === IConnectedApp.CALENDAR_PRO) return;
    markers.forEach((m) => m.setVisible(false));
    setMarkers(new Map(markers));
  }, [connectedApp]);

  const queryCalendar = useQuery<IEvent[]>([IQueryKey.CALENDAR, appIds, { activeStartDate, displayMode, searchTag }], getAllCalendars, {
    ...queryOptions,
    enabled: connectedApp === IConnectedApp.CALENDAR_PRO && !!activeStartDate,
    staleTime: 3_600_000, // 1h
    refetchOnMount: false,
  });

  useEffect(() => {
    if (!queryCalendar.data) return;
    if (!props?.initializer || !googleMap || !oms) return;
    markers.forEach((m) => m.setMap(null));
    setMarkers(new Map());

    queryCalendar.data.forEach((c) => {
      const marker = createMarker(googleMap, oms, {
        id: c.title,
        title: c.title,
        description: `${t("LabelStartDate")} : ${getDate(c.startDate)} \n ${t("LabelEndDate")} : ${getDate(c.endDate)}`,
        address: c.address?.fullAddress ?? "",
        lat: c.address?.lat ?? 0,
        lng: c.address?.lng ?? 0,
        icon: IMarkerIcon.CALENDAR,
        color: c.color ?? "",
      });
      markers.set(c.title as string, marker);
    });
    setMarkers(new Map(markers));
    removeUnusedCache([IQueryKey.CALENDAR, appIds, { activeStartDate, displayMode, searchTag }]);
  }, [calendarTimeZone, queryCalendar.data]);

  const removeUnusedCache = (queryKey: any[]) => {
    const allCaches = queryClient.getQueryCache();
    allCaches.findAll().forEach((q) => {
      if (q.queryKey[0] !== queryKey[0]) return;
      if (JSON.stringify(queryKey) === JSON.stringify(q.queryKey)) return;
      const queryInfos: any = q.queryKey[2];
      if (!queryInfos.searchTag) return;
      queryClient.removeQueries(q.queryKey);
    });
  };

  const getDate = (date: string): string => {
    const dateInTimeZone = moment(date).tz(calendarTimeZone);
    return dateInTimeZone.format("dddd D MMMM YYYY HH:mm");
  };

  return { queryCalendar };
};
