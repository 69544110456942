import { useEffect, useState } from "react";
import { loadGoogleMapScript } from "../utils/google";
import { useConfigurationSelector } from "../redux/reducers/ConfigurationReducer/ConfigurationReducer";
export const useGoogleInitScript = (props) => {
    // State
    const [scriptIsLoading, setIsScriptLoading] = useState(false);
    const { data } = useConfigurationSelector("data");
    useEffect(() => {
        if (data.googleMapApiKey == "")
            return;
        const googleMapScript = loadGoogleMapScript(data.googleMapApiKey, props.libraries, props.version);
        if (googleMapScript.ready)
            setIsScriptLoading(true);
        if (!googleMapScript.script) {
            console.error("Can't load google maps script");
            return;
        }
        const onScriptLoaded = () => {
            setIsScriptLoading(true);
            googleMapScript.script.removeEventListener("load", onScriptLoaded);
        };
        googleMapScript.script.addEventListener("load", onScriptLoaded);
    }, [data.googleMapApiKey]);
    return { scriptIsLoading };
};
