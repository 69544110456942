import * as microsoftTeams from "@microsoft/teams-js";
export const IframeManager = (iframeConfig, isOnMobile, appInsightInstance, from, redirectUrl, redirectUrlForMapOrMobile, dataFunction) => {
    !!appInsightInstance && iframeConfig.trackingIds.open !== null && appInsightInstance?.trackEvent({
        name: iframeConfig.trackingIds.open,
    });
    if (from == "Map" || isOnMobile) {
        window.open(redirectUrlForMapOrMobile);
    }
    else {
        microsoftTeams.dialog.url.open({
            url: redirectUrl,
            size: {
                width: iframeConfig.width,
                height: iframeConfig.height,
            },
        }, (data) => {
            dataFunction(data);
        });
    }
};
