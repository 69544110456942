import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, CloseIcon, Dialog, Flex, FlexItem, Image, ParticipantAddIcon, QnaIcon, SendIcon, Text, TextArea } from "@fluentui/react-northstar";
import { useUpgradeLogic } from "./UpgradeDialog.logic";
import { TeamsProImages } from "../../../const/images";
import { translations } from "../../../translations";
import { useModalSelector } from "../../../redux/reducers/ModalReducer/ModalReducer";
import { useMsTeamsSelector } from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { Modal } from "../../../interfaces/Modal";
import { StoreMicrosoftRegular } from "@fluentui/react-icons";
import MarkdownView from "react-showdown";
import { useTranslate } from "../../../hook/useTranslate";
import { useDispatch } from "react-redux";
import { usePremiumSelector } from "../../../redux/reducers/PremiumReducer/PremiumReducer";
import { SearchDropdown } from "../../SearchDropdown/SearchDropdown";
import { useConfigurationSelector } from "../../../redux/reducers/ConfigurationReducer/ConfigurationReducer";
import ReactHtmlParser from "react-html-parser";
export const appSourceBaseUrl = "https://appsource.microsoft.com/";
export const UpgradeDialog = (props) => {
    const logic = useUpgradeLogic(props);
    const { isOpen } = useModalSelector("isOpen");
    const { isOnMobile, locale } = useMsTeamsSelector("isOnMobile", "locale");
    const { isAdmin, isPlatinum, isPremium, adminCenterData } = usePremiumSelector("isAdmin", "isPlatinum", "isPremium", "adminCenterData");
    const { data: config } = useConfigurationSelector("data");
    const dispatch = useDispatch();
    const t = useTranslate(translations);
    /**
     * Render content when premium activated
     */
    const renderPremiumActivatedContent = () => {
        return (_jsxs(Flex, { fill: true, column: true, gap: "gap.small", hAlign: "center", vAlign: "center", children: [_jsx(Image, { src: TeamsProImages.AdminCenter.PremiumImage, styles: { marginTop: "-20px" }, width: "350px" }), _jsx(Text, { align: "center", size: "large", content: t("ThanksPurchase") }), _jsx(Text, { align: "center", content: t("FeaturesNowAvailable") }), _jsx(Text, { align: "center", content: ReactHtmlParser(t("KnowAboutFeatures", { link: config.adminCenterBaseUrl + "/services/app/" + config.adminCenterAppId + "#product-plans-list" })) })] }));
    };
    /**
     * Render request access content
     */
    const renderRequestAccessContent = () => {
        return (_jsxs(Flex, { fill: true, column: true, gap: "gap.medium", hAlign: "center", children: [_jsx(Image, { width: "350px", styles: { marginTop: "-50px" }, src: TeamsProImages.AdminCenter.NoPremiumImage }), _jsx(Text, { size: "large", align: "center", content: t(!isPremium ? "NoPremiumAccess" : "NoPlatinumAccess") }), _jsxs(Flex, { fill: true, gap: "gap.large", children: [isAdmin ? (_jsx(FlexItem, { size: "medium", styles: { display: adminCenterData.subscriptionOwner === logic.user.AadId ? "flex" : "none" }, children: _jsx(Text, { align: "justify", content: t(!isPremium ? "UpgradeTeamMessage" : "UpgradePlatinumTeamMessage") }) })) : (_jsx(FlexItem, { size: "size.half", styles: { display: !isPremium ? "flex" : "none" }, children: _jsx(Text, { align: "justify", content: t(!isPremium ? "UpgradeTeamMessage" : "UpgradePlatinumTeamMessage") }) })), isAdmin || (adminCenterData.tenantSuperAdmins !== null && !adminCenterData.tenantSuperAdmins.length) ? null : (_jsx(FlexItem, { size: "size.half", styles: { display: !isPremium ? "flex" : "none" }, children: _jsx(Text, { align: "justify", content: t(!isPremium ? "TeamHasLicences" : "TeamHasPlatinumLicences") }) }))] })] }));
    };
    const renderNeedToBuyLicence = () => {
        return (_jsxs(Flex, { fill: true, column: true, gap: "gap.medium", hAlign: "center", children: [_jsx(Image, { width: "350px", styles: { marginTop: "-50px" }, src: TeamsProImages.AdminCenter.NoPremiumImage }), _jsx(Text, { size: "large", align: "center", content: t("NeedToBuyLicences") }), _jsx(Flex, { fill: true, gap: "gap.large", children: _jsx(FlexItem, { size: "medium", children: _jsx(Text, { align: "justify", content: t("HaveLicencesButNotEnough") }) }) })] }));
    };
    /**
     * Render request sent content
     */
    const renderRequestSentContent = () => {
        return t("AdministratorNotified");
    };
    /**
     * Render footer
     */
    const renderFooter = () => {
        if (logic.state.showPremiumActivatedContent) {
            return (_jsx(Flex, { fill: true, gap: "gap.small", hAlign: "end", children: _jsx(Button, { primary: true, content: t("Go"), onClick: logic.closeDialog }) }));
        }
        return logic.state.isRequestSent ? (_jsx(Flex, { fill: true, gap: "gap.small", hAlign: "end", children: logic.state.forceUpgradePremium ? null : _jsx(Button, { primary: true, content: t("Close"), onClick: logic.closeDialog }) })) : !isPremium && !isPlatinum && isAdmin && adminCenterData.isAppLicenceAvailable && !!logic.licencePlanId ? (_jsx(Flex, { fill: true, gap: "gap.small", hAlign: "center", children: _jsx(Button, { icon: _jsx(ParticipantAddIcon, { outline: true }), primary: true, content: t("ManageMyLicence"), onClick: logic.showPaymentPage }) })) : !isAdmin ? (_jsxs(Flex, { fill: true, column: true, gap: "gap.medium", children: [_jsxs(Flex, { fill: true, gap: "gap.large", hAlign: "center", children: [_jsx(Button, { content: "🚀 " + t("UpgradePremium"), onClick: logic.showPaymentPageForSuperAdmin, styles: { display: !isPremium ? "flex" : "none" } }), adminCenterData.tenantSuperAdmins !== null ? (_jsx(Button, { primary: true, icon: _jsx(QnaIcon, { outline: true }), content: t("RequestAccess"), onClick: () => logic.setState((prev) => ({ ...prev, showRequestAccessDialog: true })) })) : null] }), logic.state.isPendingAccessRequest && (_jsx(Alert, { styles: {
                        textAlign: "center",
                        paddingTop: "10px !important",
                        paddingBottom: "10px !important",
                    }, content: "☝️ " + t("RequestAccessInApprobation"), warning: true }))] })) : adminCenterData.subscriptionOwner === logic.user.AadId && !!adminCenterData.subscriptionLicenceId && !adminCenterData.isAppLicenceAvailable && !isPremium ? (_jsx(Flex, { fill: true, gap: "gap.small", hAlign: "end", children: _jsx(Button, { disabled: logic.state.isPaymentOpen, loading: logic.state.isPaymentOpen, primary: true, content: t("BuyMoreLicence"), onClick: logic.showPaymentPageForSuperAdmin }) })) : adminCenterData.subscriptionOwner === logic.user.AadId ? (_jsx(Flex, { fill: true, gap: "gap.small", hAlign: "end", children: _jsx(Button, { disabled: logic.state.isPaymentOpen, loading: logic.state.isPaymentOpen, primary: true, content: "🚀 " + t(!isPremium ? "UpgradePremium" : "UpgradePlatinum"), onClick: logic.showPaymentPageForSuperAdmin }) })) : (_jsx(Flex, { fill: true, gap: "gap.small", hAlign: "center", children: _jsx(Button, { primary: true, icon: _jsx(QnaIcon, { outline: true }), content: t("RequestAccess"), onClick: () => logic.setState((prev) => ({ ...prev, showRequestAccessDialog: true })) }) }));
    };
    /**
     * Render content if user is an admin
     */
    const renderUserAdminContent = () => {
        return (_jsxs(Flex, { fill: true, column: true, gap: "gap.small", hAlign: "center", vAlign: "center", children: [_jsx(Image, { src: TeamsProImages.AdminCenter.UserAdminImage, styles: { marginTop: "-40px" }, width: "350px" }), _jsx(Text, { align: "center", size: "large", content: t("YouAreAdmin") }), isPremium ? _jsx(Text, { align: "center", content: t("YouCanManageLicencesToPlatinum") }) : _jsx(Text, { align: "center", content: t("YouCanManageLicences") })] }));
    };
    /**
     * Render request access dialog
     */
    const renderRequestAccessDialog = () => {
        let displayedSuperAdmins = adminCenterData.tenantSuperAdmins?.map((tsa) => {
            return { header: tsa.email, value: tsa.aadId, disabled: false };
        });
        return (_jsx(Dialog, { open: logic.state.showRequestAccessDialog, header: t("RequestAccess"), content: _jsxs(Flex, { fill: true, column: true, gap: "gap.small", children: [_jsx(SearchDropdown, { FUI: {
                            disabled: logic.state.sendingAccessRequest,
                            fluid: true,
                            inverted: false,
                            items: displayedSuperAdmins,
                            onChange: logic.updateSuperAdminToRequest,
                            noResultsMessage: t("SuperAdmin:NoResult"),
                        }, options: {
                            defaultValue: displayedSuperAdmins[0].header,
                            resetValue: true,
                            fill: true
                        } }), _jsx(Text, { content: t("RequestAccessMessage") }), _jsx(TextArea, { styles: { height: "100px" }, disabled: logic.state.sendingAccessRequest, value: logic.state.requestAccessMessage, onChange: (e, data) => (data?.value).length <= 200 && logic.setState((prev) => ({ ...prev, requestAccessMessage: data?.value })) }), _jsx(Flex, { hAlign: "end", children: _jsx(Text, { styles: { color: "gray" }, content: logic.state.requestAccessMessage.length + "/200" }) })] }), confirmButton: {
                icon: _jsx(SendIcon, { outline: true }),
                content: t("Send"),
                disabled: !logic.state.requestAccessMessage || logic.state.sendingAccessRequest,
                onClick: logic.sendAccessRequest,
                loading: logic.state.sendingAccessRequest,
            }, headerAction: {
                icon: _jsx(CloseIcon, { outline: true }),
                title: "Close",
                onClick: () => logic.setState((prev) => ({ ...prev, showRequestAccessDialog: false })),
            } }));
    };
    if (!logic.state.loaded)
        return null;
    const renderMobile = () => {
        let content = undefined;
        if (logic.state.isRequestSent)
            content = renderRequestSentContent();
        else if (logic.state.showPremiumActivatedContent)
            content = renderPremiumActivatedContent();
        else if (!isPremium && !isPlatinum && isAdmin && adminCenterData.isAppLicenceAvailable && !!logic.licencePlanId)
            content = renderUserAdminContent();
        else if (!isPremium && !!adminCenterData.subscriptionLicenceId && !adminCenterData.isAppLicenceAvailable && adminCenterData.subscriptionOwner === logic.user.AadId)
            content = renderNeedToBuyLicence();
        else
            content = renderRequestAccessContent();
        if (isOnMobile) {
            return (_jsx(_Fragment, { children: _jsx(Dialog, { open: isOpen == Modal.UPGRADE, header: logic.state.appName, content: t("PreventUnlockPremiumFeaturesOnMobile"), footer: _jsx(Flex, { vAlign: "end", hAlign: "end", children: _jsx(Button, { content: t("GoToAdminCenter"), primary: true, onClick: () => window.open("https://admin.teams-pro.com/licence/signup?redirectUrl=%2F&fromTeams=true&userName=") }) }), headerAction: logic.state.forceUpgradePremium
                        ? undefined
                        : {
                            icon: _jsx(CloseIcon, { outline: true }),
                            onClick: logic.closeDialog,
                        } }) }));
        }
        else {
            return (_jsxs(_Fragment, { children: [_jsx(Dialog, { open: isOpen == Modal.UPGRADE, header: logic.state.appName, 
                        //content={content ?? logic.state.upgradePremiumMessage}
                        content: !logic.state.appSourceOpened && content ? (content) : (_jsxs(_Fragment, { children: [logic.state.appSourceOpened ? (_jsx(Flex, { hAlign: "center", children: _jsx(StoreMicrosoftRegular, { fontSize: 80 }) })) : null, _jsx(MarkdownView, { markdown: logic.state.appSourceOpened
                                        ? t("RedirectToAppSource", { link: appSourceBaseUrl + locale + "/product/web-apps/" + logic.state.appSourceOfferId })
                                        : logic.state.upgradePremiumMessage.toString() })] })), footer: renderFooter(), headerAction: logic.state.forceUpgradePremium
                            ? undefined
                            : !logic.state.isRequestSent &&
                                !!content && {
                                icon: _jsx(CloseIcon, { outline: true }),
                                onClick: logic.closeDialog,
                            } }), renderRequestAccessDialog()] }));
        }
    };
    return renderMobile();
};
