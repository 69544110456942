import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, Button, CloseIcon, Dialog, Flex, FlexItem, Image, ParticipantAddIcon, QnaIcon, SendIcon, Text, TextArea } from "@fluentui/react-northstar";
import { useLogic } from "./UpgradePremium.logic";
import { TeamsProImages } from "../../const/images";
import { translations } from "../../translations";
import { useModalSelector } from "../../redux/reducers/ModalReducer/ModalReducer";
import { useMsTeamsSelector } from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { Modal } from "../../interfaces/Modal";
import { StoreMicrosoftRegular } from "@fluentui/react-icons";
import MarkdownView from "react-showdown";
import { useTranslate } from "../../hook/useTranslate";
import { appSourceBaseUrl } from "../dialogs/Upgrade/UpgradeDialog";
import { usePremiumSelector } from "../../redux/reducers/PremiumReducer/PremiumReducer";
export const UpgradePremiumDialog = (props) => {
    const logic = useLogic(props);
    const { isOpen } = useModalSelector("isOpen");
    const { isOnMobile, locale } = useMsTeamsSelector("isOnMobile", "locale");
    const { isPremium, isPlatinum } = usePremiumSelector("isPremium", "isPlatinum");
    const t = useTranslate(translations);
    /**
     * Render content when premium activated
     */
    const renderPremiumActivatedContent = () => {
        return (_jsxs(Flex, { fill: true, column: true, gap: "gap.small", hAlign: "center", vAlign: "center", children: [_jsx(Image, { src: TeamsProImages.AdminCenter.PremiumImage, styles: { marginTop: "-20px" }, width: "350px" }), _jsx(Text, { align: "center", size: "large", content: t("ThanksPurchase") }), _jsx(Text, { align: "center", content: t("PremiumNowAvailable") })] }));
    };
    /**
     * Render content if user is an admin
     */
    const renderUserAdminContent = () => {
        return (_jsxs(Flex, { fill: true, column: true, gap: "gap.small", hAlign: "center", vAlign: "center", children: [_jsx(Image, { src: TeamsProImages.AdminCenter.UserAdminImage, styles: { marginTop: "-40px" }, width: "350px" }), _jsx(Text, { align: "center", size: "large", content: t("YouAreAdmin") }), _jsx(Text, { align: "center", content: t("YouCanManageLicences") })] }));
    };
    /**
     * Render request access content
     */
    const renderRequestAccessContent = () => {
        return (_jsxs(Flex, { fill: true, column: true, gap: "gap.medium", hAlign: "center", children: [_jsx(Image, { width: "350px", styles: { marginTop: "-50px" }, src: TeamsProImages.AdminCenter.NoPremiumImage }), _jsx(Text, { size: "large", align: "center", content: t("NoPremiumAccess") }), _jsxs(Flex, { fill: true, gap: "gap.large", children: [_jsx(FlexItem, { size: "size.half", children: _jsx(Text, { align: "justify", content: t("UpgradeTeamMessage") }) }), _jsx(FlexItem, { size: "size.half", children: _jsx(Text, { align: "justify", content: t("TeamHasLicences") }) })] })] }));
    };
    /**
     * Render request sent content
     */
    const renderRequestSentContent = () => {
        return t("AdministratorNotified");
    };
    /**
     * Render footer
     */
    const renderFooter = () => {
        return logic.state.isRequestSent ? (_jsx(Flex, { fill: true, gap: "gap.small", hAlign: "end", children: logic.state.forceUpgradePremium ? null : _jsx(Button, { primary: true, content: t("Close"), onClick: logic.closeDialog }) })) : logic.state.showPremiumActivatedContent || (!isPremium && !isPlatinum && logic.state.isLicencesAvailable && logic.state.isUserAdmin && !!logic.licencePlanId) ? (_jsx(Flex, { fill: true, gap: "gap.small", hAlign: "center", children: _jsx(Button, { icon: _jsx(ParticipantAddIcon, { outline: true }), primary: true, content: t("ManageLicences"), onClick: logic.assignLicences }) })) : logic.state.isLicencesAvailable ? (_jsxs(Flex, { fill: true, column: true, gap: "gap.medium", children: [_jsxs(Flex, { fill: true, gap: "gap.large", children: [_jsx(Button, { fluid: true, content: "🚀 " + t("UpgradePremium"), onClick: logic.showPaymentPage }), _jsx(Button, { fluid: true, primary: true, icon: _jsx(QnaIcon, { outline: true }), content: t("RequestAccess"), onClick: () => logic.setState((prev) => ({ ...prev, showRequestAccessDialog: true })) })] }), logic.state.isPendingAccessRequest && (_jsx(Alert, { styles: {
                        textAlign: "center",
                        paddingTop: "10px !important",
                        paddingBottom: "10px !important",
                    }, content: "☝️ " + t("RequestAccessInApprobation"), warning: true }))] })) : (_jsxs(Flex, { fill: true, gap: "gap.small", hAlign: "end", children: [logic.state.forceUpgradePremium ? null : _jsx(Button, { icon: _jsx(CloseIcon, { outline: true }), disabled: logic.state.isPaymentOpen, content: t("Cancel"), onClick: logic.closeDialog }), _jsx(Button, { disabled: logic.state.isPaymentOpen, loading: logic.state.isPaymentOpen, primary: true, content: "🚀 " + t("UpgradePremium"), onClick: logic.showPaymentPage })] }));
    };
    /**
     * Render request access dialog
     */
    const renderRequestAccessDialog = () => {
        return (_jsx(Dialog, { open: logic.state.showRequestAccessDialog, header: t("RequestAccess"), content: _jsxs(Flex, { fill: true, column: true, gap: "gap.small", children: [_jsx(Text, { content: t("RequestAccessMessage") }), _jsx(TextArea, { styles: { height: "100px" }, disabled: logic.state.sendingAccessRequest, value: logic.state.requestAccessMessage, onChange: (e, data) => (data?.value).length <= 200 && logic.setState((prev) => ({ ...prev, requestAccessMessage: data?.value })) }), _jsx(Flex, { hAlign: "end", children: _jsx(Text, { styles: { color: "gray" }, content: logic.state.requestAccessMessage.length + "/200" }) })] }), confirmButton: {
                icon: _jsx(SendIcon, { outline: true }),
                content: t("Send"),
                disabled: !logic.state.requestAccessMessage || logic.state.sendingAccessRequest,
                onClick: logic.sendAccessRequest,
                loading: logic.state.sendingAccessRequest,
            }, headerAction: {
                icon: _jsx(CloseIcon, { outline: true }),
                title: "Close",
                onClick: () => logic.setState({ showRequestAccessDialog: false }),
            } }));
    };
    if (!logic.state.loaded)
        return null;
    const renderMobile = () => {
        let content = undefined;
        if (logic.state.isRequestSent)
            content = renderRequestSentContent();
        else if (logic.state.isLicencesAvailable && logic.state.isUserAdmin && !!logic.licencePlanId)
            content = renderUserAdminContent();
        else if (logic.state.showPremiumActivatedContent)
            content = renderPremiumActivatedContent();
        else if (logic.state.isLicencesAvailable)
            content = renderRequestAccessContent();
        if (isOnMobile) {
            return (_jsx(_Fragment, { children: _jsx(Dialog, { open: isOpen == Modal.PREMIUM, header: logic.state.appName, content: t("PreventUnlockPremiumFeaturesOnMobile"), footer: _jsx(Flex, { vAlign: "end", hAlign: "end", children: _jsx(Button, { content: t("GoToAdminCenter"), primary: true, onClick: () => window.open("https://admin.teams-pro.com/licence/signup?redirectUrl=%2F&fromTeams=true&userName=") }) }), headerAction: logic.state.forceUpgradePremium
                        ? undefined
                        : {
                            icon: _jsx(CloseIcon, { outline: true }),
                            onClick: logic.closeDialog,
                        } }) }));
        }
        else {
            return (_jsxs(_Fragment, { children: [_jsx(Dialog, { open: isOpen == Modal.PREMIUM, header: logic.state.appName, 
                        //content={content ?? logic.state.upgradePremiumMessage}
                        content: !logic.state.appSourceOpened && content ? (content) : (_jsxs(_Fragment, { children: [logic.state.appSourceOpened ? (_jsx(Flex, { hAlign: "center", children: _jsx(StoreMicrosoftRegular, { fontSize: 80 }) })) : null, _jsx(MarkdownView, { markdown: logic.state.appSourceOpened
                                        ? t("RedirectToAppSource", { link: appSourceBaseUrl + locale + "/product/web-apps/" + logic.state.appSourceOfferId })
                                        : logic.state.upgradePremiumMessage.toString() })] })), footer: renderFooter(), headerAction: logic.state.forceUpgradePremium
                            ? undefined
                            : !logic.state.isRequestSent &&
                                !!content && {
                                icon: _jsx(CloseIcon, { outline: true }),
                                onClick: logic.closeDialog,
                            } }), renderRequestAccessDialog()] }));
        }
    };
    return renderMobile();
};
