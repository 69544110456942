export const CrownIcon = (props: { permissions: "premium" | "platinium" }) => {
    const colors = {
      primary: props.permissions === "premium" ? "#FFB02E" : "#00A6ED",
      primaryDark: props.permissions === "premium" ? "#E19747" : "#0381B7",
      secondary: props.permissions === "premium" ? "#00A6ED" : "#FFB02E",
      tertiary: props.permissions === "premium" ? "#F8312F" : "#F8312F",
    };
  
    return (
      <svg width="17" height="14" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.12534 7.09929C2.12534 7.18857 2.10776 7.27698 2.07359 7.35946C2.03942 7.44195 1.98935 7.51689 1.92622 7.58002C1.86308 7.64316 1.78814 7.69323 1.70565 7.7274C1.62317 7.76157 1.53476 7.77915 1.44548 7.77915C1.3562 7.77915 1.2678 7.76157 1.18531 7.7274C1.10283 7.69323 1.02788 7.64316 0.964751 7.58002C0.90162 7.51689 0.851542 7.44195 0.817376 7.35946C0.78321 7.27698 0.765625 7.18857 0.765625 7.09929C0.765625 6.91898 0.837253 6.74606 0.964751 6.61856C1.09225 6.49106 1.26517 6.41943 1.44548 6.41943C1.62579 6.41943 1.79872 6.49106 1.92622 6.61856C2.05371 6.74606 2.12534 6.91898 2.12534 7.09929ZM11.2733 7.09929C11.2733 7.2796 11.2017 7.45253 11.0742 7.58002C10.9467 7.70752 10.7738 7.77915 10.5934 7.77915C10.4131 7.77915 10.2402 7.70752 10.1127 7.58002C9.98522 7.45253 9.91359 7.2796 9.91359 7.09929C9.91359 6.91898 9.98522 6.74606 10.1127 6.61856C10.2402 6.49106 10.4131 6.41943 10.5934 6.41943C10.7738 6.41943 10.9467 6.49106 11.0742 6.61856C11.2017 6.74606 11.2733 6.91898 11.2733 7.09929Z"
          fill={colors.secondary}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.54932 2.69712L4.91764 4.53446L2.08203 4.86148L2.99425 2.75736C2.7748 2.67131 2.61989 2.45616 2.61989 2.20659C2.61989 1.87957 2.88667 1.61279 3.21369 1.61279C3.34025 1.61346 3.46329 1.65446 3.56495 1.72983C3.66662 1.8052 3.74161 1.91102 3.77903 2.03192C3.81645 2.15281 3.81435 2.28249 3.77304 2.40211C3.73173 2.52174 3.65337 2.62508 3.54932 2.69712ZM9.03982 2.75736L9.95203 4.86148L7.11642 4.53446L8.48475 2.69712C8.32984 2.58955 8.22657 2.40883 8.22657 2.20659C8.22657 1.87957 8.49335 1.61279 8.82037 1.61279C9.14739 1.61279 9.41417 1.87957 9.41417 2.20659C9.41417 2.45616 9.25927 2.67131 9.03982 2.75736Z"
          fill={colors.primaryDark}
        />
        <path
          d="M12.0395 2.61988C12.0395 2.29286 11.7727 2.02608 11.4457 2.02608C11.1187 2.02608 10.8519 2.29286 10.8519 2.61988C10.8519 2.74036 10.8906 2.85654 10.9509 2.9469C10.8562 2.9469 10.7616 2.96841 10.6712 3.02865L8.98015 4.12159C8.7564 4.26358 8.4638 4.21195 8.3046 3.9968L6.42423 1.44518C6.41083 1.42465 6.39493 1.40586 6.3769 1.38925C6.52083 1.31 6.63433 1.18515 6.69951 1.03432C6.7647 0.883502 6.77787 0.715284 6.73697 0.556152C6.69606 0.397021 6.60339 0.256013 6.47354 0.155329C6.3437 0.0546438 6.18406 0 6.01976 0C5.85545 0 5.69581 0.0546438 5.56597 0.155329C5.43613 0.256013 5.34346 0.397021 5.30255 0.556152C5.26164 0.715284 5.27482 0.883502 5.34 1.03432C5.40519 1.18515 5.51868 1.31 5.66262 1.38925C5.6454 1.40646 5.62819 1.42367 5.61528 1.44518L3.73492 3.9968C3.58001 4.21195 3.28311 4.26358 3.05936 4.12159L1.36832 3.02865C1.27796 2.96841 1.1833 2.9469 1.08863 2.9469C1.15318 2.85223 1.1876 2.74036 1.1876 2.61988C1.1876 2.29286 0.920821 2.02608 0.5938 2.02608C0.26678 2.02608 0 2.29286 0 2.61988C0 2.9469 0.26678 3.21368 0.5938 3.21368C0.615315 3.21368 0.636829 3.21368 0.654041 3.20937C0.598103 3.30834 0.576589 3.42452 0.602406 3.5493L1.72116 9.13016C1.80291 9.53033 2.15575 9.81863 2.56453 9.81863H9.47499C9.88376 9.81863 10.2366 9.53033 10.3184 9.13016L11.4371 3.5493C11.4629 3.42452 11.4371 3.30404 11.3855 3.20937C11.407 3.20937 11.4285 3.21368 11.4457 3.21368C11.7727 3.21368 12.0395 2.9469 12.0395 2.61988Z"
          fill={colors.primary}
        />
        <path
          d="M6.01908 8.17489C6.30438 8.17489 6.578 8.06155 6.77974 7.85981C6.98147 7.65808 7.09481 7.38446 7.09481 7.09916C7.09481 6.81386 6.98147 6.54025 6.77974 6.33851C6.578 6.13677 6.30438 6.02344 6.01908 6.02344C5.73378 6.02344 5.46017 6.13677 5.25843 6.33851C5.05669 6.54025 4.94336 6.81386 4.94336 7.09916C4.94336 7.38446 5.05669 7.65808 5.25843 7.85981C5.46017 8.06155 5.73378 8.17489 6.01908 8.17489Z"
          fill={colors.secondary}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M3.8729 7.09905C3.8729 7.27023 3.8049 7.4344 3.68386 7.55544C3.56282 7.67648 3.39865 7.74448 3.22747 7.74448C3.05629 7.74448 2.89212 7.67648 2.77107 7.55544C2.65003 7.4344 2.58203 7.27023 2.58203 7.09905C2.58203 6.92787 2.65003 6.7637 2.77107 6.64266C2.89212 6.52161 3.05629 6.45361 3.22747 6.45361C3.39865 6.45361 3.56282 6.52161 3.68386 6.64266C3.8049 6.7637 3.8729 6.92787 3.8729 7.09905ZM9.46667 7.09905C9.46667 7.27023 9.39867 7.4344 9.27763 7.55544C9.15658 7.67648 8.99242 7.74448 8.82124 7.74448C8.65006 7.74448 8.48589 7.67648 8.36484 7.55544C8.2438 7.4344 8.1758 7.27023 8.1758 7.09905C8.1758 6.92787 8.2438 6.7637 8.36484 6.64266C8.48589 6.52161 8.65006 6.45361 8.82124 6.45361C8.99242 6.45361 9.15658 6.52161 9.27763 6.64266C9.39867 6.7637 9.46667 6.92787 9.46667 7.09905Z"
          fill={colors.tertiary}
        />
      </svg>
    );
  };
  