export var IframeSize;
(function (IframeSize) {
    IframeSize[IframeSize["LargerSize"] = 1500] = "LargerSize";
    IframeSize[IframeSize["LargeSize"] = 1300] = "LargeSize";
    IframeSize[IframeSize["MediumSize"] = 1000] = "MediumSize";
    IframeSize[IframeSize["LowSize"] = 700] = "LowSize";
    IframeSize[IframeSize["LowerSize"] = 500] = "LowerSize";
})(IframeSize || (IframeSize = {}));
export var IframeTracking;
(function (IframeTracking) {
    IframeTracking["PremiumManageAssign"] = "premium_manage_assign";
    IframeTracking["PremiumManageCancel"] = "premium_manage_cancel";
    IframeTracking["PremiumManageOpen"] = "premium_manage_open";
    IframeTracking["LicenceAssign"] = "assign_licence";
    IframeTracking["ManageLicenceCancel"] = "manage_licence_cancel";
    IframeTracking["PremiumPricingPaid"] = "premium_pricing_paid";
    IframeTracking["PremiumPricingCancel"] = "premium_pricing_cancel";
    IframeTracking["PremiumPricingOpen"] = "premium_pricing_open";
    IframeTracking["MoreAppsRedirected"] = "more_apps_redirected";
    IframeTracking["MoreAppsOpen"] = "more_apps_open";
    IframeTracking["MoreAppsCancel"] = "more_apps_cancel";
})(IframeTracking || (IframeTracking = {}));
