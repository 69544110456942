import { useQuery } from "react-query";
import { IContact } from "../../types/IContact/IContact";
import { IQueryKey } from "../../types/IQueryKey/IQueryKey";
import { queryOptions } from "../../utils/queryOptions";
import { useConnectedAppSelector } from "../../redux/reducers/connectedApp.reducer";
import { IConnectedApp } from "../../types/IConnectedApp/IConnectedApp";
import { useSelector } from "react-redux";
import { ReduxStoreState } from "../../redux/store";
import { getAllContacts } from "../../apis/contactsActions/contactsActions";
import { useEffect, useState } from "react";
import { createMarker } from "../../utils/createMarker";
import { IMarkerIcon } from "../../types/IMarkerIcon/IMarkerIcon";
import { removeOldCache, useMsTeamsSelector, useTranslate } from "front";
import { queryClient } from "../../index";
import { translations } from "../../translations";

export const useContactCache = (props?: { initializer: boolean }) => {
  const [markers, setMarkers] = useState<Map<string, google.maps.Marker>>(new Map());
  const t = useTranslate(translations);
  const { tenantId } = useMsTeamsSelector("tenantId");
  const { connectedApp, appIds } = useConnectedAppSelector("connectedApp", "appIds");
  const { googleMap, oms } = useSelector((s: ReduxStoreState) => s.googleMap);
  const pagination = useSelector((s: ReduxStoreState) => s.pagination);

  const KEY = [IQueryKey.CONTACT, appIds, pagination, tenantId];

  useEffect(() => {
    if (connectedApp === IConnectedApp.CONTACTS_PRO) return;
    markers.forEach((m) => m.setVisible(false));
    setMarkers(new Map(markers));
  }, [connectedApp]);

  const queryContacts = useQuery<{ total: number; contacts: IContact[] }>(KEY, getAllContacts, {
    ...queryOptions,
    enabled: connectedApp === IConnectedApp.CONTACTS_PRO,
    staleTime: 3_600_000, // 1h
    refetchOnMount: false,
    onSuccess: (res) => {
      if (!props?.initializer || !googleMap || !oms) return;
      markers.forEach((m) => m.setVisible(false));
      res.contacts.forEach((c) => {
        const isHere = markers.get(c.name);
        if (isHere) {
          isHere.setVisible(true);
          return;
        }
        const marker = createMarker(googleMap, oms, {
          id: c.name,
          title: c.name,
          description: c.internet?.email ? `${t("Email")}: ${c.internet?.email}` : "",
          address: c.addresses[0]!.fullAddress,
          lat: c.addresses[0]!.lat ?? 0,
          lng: c.addresses[0]!.lng ?? 0,
          icon: IMarkerIcon.CONTACT,
        });
        markers.set(c.name as string, marker);
      });
      setMarkers(new Map(markers));
      removeOldCache(queryClient, KEY);
    },
  });

  return { queryContacts };
};
