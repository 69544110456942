import { setModalWithData, uniqueString, useTranslate } from "front";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IAppDispatch } from "redux/store";
import { translations } from "../../translations";
import { DropdownProps } from "@fluentui/react-northstar";
import { IConnectedApp } from "../../types/IConnectedApp/IConnectedApp";
import { SubmitHandler, useForm } from "react-hook-form";
import { isContactExist } from "../../apis/contactsActions/contactsActions";
import { CALENDAR_GET_ID, CONTACTS_GET_ID } from "../../const/links";
import { genericObject } from "../../utils/genericObject";
import { setAppIds, setConnectedApp, setConnectedAppsAndIds, useConnectedAppSelector } from "../../redux/reducers/connectedApp.reducer";
import { useMapCache } from "../../hooks/useMapCache/useMapCache";
import { IMap } from "../../types/IMap/IMap";
import { convertAppEnumToNumber } from "../../utils/convertAppEnumToNumber";
import { isCalendarExist } from "../../apis/calendarActions/calendarActions";

export const AVAILABLE_APPS_TO_CONNECT = [IConnectedApp.CALENDAR_PRO, IConnectedApp.CONTACTS_PRO];
const guidPattern = /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/;
export const CONNECTED_APPS = genericObject("app", 3);

export const useConnectAppDialog = () => {
  const t = useTranslate(translations);
  const dispatchCtx = useDispatch<IAppDispatch>();

  const { queryMap, editMapMutation } = useMapCache();
  const { connectedApp, appIds } = useConnectedAppSelector("connectedApp", "appIds");
  const [selectedApp, setSelectedApp] = useState<IConnectedApp | undefined>(undefined);

  const {
    control,
    formState: { errors },
    handleSubmit,
    resetField,
    setValue,
  } = useForm<Record<string, string>>({
    mode: "onSubmit",
    defaultValues: CONNECTED_APPS,
  });

  // State
  const [submitError, setSubmitError] = useState<string>("");

  useEffect(() => {
    appIds.forEach((appId, i) => setValue(`app${i + 1}`, appId));
    if (connectedApp) setSelectedApp(connectedApp);
  }, []);

  const onClose = useCallback(() => {
    dispatchCtx(setModalWithData({ isOpen: undefined, data: undefined }));
  }, []);

  const linkRedirection = (url: string) => window.open(url, "_blank");

  const handleAppToConnect = (_: any, data: DropdownProps): void => {
    const app = data.value as IConnectedApp;
    setSelectedApp(app);
  };

  const isGuidValid = useCallback((inpValue: string): boolean => guidPattern.test(inpValue), []);

  const clearInput = (idx: number): void => resetField(`app${idx}` as any);

  const getAppIdLink = useCallback((): void => {
    if (!selectedApp) return;
    const map: Record<IConnectedApp, string> = {
      [IConnectedApp.CALENDAR_PRO]: CALENDAR_GET_ID,
      [IConnectedApp.CONTACTS_PRO]: CONTACTS_GET_ID,
    };
    const link = map[selectedApp];
    window.open(link);
  }, [selectedApp]);

  const onSubmit: SubmitHandler<Record<string, string>> = async (data) => {
    try {
      if (!selectedApp) return;
      const ids = Object.values(data).filter(Boolean);
      if (!ids.length) return await removeConnectedApp();
      uniqueString(ids);
      const promises = ids.map((id) => {
        if (selectedApp === IConnectedApp.CALENDAR_PRO) {
          return isCalendarExist(id);
        }

        if (selectedApp === IConnectedApp.CONTACTS_PRO) {
          return isContactExist(id);
        }
        return;
      });
      await Promise.all(promises);
      dispatchCtx(setConnectedAppsAndIds({ connectedApp: selectedApp, appIds: ids }));
      const connect = {
        app: convertAppEnumToNumber(selectedApp) as any,
        guids: ids,
      };
      const params: IMap = { ...queryMap.data, connect };
      await editMapMutation.mutateAsync(params);
      onClose();
    } catch (error: unknown) {
      if (typeof error === "string") {
        if (error.endsWith("is duplicated.")) {
          const translateError = t("MessagePreventUniqueIds");
          setSubmitError(translateError);
        }

        if (error === "errorOccured") {
          const translateError = t("IDNotExists");
          setSubmitError(translateError);
        }
      }
    }
  };

  const removeConnectedApp = async () => {
    if (!connectedApp) return onClose();
    dispatchCtx(setConnectedApp(undefined));
    dispatchCtx(setAppIds([]));
    const newMap = { ...queryMap.data };
    delete newMap.connect;
    await editMapMutation.mutateAsync(newMap);
    onClose();
  };

  return {
    t,
    onClose,
    linkRedirection,
    handleAppToConnect,
    isGuidValid,
    control,
    handleSubmit,
    onSubmit,
    errors,
    clearInput,
    getAppIdLink,
    submitError,
    connectedApp,
    selectedApp,
  };
};
