import { setModalWithData, useTranslate } from "front";
import { useCallback } from "react";
import { translations } from "../../translations";
import { useDispatch, useSelector } from "react-redux";
import { IAppDispatch, ReduxStoreState } from "../../redux/store";

export const useValidateCancelDialog = () => {
  const t = useTranslate(translations);
  const dispatchCtx = useDispatch<IAppDispatch>();

  const data = useSelector((s: ReduxStoreState) => s.modal.data);

  const onClose = useCallback(() => {
    dispatchCtx(setModalWithData({ isOpen: undefined, data: undefined }));
  }, []);

  const handleSubmit = () => {
    if (!data) return;
    data.confirmClose();
    onClose();
  };

  return { t, onClose, handleSubmit };
};
