import { HomePro } from "@witivio_teamspro/home-pro-sdk";
import { useEffect, useState } from "react";
export const useHomeProToken = () => {
    const [token, setToken] = useState("");
    useEffect(() => {
        HomePro.authentication.getAuthToken({
            successCallback(token) {
                setToken(token);
            },
            failureCallback(error) {
                console.error(error);
            },
        });
    }, []);
    return { token };
};
