export var AppView;
(function (AppView) {
    AppView[AppView["Configure"] = 1] = "Configure";
    AppView[AppView["Calendars"] = 2] = "Calendars";
    AppView[AppView["Tags"] = 3] = "Tags";
    AppView[AppView["Remove"] = 4] = "Remove";
    AppView[AppView["Widget"] = 5] = "Widget";
    AppView[AppView["WidgetDetail"] = 6] = "WidgetDetail";
    AppView[AppView["WidgetConfigure"] = 7] = "WidgetConfigure";
    AppView[AppView["Permissions"] = 8] = "Permissions";
    AppView[AppView["Sync"] = 9] = "Sync";
    AppView[AppView["MeetingScheduler"] = 10] = "MeetingScheduler";
})(AppView || (AppView = {}));
