import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Flex, Header, Image, Loader, Text } from "@fluentui/react-northstar";
import { translations } from "../../translations";
import React, { memo } from "react";
import { useScopesService } from "../../services/ScopesService/ScopesService";
import { useAuthSelector } from "../../redux/reducers/AuthReducer/AuthReducer";
import { useScopesSelector } from "../../redux/reducers/ScopesReducer/ScopesReducer";
import { useTranslate } from "../../hook/useTranslate";
export const Consent = memo(() => {
    const isTeamsApp = useAuthSelector("isTeamsApp");
    const t = useTranslate(translations);
    const { teamsFxConfig, needToConsent, isConsenting } = useScopesSelector("teamsFxConfig", "needToConsent", "isConsenting");
    const { bindConsentButton } = useScopesService();
    if (!teamsFxConfig || !needToConsent)
        return isTeamsApp ? _jsx("div", {}) : _jsx(Loader, { styles: { height: "100%" } });
    return (_jsx(Flex, { fill: true, styles: { position: "absolute", top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999 }, vAlign: "center", hAlign: "center", column: true, children: _jsxs(Flex, { fill: true, vAlign: "center", hAlign: "center", column: true, children: [_jsx(Image, { width: "300px", height: "300px", alt: "login image", src: "/loginImage.svg" }), _jsx(Header, { styles: { marginTop: "10px", marginBottom: "5px" }, as: "h2", content: teamsFxConfig?.loginPageTitle }), _jsx(Text, { styles: { marginTop: "0", marginBottom: "15px" }, content: teamsFxConfig?.loginPageSubtitle }), _jsx(Button, { ref: bindConsentButton, primary: true, loading: isConsenting, disabled: isConsenting, content: t("Consent") })] }) }));
}, () => true);
