import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { setPersistantAlert, usePersitentAlertSelector } from "../../redux/reducers/PersistentAlertReducer/PersistentAlertReducer";
import { useNotificationCache } from "../../services/CacheService/notificationCache";
import { useMemo, useCallback } from "react";
import { GraphService } from "../../services/GraphService/GraphService";
import { usePremiumSelector } from "../../redux/reducers/PremiumReducer/PremiumReducer";
import { useMsTeamsSelector } from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { useConfigurationSelector } from "../../redux/reducers/ConfigurationReducer/ConfigurationReducer";
import { useAppConfigurationSelector } from "../../redux/reducers/AppConfigurationReducer/AppConfigurationReducer";
import ReactHtmlParser from 'react-html-parser';
import { PersistentAlertTymeEnum, PersistentCtaTypeEnum } from "../../interfaces/PersistantAlert";
import { billingNotifySuperAdmin } from "../../apis/Miscs/MiscsApi";
import { translations } from "../../translations";
import { useTranslate } from "../../..";
import { useDispatch } from "react-redux";
import { AcceptIcon } from "@fluentui/react-northstar";
export const PersistantAlertCTA = (props) => {
    const dispatch = useDispatch();
    const { persistentAlert } = usePersitentAlertSelector("persistentAlert");
    const { notif } = useNotificationCache();
    const { adminCenterData } = usePremiumSelector("adminCenterData");
    const { groupId, channelId, userId, chatId } = useMsTeamsSelector("groupId", "channelId", "userId", "chatId");
    const { data } = useConfigurationSelector("data");
    const { appId, from, appName } = useAppConfigurationSelector("appId", "from", "appName");
    const t = useTranslate(translations);
    const notifActive = useMemo(() => {
        if (!notif)
            return false;
        return Object.values(notif).some((v) => v === true);
    }, [notif]);
    const sendNotification = useCallback(() => {
        if (adminCenterData != null && adminCenterData.subscriptionLicenceId != null)
            billingNotifySuperAdmin(userId, appId, adminCenterData.subscriptionLicenceId);
        if (notifActive) {
            GraphService.sendNotification([{
                    id: adminCenterData.subscriptionOwner,
                    displayName: '',
                    userPrincipalName: '',
                    picture: ''
                }], !!channelId ? groupId : chatId, channelId, t("IC_NotifTitle"), t("IC_NotifMessage", { appName: appName }), data.manifestId, appId, from, window.location.href, !!channelId);
        }
        displaySuccess();
    }, [notifActive, groupId, channelId, appId, data.manifestId, from, window.location.href, adminCenterData]);
    const onClick = useCallback((e) => {
        e.preventDefault();
        switch (persistentAlert.cta.type) {
            case PersistentCtaTypeEnum.Notification:
                sendNotification();
                break;
            case PersistentCtaTypeEnum.Link:
                window.open(persistentAlert.cta.action, '_blank');
                break;
        }
    }, [persistentAlert.cta, sendNotification]);
    const displaySuccess = useCallback(() => {
        dispatch(setPersistantAlert({
            title: t("IC_SuccesNotifTitle"),
            message: t("IC_SuccesNotifMessage"),
            type: PersistentAlertTymeEnum.Success,
            icon: _jsx(AcceptIcon, {}),
            cta: null,
            closable: true
        }));
    }, []);
    return (_jsxs(_Fragment, { children: [ReactHtmlParser(persistentAlert.message), (persistentAlert.cta) && (_jsx("a", { href: "#", onClick: onClick, style: { paddingLeft: 5 }, children: persistentAlert.cta.label }))] }));
};
