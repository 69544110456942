import { InputTagName } from "../interfaces/InputTag";
export const constInputsTag = [
    {
        name: InputTagName.FirstName,
        isChecked: true,
        isRequired: true,
    },
    {
        name: InputTagName.LastName,
        isChecked: true,
        isRequired: true,
    },
    {
        name: InputTagName.Email,
        isChecked: true,
        isRequired: true,
    },
    {
        name: InputTagName.PhoneNumber,
        isChecked: true,
        isRequired: true,
    },
    {
        name: InputTagName.CompanyName,
        isChecked: true,
        isRequired: true,
    },
    {
        name: InputTagName.JobTitle,
        isChecked: true,
        isRequired: true,
    },
    {
        name: InputTagName.Note,
        isChecked: true,
        isRequired: false,
    },
];
