import { createSlice } from "@reduxjs/toolkit";
import { getSliceSelector } from "../../utils";
const initialState = {
    infos: null
};
const slice = createSlice({
    name: "draft",
    initialState,
    reducers: {
        setDraftInfo: (state, action) => {
            state.infos = action.payload;
        },
    },
});
export const { setDraftInfo } = slice.actions;
export const useDraftsSelector = getSliceSelector(slice);
export const draftsReducer = slice.reducer;
