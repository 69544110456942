import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as microsoftTeams from "@microsoft/teams-js";
import { SCOPE_GET_GRAPHCLIENT, SCOPE_INIT } from "./ScopesReducer.interfaces";
import { TeamsUserCredential } from "@microsoft/teamsfx";
import { checkConfig } from "../../../services/ScopesService/ScopesService";
import { translations } from "../../../translations";
import { login } from "../AuthReducer/AuthReducer";
import { ErrorModule } from "../../../components/ErrorBoundary/ErrorBoundary";
import { getSliceSelector } from "../../utils";
import { Client } from "@microsoft/microsoft-graph-client";
import { TokenApi } from "../../../apis/Token/TokenApi";
export let teamsFxGraphClient = undefined;
export let scopesServiceButtonAction = undefined;
const initialState = {
    loaded: false,
    needToConsent: false,
    isConsenting: false,
    teamsFxConfig: undefined,
};
const slice = createSlice({
    name: "scopes",
    initialState,
    reducers: {
        setScope: (state, action) => {
            Object.assign(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(initializeScope.fulfilled, (state, action) => {
            if (!action.payload)
                return ErrorModule.showErrorAlert("Can't initialize scopes reducer");
            Object.assign(state, action.payload);
        });
        builder.addCase(getGraphClient.fulfilled, (state, action) => {
            if (!action.payload)
                return;
            Object.assign(state, action.payload);
        });
    }
});
export const initializeScope = createAsyncThunk(SCOPE_INIT, async (data, { dispatch }) => {
    const { initArgs } = data;
    checkConfig(initArgs);
    const scopes = initArgs.scopes?.map(s => "https://graph.microsoft.com/" + s) ?? [];
    const teamsFxConfig = {
        initiateLoginEndpoint: initArgs.apiBaseUrl + "/auth-start.html",
        apiEndpoint: initArgs.apiBaseUrl + "",
        clientId: initArgs.clientId + "",
        tenantId: initArgs.tenantId + "",
        scopes: scopes.join(" "),
        authorityHost: "https://login.microsoftonline.com/common/v2.0",
        applicationIdUri: initArgs.apiBaseUrl + "/auth-start.html",
        loginPageTitle: initArgs.loginPageTitle ?? translations.get("NeedToConsent"),
        loginPageSubtitle: initArgs.loginPageSubtitle ?? translations.get("ClickButtonToContinue"),
    };
    const teamsFxCredential = new TeamsUserCredential(teamsFxConfig);
    if (!data.askGraphToken)
        return { loaded: true, needToConsent: false, isConsenting: false };
    dispatch(getGraphClient({ teamsFxCredential, scopes, initArgs }));
    return { teamsFxConfig };
});
export const initGraphClient = async (scopes) => {
    const graphToken = await TokenApi.getGraphToken(scopes);
    if (!graphToken)
        throw new Error("Invalid graph token");
    teamsFxGraphClient = Client.init({ authProvider: (callback) => callback(undefined, graphToken) });
    return { loaded: true, needToConsent: false, isConsenting: false };
};
const manageRedirect = (getState) => {
    const infos = getState().draft.infos;
    if (infos) {
        window.location.assign(window.location.origin + window.location.pathname + "?draft=" + encodeURIComponent(JSON.stringify(infos)));
    }
    else {
        window.location.reload();
    }
};
export const getGraphClient = createAsyncThunk(SCOPE_GET_GRAPHCLIENT, async (data, { getState, dispatch }) => {
    const { teamsFxCredential, scopes, initArgs } = data;
    try {
        return await initGraphClient(initArgs.scopes.join());
    }
    catch (error) {
        try {
            if (initArgs.isOnMobile && initArgs.inTeams) {
                await teamsFxCredential.login(scopes);
                window.location.reload();
            }
            else {
                scopesServiceButtonAction = async () => {
                    dispatch(setScope({ isConsenting: true }));
                    try {
                        if (initArgs.inTeams)
                            await teamsFxCredential.login(scopes);
                        else
                            dispatch(login());
                        //on attend 10 seconde pour laisse le temps au consent de s'activer
                        setTimeout(() => { manageRedirect(getState); }, 10000);
                    }
                    catch (e) {
                        manageRedirect(getState);
                    }
                };
                dispatch(setScope({ needToConsent: true }));
                microsoftTeams.app.notifySuccess();
            }
        }
        catch (loginError) {
            window.location.reload();
        }
        return;
    }
});
export const { setScope } = slice.actions;
export const useScopesSelector = getSliceSelector(slice);
export const scopesReducer = slice.reducer;
