import { AxiosConfig, tryCatch } from "front";
import { IPlace } from "../../types/IPlace/IPlace";

export const getPlacesAction = async ({ queryKey }: { queryKey: any[] }): Promise<IPlace[]> => {
  const [_, mapId, tenantId] = queryKey;
  const res = await AxiosConfig.instance.get(`/maps/${mapId}/places/tenantId/${tenantId}`);
  return res.data;
};

export const createPlaceAction = async (data: { place: IPlace }): Promise<IPlace> => {
  const res = await AxiosConfig.instance.post(`/maps/${data.place.mapId}/places`, data.place);
  return res.data;
};

export const editPlaceAction = async (data: { place: IPlace }): Promise<void> => {
  await AxiosConfig.instance.put(`/maps/${data.place.mapId}/places/${data.place.id}`, data.place);
};

export const deletePlaceAction = async (data: { mapId: string; placeId: string }): Promise<void> => {
  await AxiosConfig.instance.delete(`/maps/${data.mapId}/places/${data.placeId}`);
};

export const getPlaces = tryCatch(getPlacesAction);
export const createPlace = tryCatch(createPlaceAction);
export const editPlace = tryCatch(editPlaceAction);
export const deletePlace = tryCatch(deletePlaceAction);
