import React from "react";

const TrainIcon = () => {
  return (
    <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.86193e-07 13.5V4.00001C9.86193e-07 3.11667 0.229335 2.41234 0.688001 1.88701C1.14667 1.36167 1.75067 0.961674 2.5 0.687007C3.25 0.412341 4.10433 0.229007 5.063 0.137007C6.02167 0.0450073 7.00067 -0.000659472 8 7.19423e-06C9.1 7.19423e-06 10.1377 0.0460072 11.113 0.138007C12.0883 0.230007 12.9383 0.413341 13.663 0.688007C14.3877 0.962674 14.9583 1.36267 15.375 1.88801C15.7917 2.41334 16 3.11734 16 4.00001V13.5C16 14.4833 15.6627 15.3127 14.988 15.988C14.3133 16.6633 13.484 17.0007 12.5 17L14 18.5V19H12L10 17H6L4 19H2V18.5L3.5 17C2.51667 17 1.68733 16.6627 1.012 15.988C0.336668 15.3133 -0.00066568 14.484 9.86193e-07 13.5ZM2 8.00001H7V5.00001H2V8.00001ZM9 8.00001H14V5.00001H9V8.00001ZM4.5 14C4.93333 14 5.29167 13.8583 5.575 13.575C5.85833 13.2917 6 12.9333 6 12.5C6 12.0667 5.85833 11.7083 5.575 11.425C5.29167 11.1417 4.93333 11 4.5 11C4.06667 11 3.70833 11.1417 3.425 11.425C3.14167 11.7083 3 12.0667 3 12.5C3 12.9333 3.14167 13.2917 3.425 13.575C3.70833 13.8583 4.06667 14 4.5 14ZM11.5 14C11.9333 14 12.2917 13.8583 12.575 13.575C12.8583 13.2917 13 12.9333 13 12.5C13 12.0667 12.8583 11.7083 12.575 11.425C12.2917 11.1417 11.9333 11 11.5 11C11.0667 11 10.7083 11.1417 10.425 11.425C10.1417 11.7083 10 12.0667 10 12.5C10 12.9333 10.1417 13.2917 10.425 13.575C10.7083 13.8583 11.0667 14 11.5 14Z"
        fill="black"
      />
    </svg>
  );
};

export default TrainIcon;
