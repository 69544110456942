import { Button, CalendarIcon, ChevronEndIcon, ChevronStartIcon, Dropdown, Flex, FlexItem, MenuButton, Text } from "@fluentui/react-northstar";
import React, { memo, useMemo } from "react";
import { useCalendarNav } from "./CalendarNav.logic";
import FullCalendar from "@fullcalendar/react";
import "@fullcalendar/react/dist/vdom";
import { locales } from "calendarShared";
import "./CalendarNav.css";
import { plugins, timeZones, viewModes } from "./CalendarNav.data";

const CalendarNav = memo(() => {
  const logic = useCalendarNav();

  const navigation = useMemo(
    () => (
      <Flex gap="gap.small" vAlign={"center"} style={{ marginRight: "3rem", minWidth: "320px" }}>
        <Flex>
          <Button icon={<ChevronStartIcon />} iconOnly onClick={() => logic.handleCursorEvents("prev")} styles={{ marginRight: "5px" }} />
          <Button icon={<ChevronEndIcon />} iconOnly onClick={() => logic.handleCursorEvents("next")} />
        </Flex>
        <Button primary styles={{ minWidth: "auto" }} content={logic.t("Today")} onClick={logic.showTodayEvents} />
        <FlexItem push={logic.isOnMobile}>
          <Text styles={{ whiteSpace: "nowrap" }} weight={"bold"} content={logic.calendarTitle} />
        </FlexItem>
      </Flex>
    ),
    [logic.isOnMobile, logic.calendarTitle]
  );

  const tagsDropdown = useMemo(
    () => (
      <Dropdown
        clearable
        inverted
        fluid
        value={logic.searchTag}
        disabled={!logic.isPremium}
        items={logic.allTags ?? []}
        placeholder={logic.t("TagsTitle")}
        onChange={logic.handleSearchTag}
        search
        style={{ minWidth: logic.isOnMobile ? "150px" : "initial" }}
      />
    ),
    [logic.searchTag, logic.isPremium, logic.allTags]
  );

  const timeZoneDropdown = useMemo(
    () => (
      <Dropdown
        fluid
        inverted
        items={timeZones}
        value={logic.calendarTimeZone}
        placeholder={logic.calendarTimeZone}
        onChange={logic.handleCalendarTimeZone}
        style={{ minWidth: logic.isOnMobile ? "150px" : "initial", position: "initial" }}
      />
    ),
    [logic.calendarTimeZone]
  );

  const viewMode = useMemo(
    () => (
      <MenuButton
        styles={{ width: logic.isOnMobile ? "100%" : "auto" }}
        trigger={<Button fluid={logic.isOnMobile} primary icon={<CalendarIcon />} content={logic.getViewModeLabel} />}
        menu={{
          styles: logic.isOnMobile ? { minWidth: "calc(100vw - 20px)" } : {},
          fluid: logic.isOnMobile,
          items: viewModes.map((i) => ({ ...i, content: logic.t(i.content) })) ?? [],
        }}
        onMenuItemClick={logic.handleChangeDisplayMode}
      />
    ),
    [logic.isOnMobile, logic.displayMode]
  );

  return (
    <Flex hAlign="start" vAlign="start">
      {navigation}
      <Flex gap="gap.small">
        {tagsDropdown}
        {timeZoneDropdown}
        {viewMode}
      </Flex>
      <FullCalendar ref={logic.fullcalendarRef} timeZone={logic.calendarTimeZone} locales={locales} locale={logic.locale} plugins={plugins} />
    </Flex>
  );
});

export default CalendarNav;
