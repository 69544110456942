import { setModalWithData, useTranslate } from "front";
import { useCallback } from "react";
import { translations } from "../../translations";
import { useDispatch, useSelector } from "react-redux";
import { IAppDispatch, ReduxStoreState } from "../../redux/store";
import { useMapCache } from "../../hooks/useMapCache/useMapCache";
import { useTripCache } from "../../hooks/useTripCache/useTripCache";

export const useDeleteTripDialog = () => {
  const t = useTranslate(translations);
  const dispatchCtx = useDispatch<IAppDispatch>();

  const data = useSelector((s: ReduxStoreState) => s.modal.data);

  const { deleteTripMutation, getTripById } = useTripCache();
  const { queryMap } = useMapCache();

  const onClose = useCallback(() => {
    dispatchCtx(setModalWithData({ isOpen: undefined, data: undefined }));
  }, []);

  const getPlaceName = (): string => {
    const trip = getTripById(data.tripId);
    return trip?.title ?? "";
  };

  const handleSubmit = () => {
    if (!data) return;
    const params = {
      mapId: queryMap.data.id!,
      tripId: data.tripId,
    };
    deleteTripMutation.mutate(params);
    if (data.tripId === data.currentDisplayedTrip) data.googleTravel.clearTrip();
    onClose();
  };

  return { t, isLoading: deleteTripMutation.isLoading, onClose, handleSubmit, getPlaceName };
};
