import { useState, useEffect } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { getPremium, usePremiumSelector } from "../../redux/reducers/PremiumReducer/PremiumReducer";
import { useConfigurationSelector } from "../../redux/reducers/ConfigurationReducer/ConfigurationReducer";
import { createAccessRequest, getAppPlansUrl, getProductUrl, getProductUrlByWS } from "../../apis/AdminCenter/adminCenterApi";
import { useMsTeamsSelector } from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { useDispatch } from "react-redux";
import { openModal } from "../../redux/reducers/ModalReducer/ModalReducer";
import { translations } from "../../translations";
import { useTranslate } from "../../hook/useTranslate";
import { useAppConfigurationSelector } from "../../redux/reducers/AppConfigurationReducer/AppConfigurationReducer";
import { appSourceBaseUrl } from "../dialogs/Upgrade/UpgradeDialog";
import { configIframe } from "../../const/configIframe";
import { IframeManager } from "../../utils/IframeManager";
export const useLogic = (props) => {
    const { adminCenterData } = usePremiumSelector("adminCenterData");
    const { locale, userName, userId, tenantId, isOnMobile } = useMsTeamsSelector("locale", "userName", "userId", "tenantId", "isOnMobile");
    const { data: config } = useConfigurationSelector("data");
    const { from } = useAppConfigurationSelector("from");
    const [licencePlanId, setLicencePlanId] = useState("");
    const dispatch = useDispatch();
    const t = useTranslate(translations);
    const [user, setUser] = useState({});
    const [state, setState] = useState({
        loaded: false,
        isPaymentOpen: false,
        showPremiumActivatedContent: false,
        isLicencesAvailable: false,
        showRequestAccessDialog: false,
        requestAccessMessage: "",
        sendingAccessRequest: false,
        isPendingAccessRequest: false,
        isRequestSent: false,
        appName: "",
        isUserAdmin: false,
        isUserSubscriptionOwner: false,
        forceUpgradePremium: false,
        upgradePremiumMessage: t("UnlockPremiumFeatures"),
        appSourceOpened: false,
    });
    const getDefaultRequestMessage = async (productName) => {
        return t("DefaultRequestMessage", {
            userName: userName,
            appTitle: productName,
        });
    };
    /**
     * Update config
     */
    const updateConfig = (config) => {
        setState((prevState) => {
            const newState = { ...prevState };
            if (config.forceUpgradePremium)
                newState.forceUpgradePremium = config.forceUpgradePremium;
            if (config.upgradePremiumMessage)
                newState.upgradePremiumMessage = config.upgradePremiumMessage;
            return newState;
        });
    };
    useEffect(() => {
        if (!adminCenterData)
            return;
        const init = async () => {
            await microsoftTeams.app.initialize();
            const context = await microsoftTeams.app.getContext();
            setLicencePlanId(adminCenterData.subscriptionLicenceId);
            const defaultRequestMessage = await getDefaultRequestMessage(adminCenterData.productName);
            setUser({
                AadId: context.user.id,
                Upn: context.user.userPrincipalName,
                DisplayName: context.user.displayName,
                Email: context.user.userPrincipalName,
                TenantId: context.user.tenant.id,
            });
            setState((prev) => ({
                ...prev,
                isPendingAccessRequest: adminCenterData.isAccessRequestPending,
                isLicencesAvailable: adminCenterData.isTenantLicenceAvailable,
                requestAccessMessage: defaultRequestMessage,
                isUserAdmin: adminCenterData.isUserAdmin,
                isUserSubscriptionOwner: adminCenterData.subscriptionOwner === userId,
                loaded: true,
            }));
            //props.onRef({ updateConfig: updateConfig });
        };
        init();
    }, [adminCenterData]);
    /**
     * Show payment page
     */
    const showPaymentPage = () => {
        setState((prev) => ({ ...prev, isPaymentOpen: true }));
        !!props.appInsight && props.appInsight?.trackEvent({
            name: "premium_pricing_open",
        });
        if (config.appSourceOfferId != undefined && config.appSourceOfferId.trim() != "") {
            setState((prev) => ({ ...prev, isPaymentOpen: false, showPremiumActivatedContent: false, isClosable: false, appSourceOpened: true }));
            setTimeout(() => {
                setState((prev) => ({ ...prev, isClosable: true }));
                window.open(appSourceBaseUrl + locale + "/product/web-apps/" + config.appSourceOfferId, "_blank");
            }, 4000);
        }
        else {
            const dataFunction = (data) => {
                if (data.err && !data.result) {
                    setLicencePlanId("");
                    setState((prev) => ({ ...prev, isPaymentOpen: false, showPremiumActivatedContent: false }));
                    !!props.appInsight && props.appInsight?.trackEvent({
                        name: configIframe.PremiumPricing.trackingIds.cancel,
                    });
                }
                else {
                    setLicencePlanId(data.result);
                    setState((prev) => ({ ...prev, isPaymentOpen: false, showPremiumActivatedContent: true }));
                    !!props.appInsight && props.appInsight?.trackEvent({ name: configIframe.PremiumPricing.trackingIds.validate });
                }
            };
            IframeManager(configIframe.PremiumPricing, isOnMobile, props.appInsight, from, getAppPlansUrl(user, props.appId, config.apiSubscription), getAppPlansUrl(user, props.appId, undefined, config.adminCenterBaseUrl), dataFunction);
        }
    };
    /**
     * Assign licences
     */
    const assignLicences = () => {
        const dataFunction = (data) => {
            if (data.err && !data.result) {
                !!props.appInsight && props.appInsight?.trackEvent({
                    name: configIframe.PremiumAssign.trackingIds.cancel,
                });
            }
            else {
                setState((prev) => ({ ...prev, isPaymentOpen: false, showPremiumActivatedContent: true }));
                !!props.appInsight && props.appInsight?.trackEvent({ name: configIframe.PremiumAssign.trackingIds.validate });
                dispatch(openModal(undefined));
                dispatch(getPremium({ tid: tenantId, userId }));
            }
        };
        IframeManager(configIframe.PremiumAssign, isOnMobile, props.appInsight, from, getProductUrlByWS(user, licencePlanId, config.apiSubscription), getProductUrl(licencePlanId, true, config.adminCenterBaseUrl), dataFunction);
    };
    /**
     * Close dialog
     */
    const closeDialog = () => {
        if (state.showPremiumActivatedContent)
            window.location.reload();
        else if (state.appSourceOpened) {
            setState((prev) => ({ ...prev, appSourceOpened: false }));
            dispatch(getPremium({ tid: tenantId, userId }));
        }
        else
            dispatch(openModal(undefined));
    };
    /**
     * Send an access request
     */
    const sendAccessRequest = async () => {
        setState((prev) => ({ ...prev, sendingAccessRequest: true }));
        await createAccessRequest(user, props.appId, state.requestAccessMessage, locale, config.adminCenterBaseUrl);
        setState((prev) => ({ ...prev, sendingAccessRequest: false, showRequestAccessDialog: false, requestAccessMessage: "", isRequestSent: true }));
    };
    return {
        state,
        closeDialog,
        sendAccessRequest,
        assignLicences,
        showPaymentPage,
        licencePlanId,
        setState,
        user,
        setLicencePlanId,
        appId: props.appId
    };
};
