import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { USER_CURRENT } from "./UserReducer.interfaces";
import { ErrorModule } from "../../../components/ErrorBoundary/ErrorBoundary";
import { GraphService } from "../../../services/GraphService/GraphService";
import { getSliceSelector } from "../../utils";
const initialState = {
    currentUser: undefined,
};
const slice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCurrentUser.fulfilled, (state, action) => {
            if (!action.payload)
                throw new Error("No Current User");
            state.currentUser = action.payload;
        });
    }
});
export const fetchCurrentUser = createAsyncThunk(USER_CURRENT, async (userId) => {
    const users = await GraphService.getUsersAsync([userId]);
    if (users.length === 0)
        return ErrorModule.showErrorAlert("Can't get current user");
    const currentUser = { ...users[0], id: userId };
    return currentUser;
});
export const { setCurrentUser } = slice.actions;
export const useUserSelector = getSliceSelector(slice);
export const userReducer = slice.reducer;
