import * as microsoftTeams from "@microsoft/teams-js";
import { FrameContexts, HostClientType, UserTeamRole } from "@microsoft/teams-js";
import moment from "moment";
import "moment/locale/fr";
import { teamsDarkTheme, teamsHighContrastTheme, teamsTheme } from "@fluentui/react-northstar";
import { translations } from "../../translations";
import { ErrorModule } from "../../components/ErrorBoundary/ErrorBoundary";
import { ThemeClass } from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer.interfaces";
export const initializeFromTeams = async (accessToken) => {
    const context = await microsoftTeams.app.getContext();
    moment.locale(context.app.locale);
    const isOnMobile = [HostClientType.ios, HostClientType.android, HostClientType.ipados].includes(context.app.host.clientType);
    let instance = null;
    if (!isOnMobile)
        instance = await microsoftTeams.pages.tabs.getTabInstances();
    const stateUpdate = {
        loaded: !!accessToken,
        locale: context.app.locale?.substring(0, 2).toLowerCase(),
        fullLocale: context.app.locale,
        groupId: context.team?.groupId ?? context.channel?.ownerGroupId,
        channelId: context.channel?.id,
        hostClientType: context.app.host.clientType,
        teamId: context.team?.internalId,
        teamName: context.team?.displayName,
        tenantId: context.user?.tenant?.id,
        channelName: context.channel?.displayName,
        userMail: (context.user?.loginHint).toLowerCase(),
        userId: context.user?.id,
        isPrivateChannel: context.channel?.membershipType === "Private",
        isOnMobile: isOnMobile,
        isInPersonalApp: context.page.id === "personnal" || context.page.id === "keepassPersonalTab",
        subEntityId: context.page.subPageId,
        isConfiguringApp: context.page.frameContext === FrameContexts.settings,
        isDeletingApp: context.page.frameContext === FrameContexts.remove,
        isInTaskModule: context.page.frameContext === microsoftTeams.FrameContexts.task,
        userName: !accessToken ? "" : await getUserName(accessToken),
        meetingId: context.meeting?.id ?? "",
        isInMeeting: !!context.meeting?.id,
        isInMeetingSidePanel: context.page.frameContext === FrameContexts.sidePanel,
        userRole: context.team?.userRole ?? UserTeamRole.User,
        isTeamsIframe: true,
        teamSitePath: context.sharePointSite.teamSitePath,
        teamSiteDomain: context.sharePointSite.teamSiteDomain,
        tabInternalId: instance?.teamTabs.find((t) => t.entityId == context.page.id)?.internalTabInstanceId ?? "",
        tabName: instance?.teamTabs.find((t) => t.entityId == context.page.id)?.tabName ?? "",
        newTeams: context.app.host.name == "TeamsModern",
        sku: context.user.tenant.teamsSku,
        chatId: context.chat?.id,
        entityId: context.page.id
    };
    translations.locale = stateUpdate.locale ?? "en";
    if (stateUpdate.isOnMobile)
        document.body.className = "on-mobile";
    stateUpdate.isTouchScreen = "ontouchstart" in window || navigator.maxTouchPoints > 0;
    if (stateUpdate.isConfiguringApp)
        stateUpdate.entityId = await getEntityId();
    //if (accessToken && !stateUpdate.isInTaskModule) await TokenApi.saveToken();
    return stateUpdate;
};
export const initializeOutsideOfTeams = async (accessToken) => {
    const fullLocale = window.navigator.language;
    const locale = fullLocale.substring(0, 2).toLowerCase();
    translations.locale = locale;
    moment.locale(fullLocale);
    const parentUrl = window.location != window.parent.location ? document.referrer : document.location.href;
    const isTeamsIframe = parentUrl.includes("teams.microsoft.com");
    //if (accessToken) await TokenApi.saveToken();
    return {
        loaded: !!accessToken,
        isTeamsApp: false,
        isTeamsIframe,
        tenantId: accessToken?.tenantId ?? "",
        userId: accessToken?.userId ?? "",
        userMail: accessToken?.userMail ?? "",
        userName: accessToken?.userName ?? "",
        channelId: "",
        groupId: "",
        fullLocale,
        locale,
    };
};
const getEntityId = async () => {
    const settings = await microsoftTeams.pages.getConfig();
    return settings.entityId ?? "";
};
const getUserName = async (accessToken) => {
    if (!accessToken.token) {
        console.error("Can't get user name");
        return "";
    }
    const tokenData = parseJWTToken(accessToken.token);
    return tokenData.name;
};
const parseJWTToken = (token) => {
    const base64Url = token.split(".")[1];
    if (!base64Url)
        return ErrorModule.showErrorAlert("Token base64 url is undefined");
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(atob(base64)
        .split("")
        .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
    })
        .join(""));
    return JSON.parse(jsonPayload);
};
export const getCurrentTheme = (themeClass) => {
    switch (themeClass) {
        case ThemeClass.Default:
            return teamsTheme;
        case ThemeClass.Dark:
            return teamsDarkTheme;
        case ThemeClass.HighContrast:
            return teamsHighContrastTheme;
        default:
            return teamsTheme;
    }
};
