import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAppConfigurationSelector } from "../redux/reducers/AppConfigurationReducer/AppConfigurationReducer";
import { fetchDefaultPerm, fetchGlobalPerm, fetchUserPerm, usePermissionsSelector } from "../redux/reducers/Permissions/PermissionsReducer";
import { useMsTeamsSelector } from "../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
export const usePermInitializer = () => {
    const { appId, appOwner } = useAppConfigurationSelector("appId", "appOwner");
    const { userId } = useMsTeamsSelector("userId");
    const { userPerm, loaded: permLoad, defaultRight, } = usePermissionsSelector("userPerm", "loaded", "defaultRight");
    const dispatch = useDispatch();
    useEffect(() => {
        if (!appId)
            return;
        if (permLoad) {
            if (userPerm["id"])
                return;
            dispatch(fetchUserPerm({ projectId: appId, userId: userId, createdBy: appOwner ?? "" }));
            dispatch(fetchGlobalPerm(appId));
            return;
        }
        if (Object.keys(defaultRight).length === 0)
            dispatch(fetchDefaultPerm(appId));
    }, [appId, permLoad, userId, defaultRight, appOwner, userPerm]);
};
