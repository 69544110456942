export const translations = {
  locale: "en",
  get: (id: string, parameters?: Record<string, string | number>): string => {
    if (!id) return "";
    const object = (translations as any)[translations.locale] ?? (translations as any).en;
    let translation = object[id] ?? (translations as any).en[id] ?? "";
    if (!translation) {
      console.error("Missing translation for '" + id + "'");
      translation = id;
    }
    if (parameters) {
      for (const param in parameters) translation = translation.replaceAll("{" + param + "}", parameters[param] + "");
    }
    return translation;
  },
  en: {
    LoginWelcomeApp: "Welcome to Map Pro",
    LoginWelcomeAppSubtitle: "Your personalized map for Microsoft Teams",
    LoginButton: "Let's go 🚀",
    LoginWelcomeAccessDenied: "You can't continue without Admin consent.",
    MapPro: "Map Pro",
    titleMenuPlaces: "Places",
    titleMenuTrips: "Trips",
    titleSideBarPlace: "Places",
    titleSideBarTrip: "Trips",
    DisplayAllPlaces: "Display all the places?",
    DisplayAllTrips: "Display all the trips?",
    TitleButtonAddPlace: "Add a place",
    TitleButtonAddTrip: "Add a trip",
    TitleModalPlaceAddPlace: "Add a Place",
    TitleModalPlaceTitle: "Title",
    TitleModalPlaceAddress: "Address",
    TitleModalPlaceDescription: "Description",
    ButtonCancelModalPlace: "Cancel",
    Save: "Save",
    TitleModalEditPlace: "Edit a Place",
    TitleModalTripAddTrip: "Add a Trip",
    TitleModalTripType: "Type",
    TitleModalTripTitle: "Title",
    TitleModalTripFrom: "From",
    TitleModalTripTo: "To",
    TitleModalTripDescription: "Description",
    ButtonCancelModalTrip: "Cancel",
    TitleModalEditTrip: "Edit a Trip",
    MessageRequired: "This field is required",
    Car: "Car",
    Train: "Train",
    Foot: "Foot",
    Bicycle: "Bicycle",
    BadAddress: "Invalid address",
    Confirm: "Confirm",
    HeaderDiscardModal: "Unsaved changes",
    ContentDiscardModal: "Do you want to discard edits or continue editing ?",
    ButtonCancelDiscard: "Discard",
    ButtonContinueDiscard: "Continue editing",
    AddTitle: "Add a title",
    TypeAdresse: "Type here...",
    NoTripFound: "We couldn't find any valid trip",
    MessageConfiguration: "Everything is set up.",
    MessageConfigurationSubtitle: "Click Save to continue.",
    WelcomeMessage: "Welcome to Map Pro",
    WelcomeSubtitle: "Your personalized map for Microsoft Teams",
    AllGoodMessage: "All good, please to click Save to continue",
    LestGoButton: "Let's go  🚀",
    RemoveMap: "Remove map",
    RemoveMapMessage: "Click on remove button to remove your map",
    FreeVersion: "You're using Map Pro free version.",
    RemoveMapsTitle: "Are you sure you want to delete this tab?",
    RemoveMapsDescription: "All the information associated to this tab will be permanently removed. You will not be able to recover the content.",
    RemoveCalendarTitle: "Read before deleting the Map Pro: ",
    RemoveCalendarItem1: "1. When deleting Map Pro, you also delete all the data stored. Make sure you retrieve all necessary information before deleting it.",
    RemoveCalendarItem2: "2. You cannot recover Map Pro after deleting it. Once you delete it, we will not be able to restore it.",
    RemoveCalendarItem3: "3. We will automatically terminate the payment for this Map Pro at the end of the term. Read more about {removeCalendarCancellation}.",
    RemoveCalendarItem4: "4. If you want to keep using the Map Pro, but on the free version, you do not need to delete the Map Pro. Visit our cancellation policy page to learn how to do it.",
    RemoveCalendarItem5: "5. If you are deleting it because Map Pro has issues. We recommend you visit our {RemoveCalendarFAQ} or {RemoveCalendarContact} us before proceeding.",
    RemoveCalendarFAQ: "Map Pro FAQ page",
    RemoveCalendarCancellationText: "Witivio’s cancellation policy here",
    RemoveCalendarContact: "Contact us",
    ClickHere: "Click here",
    PremiumMessage: "To add more places or trips go to Premium",
    AvailableInPremium: "Available in Premium",
    GetAppID: "About",
    GetId: "About",
    Copy: "Copy",
    UniqueId: "Unique identifier",
    Ok: "Ok",
    Copied: "Copied!",
    Version: "Version",
    ToKnowMore: "To know more.",
    TeamsProApplications: "TeamsPro application",
    SelectionApplication: "Select an application",
    ConnectIDLabel: "Unique identifier",
    ConnectLabelError: "this field must be an unique identifier",
    ConnectHowTo: "How to get the unique identifier?",
    ConnectButton: "Connect",
    Cancel: "Cancel",
    MessagePreventUniqueIds: "The unique identifiers must be different.",
    Today: "Today",
    Tags: "Tags",
    LabelStartDate: "Start",
    LabelEndDate: "End",
    LabelTimeZone: "Timezone",
    IDNotExists: "This id does not exists.",
    NotRepeat: "Does not repeat",
    Workweek: "From Monday to Friday",
    Weekly: "Weekly",
    Daily: "Daily",
    Monthly: "Monthly",
    Yearly: "Yearly",
    Custom: "Custom",
    Month: "Month",
    Week: "Week",
    Day: "Day",
    Disconnect: "Disconnect",
    Recurrence: "Recurrence",
    Search: "Please search using Name, Job, Company, Department, Notes, Groups, or Address to find what you need.",
    Email: "Email",
    Phone: "Phone",
    MapNotExist: "This map has been deleted",
    "Help Center": "Help Center",
    "Suggest Feature": "Suggest a feature",
    PremiumMessage1: "You are using the free version of Map Pro 🚀",
    PremiumMessage2: "to upgrade to the Premium version",
    SuggestFeature: "Suggest a Feature",
    HelpCenter: "Help Center",
    TagsTitle: "Tags",
    About: "About",
    Close: "Close",

    DeleteMessage: "Do you want to delete this {event}",
    Deletion: "Delete {event}",
    Delete: "Delete",
    Place: "place",
    Trip: "trip",
    TitleConnect: "You can link Contacts Pro or Calendar Pro and display items on Map Pro.",
    UpgradeToPaidPlan: "Upgrade",
  },
  fr: {
    LoginWelcomeApp: "Bienvenue sur Carte Pro",
    LoginWelcomeAppSubtitle: "Votre carte personnalisée pour Microsoft Teams",
    LoginButton: "C'est parti 🚀",
    LoginWelcomeAccessDenied: "Vous ne pouvez pas continuer sans consentement Administrateur",
    MapPro: "Carte Pro",
    titleMenuPlaces: "Lieux",
    titleMenuTrips: "Itinéraires",
    titleSideBarPlace: "Lieux",
    titleSideBarTrip: "Itinéraires",
    DisplayAllPlaces: "Afficher tous les lieux ?",
    DisplayAllTrips: "Afficher tous les itinéraires ?",
    TitleButtonAddPlace: "Ajouter un lieu",
    TitleButtonAddTrip: "Ajouter un itinéraire",
    TitleModalPlaceAddPlace: "Ajouter un lieu",
    TitleModalPlaceTitle: "Titre",
    TitleModalPlaceAddress: "Adresse",
    TitleModalPlaceDescription: "Description",
    ButtonCancelModalPlace: "Annuler",
    Save: "Sauvegarder",
    TitleModalEditPlace: "Modifier un lieu",
    TitleModalTripAddTrip: "Ajouter un itinéraire",
    TitleModalTripType: "Type",
    TitleModalTripTitle: "Titre",
    TitleModalTripFrom: "De",
    TitleModalTripTo: "À",
    TitleModalTripDescription: "Description",
    ButtonCancelModalTrip: "Annuler",
    TitleModalEditTrip: "Modifier un itinéraire",
    MessageRequired: "Ce champ est requis",
    Car: "Voiture",
    Train: "Train",
    Foot: "Pied",
    Bicycle: "Vélo",
    BadAddress: "Adresse invalide",
    Confirm: "Valider",
    HeaderDiscardModal: "Modifications non enregistrées",
    ContentDiscardModal: "Voulez-vous ignorer les modifications apportées ou continuer la modification ?",
    ButtonCancelDiscard: "Ignorer",
    ButtonContinueDiscard: "Continuer l'édition",
    AddTitle: "Ajouter un titre",
    TypeAdresse: "Écrivez ici...",
    NoTripFound: "Nous n'avons pas trouvé d'itineraire valide",
    MessageConfiguration: "Tout est prêt.",
    MessageConfigurationSubtitle: "Veuillez cliquer sur enregistrer pour continuer...",
    WelcomeMessage: "Bienvenue sur Carte Pro",
    WelcomeSubtitle: "Votre carte personnalisée pour Microsoft Teams",
    AllGoodMessage: "Tout est bon, s'il vous plaît cliquez sur enregistrer pour continuer",
    LestGoButton: "Allons-y  🚀",
    RemoveMap: "Supprimer la carte",
    RemoveMapMessage: "Cliquez sur le bouton supprimer pour supprimer la Carte Pro",
    FreeVersion: "Vous utilisez la version gratuite de Carte Pro.",
    RemoveMapsTitle: "Êtes-vous sûr de vouloir supprimer cet onglet?",
    RemoveMapsDescription: "Toutes les informations associées à cet onglet seront définitivement supprimées. Vous ne pourrez pas récupérer le contenu.",
    RemoveCalendarTitle: "A lire avant de supprimer Carte Pro: ",
    RemoveCalendarItem1:
      "1. Lorsque vous supprimez Carte Pro, vous supprimez également toutes les données stockées. Assurez-vous de récupérer toutes les informations nécessaires avant de la supprimer.",
    RemoveCalendarItem2: "2. Vous ne pouvez pas récupérer Carte Pro après l'avoir supprimée. Une fois que vous l'avez supprimée, nous ne pourrons pas la restaurer.",
    RemoveCalendarItem3: "3. Nous mettrons automatiquement fin au paiement de Carte Pro à la fin de la période. En savoir plus sur {removeCalendarCancellation}.",
    RemoveCalendarItem4:
      "4. Si vous souhaitez continuer à utiliser Carte Pro, mais sur la version gratuite, vous n'avez pas besoin de supprimer Carte Pro. Consultez notre page sur les conditions d'annulation pour savoir comment procéder.",
    RemoveCalendarItem5:
      "5. Si vous effacez Carte Pro parce que l'application a des problèmes. Nous vous recommandons de visiter notre {RemoveCalendarFAQ} ou {RemoveCalendarContact} avant de procéder.",
    RemoveCalendarFAQ: "Carte Pro FAQ page",
    RemoveCalendarCancellationText: "La politique d'annulation de Witivio ici",
    RemoveCalendarContact: "Nous contacter",
    ClickHere: "Cliquez ici",
    PremiumMessage: "Pour ajouter plus de lieux ou d'itinéraires passez Premium",
    AvailableInPremium: "Disponible en Premium",
    GetAppID: "À propos",
    GetId: "À propos",
    Copy: "Copier",
    UniqueId: "Identifiant unique",
    Ok: "Ok",
    Copied: "Copié !",
    Version: "Version",
    ToKnowMore: "Pour en savoir plus.",
    TeamsProApplications: "Application TeamsPro",
    SelectionApplication: "Sélectionnez une application",
    ConnectIDLabel: "Identifiant unique",
    ConnectLabelError: "ce champ doit être un identifiant unique",
    ConnectHowTo: "Comment obtenir l'identifiant unique ?",
    ConnectButton: "Connecter",
    Cancel: "Annuler",
    MessagePreventUniqueIds: "Les identifiants uniques doivent être différents",
    Today: "Aujourd'hui",
    Tags: "Tags",
    LabelStartDate: "Début",
    LabelEndDate: "Fin",
    LabelTimeZone: "Fuseau horaire",
    IDNotExists: "Cet identifiant n'existe pas.",
    NotRepeat: "Ne pas répéter",
    Workweek: "Du Lundi au Vendredi",
    Weekly: "Hebdomadaire",
    Daily: "Journalier",
    Monthly: "Mensuel",
    Yearly: "Annuel",
    Custom: "Personnaliser",
    Month: "Mois",
    Week: "Semaine",
    Day: "Jour",
    Disconnect: "Déconnexion",
    Recurrence: "Récurrence",
    Search: "Veuillez chercher par Nom, Poste, Entreprise, Département, Notes, Groupes, ou Adresse pour trouver ce dont vous avez besoin.",
    Email: "Email",
    Phone: "Téléphone",
    MapNotExist: "Cette carte a été supprimée",
    "Help Center": "Centre d'aide",
    "Suggest Feature": "Proposer une fonctionnalité",
    PremiumMessage1: "Vous utilisez la version gratuite de Map Pro 🚀",
    PremiumMessage2: "pour passer à la version Premium",
    SuggestFeature: "Suggérer une fonctionnalité",
    HelpCenter: "Centre d'aide",
    TagsTitle: "Tags",
    About: "À propos",
    Close: "Fermer",

    DeleteMessage: "Voulez-vous supprimer ce {event}",
    Deletion: "Supprimer {event}",
    Delete: "Supprimer",
    Place: "lieu",
    Trip: "voyage",
    TitleConnect: "Vous pouvez lier Contacts Pro ou Calendar Pro et afficher les éléments sur Map Pro.",
    UpgradeToPaidPlan: "Mettre à niveau",
  },
  it: {
    LoginWelcomeApp: "Benvenuti a Mappa Pro",
    LoginWelcomeAppSubtitle: "La tua mappa personalizzata per Microsoft Teams",
    LoginButton: "Andiamo  🚀",
    LoginWelcomeAccessDenied: "Non puoi continuare senza il consenso dell'amministratore.",
    MapPro: "Mappa Pro",
    titleMenuPlaces: "Luoghi",
    titleMenuTrips: "Itinerari",
    titleSideBarPlace: "Luoghi",
    titleSideBarTrip: "Percorsi",
    DisplayAllPlaces: "Visualizza tutti i luoghi?",
    DisplayAllTrips: "Visualizza tutti gli itinerari?",
    TitleButtonAddPlace: "Aggiungi un luogo",
    TitleButtonAddTrip: "Aggiungi un itinerario",
    TitleModalPlaceAddPlace: "Aggiungi un luogo",
    TitleModalPlaceTitle: "Titolo",
    TitleModalPlaceAddress: "Indirizzo",
    TitleModalPlaceDescription: "Descrizione",
    ButtonCancelModalPlace: "Annulla",
    Save: "Salva",
    TitleModalEditPlace: "Modifica un luogo",
    TitleModalTripAddTrip: "Aggiungi un viaggio",
    TitleModalTripType: "Tipo *",
    TitleModalTripTitle: "Titolo *",
    TitleModalTripFrom: "Da *",
    TitleModalTripTo: "A *",
    TitleModalTripDescription: "Descrizione",
    ButtonCancelModalTrip: "Annulla",
    TitleModalEditTrip: "Modifica un itinerario",
    MessageRequired: "Questo campo è obbligatorio",
    Car: "Automobile",
    Train: "Treno",
    Foot: "A piede",
    Bicycle: "Bicicletta",
    BadAddress: "Indirizzo non valido",
    Confirm: "Validare",
    HeaderDiscardModal: "Modifiche non salvate",
    ContentDiscardModal: "Vuoi continuare ad apportare modifiche o rimuoverle ?",
    ButtonCancelDiscard: "Rimuovi",
    ButtonContinueDiscard: "Continua la modifica",
    AddTitle: "Aggiungi un titolo",
    TypeAdresse: "Digitare qui...",
    NoTripFound: "Non abbiamo trovato nessun itinerari valido",
    MessageConfiguration: "Tutto è pronto.",
    MessageConfigurationSubtitle: "Clicca su salva per continuare...",
    WelcomeMessage: "Benvenuti a Mappa Pro",
    WelcomeSubtitle: "La tua mappa personalizzata per Microsoft Teams",
    AllGoodMessage: "Tutto bene, cliccare su salva per continuare",
    LestGoButton: "Andiamo  🚀",
    RemoveMap: "Rimuovere la mappa",
    RemoveMapMessage: "Clicca sul pulsante rimuovi per rimuovere la tua mappa",
    FreeVersion: "Stai usando la versione gratuita di Mappe Pro.",
    RemoveMapsTitle: "",
    RemoveMapsDescription: "",
    RemoveCalendarTitle: "Leggere prima di cancellare il Mappe Pro: ",
    RemoveCalendarItem1: "1. Quando si cancella Mappe Pro, si cancellano anche tutti i dati memorizzati. Assicurati di recuperare tutte le informazioni necessarie prima di cancellarlo.",
    RemoveCalendarItem2: "2. Non è possibile recuperare una Mappe Pro dopo averla cancellata. Una volta che l'hai cancellata, non saremo in grado di ripristinarla.",
    RemoveCalendarItem3: "3. Termineremo automaticamente il pagamento di questa Mappe Pro alla fine del termine. Per saperne di più {removeCalendarCancellation}.",
    RemoveCalendarItem4:
      "4. Se vuoi continuare a usare Mappe Pro, ma nella versione gratuita, non hai bisogno di cancellare Mappe Pro. Visita la nostra pagina sulla politica di cancellazione per sapere come fare.",
    RemoveCalendarItem5: "5. Se lo state cancellando perché Mappe Pro ha dei problemi. Ti consigliamo di visitare il nostro {RemoveCalendarFAQ} o {RemoveCalendarContact} prima di procedere.",
    RemoveCalendarFAQ: "Mappe Pro FAQ pagina",
    RemoveCalendarCancellationText: "Politica di cancellazione di Witivio qui",
    RemoveCalendarContact: "Contattateci",
    ClickHere: "Clicca qui",
    PremiumMessage: "Per aggiungere altri luoghi o itinerari vai a Premium",
    AvailableInPremium: "Disponibile in Premium",
    GetAppID: "A proposito",
    GetId: "A proposito",
    Copy: "Copia",
    UniqueId: "Identificatore unico",
    Ok: "Ok",
    Copied: "Copiato !",
    Version: "Versione",
    ToKnowMore: "Per saperne di più.",
    TeamsProApplications: "Applicazione TeamsPro",
    SelectionApplication: "Seleziona un'applicazione",
    ConnectIDLabel: "Identificatore unico",
    ConnectLabelError: "questo campo deve essere un identificatore unico",
    ConnectHowTo: "Come ottenere l'identificatore unico ?",
    ConnectButton: "Collegare",
    Cancel: "Cancella",
    MessagePreventUniqueIds: "Gli identificatori unici devono essere diversi",
    Today: "Oggi",
    Tags: "Tags",
    LabelStartDate: "Inizio",
    LabelEndDate: "Fine",
    LabelTimeZone: "Fuso orario",
    IDNotExists: "Questo id non esiste.",
    NotRepeat: "Non ripetere",
    Workweek: "Dal lunedì al venerdì",
    Weekly: "Settimanale ",
    Daily: "Giornaliero",
    Monthly: "Mensile",
    Yearly: "Annuale",
    Custom: "Personalizza",
    Month: "Mese",
    Week: "Settimana",
    Day: "Giorno",
    Disconnect: "Disconnessione",
    Recurrence: "Ricorrenza",
    Search: "Si prega di cercare utilizzando Nome, Lavoro, Azienda, Dipartimento, Note, Gruppi o Indirizzo per trovare ciò di cui si ha bisogno.",
    Email: "Email",
    Phone: "Telefono",
    MapNotExist: "Questa carta è stata eliminata",
    "Help Center": "Aide",
    "Suggest Feature": "Suggerisci funzione",
    PremiumMessage1: "Stai utilizzando la versione gratuita di Map Pro 🚀",
    PremiumMessage2: "per passare alla versione Premium",
    SuggestFeature: "Suggerisci una funzione",
    HelpCenter: "Aide",
    TagsTitle: "Tags",
    Close: "Chiudi",
    DeleteMessage: "Vuoi eliminare questo {event}",
    Deletion: "Elimina {event}",
    Delete: "Elimina",
    Place: "luogo",
    Trip: "viaggio",
    TitleConnect: "Puoi collegare Contacts Pro o Calendar Pro e visualizzare gli elementi su Map Pro.",
    About: "A proposito",
    UpgradeToPaidPlan: "Aggiornare",
  },
  de: {
    LoginWelcomeApp: "Willkommen bei Karte Pro",
    LoginWelcomeAppSubtitle: "Ihre personalisierte Karte für Microsoft Teams",
    LoginButton: "Los geht's  🚀",
    LoginWelcomeAccessDenied: "Ohne Zustimmung des Administrators können Sie nicht weitermachen.",
    MapPro: "Karte Pro",
    titleMenuPlaces: "Orte",
    titleMenuTrips: "Reisen",
    titleSideBarPlace: "Orte",
    titleSideBarTrip: "Reisen",
    DisplayAllPlaces: "Alle Orte anzeigen?",
    DisplayAllTrips: "Alle Reisen anzeigen?",
    TitleButtonAddPlace: "Einen Ort hinzufügen",
    TitleButtonAddTrip: "Reise hinzufügen",
    TitleModalPlaceAddPlace: "Einen Ort hinzufügen",
    TitleModalPlaceTitle: "Titel",
    TitleModalPlaceAddress: "Adresse",
    TitleModalPlaceDescription: "Beschreibung",
    ButtonCancelModalPlace: "Abbrechen",
    Save: "Speichern",
    TitleModalEditPlace: "Einen Ort bearbeiten",
    TitleModalTripAddTrip: "Reise hinzufügen",
    TitleModalTripType: "Typ *",
    TitleModalTripTitle: "Titel *",
    TitleModalTripFrom: "Von *",
    TitleModalTripTo: "Nach *",
    TitleModalTripDescription: "Beschreibung",
    ButtonCancelModalTrip: "Stornieren",
    TitleModalEditTrip: "Eine Reise bearbeiten",
    MessageRequired: "Pflichtfeld",
    Car: "Auto",
    Train: "Zug",
    Foot: "Zu Fuß",
    Bicycle: "Fahrrad",
    BadAddress: "Ungültige Adresse",
    Confirm: "Validieren",
    HeaderDiscardModal: "Nicht gespeicherte Änderungen",
    ContentDiscardModal: "Möchten Sie Änderungen verwerfen oder mit der Bearbeitung fortfahren ?",
    ButtonCancelDiscard: "Verwerfen",
    ButtonContinueDiscard: "Bearbeitung fortsetzen",
    AddTitle: "Füge einen Titel hinzu",
    TypeAdresse: "Geben Sie hier ein...",
    NoTripFound: "Wir konnten keine gültige Reise finden",
    MessageConfiguration: "Alles ist vorbereitet.",
    MessageConfigurationSubtitle: "Bitte klicken Sie auf Speichern, um fortzufahren...",
    WelcomeMessage: "Willkommen bei Karte Pro",
    WelcomeSubtitle: "Ihre personalisierte Karte für Microsoft Teams",
    AllGoodMessage: "Alles gut, bitte auf Speichern klicken, um fortzufahren",
    LestGoButton: "Los geht's  🚀",
    RemoveMap: "Karte entfernen",
    RemoveMapMessage: "Klicken Sie auf die Schaltfläche Entfernen, um Ihre Karte zu entfernen",
    FreeVersion: "Sie verwenden die kostenlose Version von Karte Pro.",
    RemoveMapsTitle: "Sind Sie sicher, dass Sie diesen Tab löschen möchten?",
    RemoveMapsDescription: "Alle Informationen, die diesem Tab zugeordnet sind, werden dauerhaft entfernt. Sie werden den Inhalt nicht wiederherstellen können.",
    RemoveCalendarTitle: "Lesen Sie vor dem Löschen der Karte Pro: ",
    RemoveCalendarItem1: "1. Wenn Sie Karte Pro löschen, löschen Sie auch alle gespeicherten Daten. Stellen Sie sicher, dass Sie alle notwendigen Informationen abrufen, bevor Sie sie löschen.",
    RemoveCalendarItem2: "2. Sie können eine gelöschte Karte Pro nicht wiederherstellen. Sobald Sie sie gelöscht haben, können wir sie nicht wiederherstellen..",
    RemoveCalendarItem3: "3. Wir werden die Zahlung für diese Karte Pro am Ende der Laufzeit automatisch beenden. Mehr lesen über {removeCalendarCancellation}.",
    RemoveCalendarItem4:
      "4. Wenn Sie die Karte Pro weiterhin nutzen möchten, aber mit der kostenlosen Version, müssen Sie die Karte Pro nicht löschen. Besuchen Sie unsere Seite mit den Kündigungsrichtlinien, um zu erfahren, wie Sie das tun können.",
    RemoveCalendarItem5: "5. Wenn Sie es löschen, weil Karte Pro Probleme hat. Wir empfehlen Ihnen den Besuch unserer {RemoveCalendarFAQ} oder {RemoveCalendarContact} bevor wir fortfahren.",
    RemoveCalendarFAQ: "Karte Pro FAQ Seite",
    RemoveCalendarCancellationText: "Die Widerrufsbelehrung von Witivio hier",
    RemoveCalendarContact: "Kontakt",
    ClickHere: "Hier klicken",
    PremiumMessage: "Um weitere Orte oder Reisen hinzuzufügen, gehen Sie zu Premium",
    AvailableInPremium: "Verfügbar in Premium",
    GetAppID: "Über",
    GetId: "Über",
    Copy: "Kopieren",
    UniqueId: "Eindeutiger Bezeichner",
    Ok: "Ok",
    Copied: "Kopiert !",
    Version: "Version",
    ToKnowMore: "Mehr Informationen.",
    TeamsProApplications: "TeamsPro-Anwendung",
    SelectionApplication: "Wählen Sie eine Anwendung",
    ConnectIDLabel: "Eindeutiger Bezeichner",
    ConnectLabelError: "dieses Feld muss eine ID sein",
    ConnectHowTo: "Wie man die ID erhält ?",
    ConnectButton: "Verbinden Sie",
    Cancel: "Abbrechen",
    MessagePreventUniqueIds: "Die eindeutigen Erkennungszeichen müssen unterschiedlich sein",
    Today: "Heute",
    Tags: "Tags",
    LabelStartDate: "Start",
    LabelEndDate: "Ende",
    LabelTimeZone: "Zeitzone",
    IDNotExists: "Diese ID existiert nicht.",
    NotRepeat: "Keine Wiederholung",
    Workweek: "Von Montag bis Freitag",
    Weekly: "Wöchentlich",
    Daily: "Täglich",
    Monthly: "Monatlich",
    Yearly: "Jährlich",
    Custom: "Benutzerdefiniert",
    Month: "Monat",
    Week: "Woche",
    Day: "Tag",
    Disconnect: "Abmelden",
    Recurrence: "Wiederholung",
    Search: "Bitte suchen Sie anhand von Name, Beruf, Unternehmen, Abteilung, Notizen, Gruppen oder Adresse, um zu finden, was Sie benötigen.",
    Email: "Email",
    Phone: "Telefon",
    MapNotExist: "Diese Karte wurde gelöscht",
    "Help Center": "Hilfezentrum",
    "Suggest Feature": "Funktionalität vorschlagen",
    PremiumMessage1: "Sie verwenden die kostenlose Version von Map Pro 🚀",
    PremiumMessage2: "um auf die Premium-Version zu aktualisieren",
    SuggestFeature: "Funktion vorschlagen",
    HelpCenter: "Hilfezentrum",
    TagsTitle: "Tags",
    About: "Über",
    Close: "Schließen",

    DeleteMessage: "Möchten Sie dieses {event} löschen",
    Deletion: "Löschen {event}",
    Delete: "Löschen",
    Place: "Ort",
    Trip: "Reise",
    TitleConnect: "Sie können Contacts Pro oder Calendar Pro verknüpfen und Elemente auf Map Pro anzeigen.",
    UpgradeToPaidPlan: "Aktualisieren",
  },
  es: {
    LoginWelcomeApp: "Bienvenido a Mapa Pro",
    LoginWelcomeAppSubtitle: "Su mapa personalizado para Microsoft Teams",
    LoginButton: "Vamos  🚀",
    LoginWelcomeAccessDenied: "No puedes continuar sin el consentimiento del administrador.",
    MapPro: "Mapa Pro",
    titleMenuPlaces: "Lugares",
    titleMenuTrips: "Trayectos",
    titleSideBarPlace: "Lugares",
    titleSideBarTrip: "Trayectos",
    DisplayAllPlaces: "Mostrar todos los lugares?",
    DisplayAllTrips: "Mostrar todos los trayectos?",
    TitleButtonAddPlace: "Agregar un lugar",
    TitleButtonAddTrip: "Agregar un trayecto",
    TitleModalPlaceAddPlace: "Agregar un Lugar",
    TitleModalPlaceTitle: "Titulo",
    TitleModalPlaceAddress: "Direcction",
    TitleModalPlaceDescription: "Descripcion",
    ButtonCancelModalPlace: "Cancelar",
    Save: "Guardar",
    TitleModalEditPlace: "Editar un Lugar",
    TitleModalTripAddTrip: "Agregar un Trayecto",
    TitleModalTripType: "Tipo",
    TitleModalTripTitle: "Titulo",
    TitleModalTripFrom: "Desde",
    TitleModalTripTo: "Hacia",
    TitleModalTripDescription: "Descripcion",
    ButtonCancelModalTrip: "Cancelar",
    TitleModalEditTrip: "Editar un Trayecto",
    MessageRequired: "Este campo es obligatorio",
    Car: "Auto",
    Train: "Tren",
    Foot: "Pie",
    Bicycle: "Bicicleta",
    BadAddress: "Dirección inválida",
    Confirm: "Validar",
    HeaderDiscardModal: "Cambios no guardados",
    ContentDiscardModal: "¿ Deseas descartar las modificaciones o continuar con la edición ?",
    ButtonCancelDiscard: "Descartar",
    ButtonContinueDiscard: "Continuar la edición",
    AddTitle: "Añada un titulo",
    TypeAdresse: "Escriba aquí...",
    NoTripFound: "No pudimos encontrar ningún viaje válido",
    MessageConfiguration: "Todo está preparado.",
    MessageConfigurationSubtitle: "Haga clic en guardar para continuar...",
    WelcomeMessage: "Bienvenido a Mapa Pro",
    WelcomeSubtitle: "Su mapa personalizado para Microsoft Teams",
    AllGoodMessage: "Todo bien, por favor hacer clic en guardar para continuar",
    LestGoButton: "Vamos  🚀",
    RemoveMap: "Eliminar el mapa",
    RemoveMapMessage: "Haga clic en el botón de eliminar para eliminar su mapa",
    FreeVersion: "Estás usando la versión gratuita de Mapa Pro.",
    RemoveMapsTitle: "¿Estás seguro de que quieres eliminar esta pestaña?​",
    RemoveMapsDescription: "Toda la información asociada a esta pestaña será eliminada permanentemente. No podrás recuperar el contenido.​",
    RemoveCalendarTitle: "Leer antes de borrar el Mapa Pro: ",
    RemoveCalendarItem1: "1. Al eliminar el Mapa Pro, también se eliminan todos los datos almacenados. Asegúrese de recuperar toda la información necesaria antes de borrarlo.",
    RemoveCalendarItem2: "2. No se puede recuperar un Mapa Pro después de haberlo borrado. Una vez que lo elimine, no podremos restaurarlo.",
    RemoveCalendarItem3: "3. Terminaremos automáticamente el pago de este Mapa Pro al final del plazo. Leer más sobre {removeCalendarCancellation}.",
    RemoveCalendarItem4:
      "4. Si quieres seguir utilizando el Mapa Pro, pero en la versión gratuita, no es necesario que lo elimines. Visita nuestra página de política de cancelación para saber cómo hacerlo.",
    RemoveCalendarItem5: "5. Si lo borras porque el Mapa Pro tiene problemas. Le recomendamos que visite nuestro {RemoveCalendarFAQ} o {RemoveCalendarContact} antes de proceder.",
    RemoveCalendarFAQ: "Mapa Pro FAQ página",
    RemoveCalendarCancellationText: "Política de cancelación de Witivio aquí",
    RemoveCalendarContact: "Contáctanos",
    ClickHere: "Haga clic aquí",
    PremiumMessage: "Para añadir más lugares o trayectos, vaya a Premium",
    AvailableInPremium: "Disponible en Premium",
    GetAppID: "Acerca",
    GetId: "Acerca",
    Copy: "Copiar",
    UniqueId: "Identificador único",
    Ok: "Ok",
    Copied: "Copiado !",
    Version: "Versión",
    ToKnowMore: "Para saber más.",
    TeamsProApplications: "Aplicación TeamsPro",
    SelectionApplication: "Seleccione una aplicación",
    ConnectIDLabel: "Identificador único",
    ConnectLabelError: "este campo debe ser un identificador único",
    ConnectHowTo: "Cómo obtener el identificador único ?",
    ConnectButton: "Conéctese",
    Cancel: "Cancelar",
    MessagePreventUniqueIds: "Los identificadores únicos deben ser diferentes",
    Today: "Hoy",
    Tags: "Tags",
    LabelStartDate: "Inicio",
    LabelEndDate: "Fin",
    LabelTimeZone: "Zona horaria",
    IDNotExists: "Este ID no existe.",
    NotRepeat: "No repetir",
    Workweek: "De Lunes a Viernes",
    Weekly: "Semanalmente",
    Daily: "Diariamente",
    Monthly: "Mensualmente",
    Yearly: "Anualmente",
    Custom: "Personalizar",
    Month: "Mes",
    Week: "Semana",
    Day: "Día",
    Disconnect: "Desconexión",
    Recurrence: "Recurrencia",
    Search: "Por favor, busque utilizando Nombre, Trabajo, Empresa, Departamento, Notas, Grupos o Dirección para encontrar lo que necesita.",
    Email: "Email",
    Phone: "Teléfono",
    MapNotExist: "Esta tarjeta ha sido eliminada",
    "Help Center": "Centro de ayuda",
    "Suggest Feature": "Sugerir función",
    PremiumMessage1: "Estás usando la versión gratuita de Map Pro 🚀",
    PremiumMessage2: "para actualizar a la versión Premium",
    SuggestFeature: "Sugerir una característica",
    HelpCenter: "Centro de ayuda",
    TagsTitle: "Etiquetas",
    About: "Acerca",
    Close: "Cerrar",

    DeleteMessage: "¿Quieres eliminar este {event}",
    Deletion: "Eliminar {event}",
    Delete: "Eliminar",
    Place: "lugar",
    Trip: "viaje",
    TitleConnect: "Puedes vincular Contacts Pro o Calendar Pro y mostrar elementos en Map Pro.",
    UpgradeToPaidPlan: "Actualizar",
  },
  pt: {
    LoginWelcomeApp: "Bem-vindo a Mapa Pro",
    LoginWelcomeAppSubtitle: "O seu mapa personalizado para as equipas da Microsoft",
    LoginButton: "Vamos  🚀",
    LoginWelcomeAccessDenied: "Não se pode continuar sem o consentimento do administrador.",
    MapPro: "Mapa Pro",
    titleMenuPlaces: "Lugares",
    titleMenuTrips: "Viagens",
    titleSideBarPlace: "Lugares",
    titleSideBarTrip: "Viagens",
    DisplayAllPlaces: "Exibir todos os lugares?",
    DisplayAllTrips: "Exibir todas as viagens?",
    TitleButtonAddPlace: "Adicionar um lugar",
    TitleButtonAddTrip: "Adicionar uma viagem",
    TitleModalPlaceAddPlace: "Adicionar um lugar",
    TitleModalPlaceTitle: "Título",
    TitleModalPlaceAddress: "Endereço",
    TitleModalPlaceDescription: "Descrição",
    ButtonCancelModalPlace: "Cancelar",
    Save: "Salve",
    TitleModalEditPlace: "Editar um lugar",
    TitleModalTripAddTrip: "Adicionar uma viagem",
    TitleModalTripType: "Modelo",
    TitleModalTripTitle: "Título",
    TitleModalTripFrom: "A partir de",
    TitleModalTripTo: "Para",
    TitleModalTripDescription: "Descrição",
    ButtonCancelModalTrip: "Cancelar",
    TitleModalEditTrip: "Editar uma viagem",
    MessageRequired: "Este campo é obrigatório",
    Car: "Carro",
    Train: "Trem",
    Foot: "Pé",
    Bicycle: "Bicicleta",
    BadAddress: "Endereço inválido",
    Confirm: "Validar",
    HeaderDiscardModal: "Alterações não guardadas",
    ContentDiscardModal: "Pretende eliminar as edições ou continuar a editar?",
    ButtonCancelDiscard: "Eliminar",
    ButtonContinueDiscard: "Continuar a editar",
    AddTitle: "Adicione um título",
    TypeAdresse: "Digite aqui...",
    NoTripFound: "Não foi possível encontrar nenhuma viagem válida",
    MessageConfiguration: "Tudo está preparado.",
    MessageConfigurationSubtitle: "Por favor clique em guardar para continuar...",
    WelcomeMessage: "Bem-vindo a Mapa Pro",
    WelcomeSubtitle: "O seu mapa personalizado para as equipas da Microsoft",
    AllGoodMessage: "Tudo bem, por favor clique em guardar para continuar",
    LestGoButton: "Vamos  🚀",
    RemoveMap: "Remover mapa",
    RemoveMapMessage: "Clique no botão remover para remover o seu mapa",
    FreeVersion: "Está a utilizar a versão gratuita do Mapa Pro.",
    RemoveMapsTitle: "Você tem certeza de que deseja excluir esta aba?",
    RemoveMapsDescription: "Todas as informações associadas a esta aba serão removidas permanentemente. Você não será capaz de recuperar o conteúdo.",
    RemoveCalendarTitle: "Leia antes de apagar o Mapa Pro: ",
    RemoveCalendarItem1: "1. Ao apagar o Mapa Pro, também se apaga todos os dados armazenados. Certifique-se de que recupera toda a informação necessária antes de a apagar.",
    RemoveCalendarItem2: "2. Não se pode recuperar um Mapa Pro depois de o ter apagado. Uma vez apagado, não poderemos recuperá-lo.",
    RemoveCalendarItem3: "3. Terminaremos automaticamente o pagamento para este Mapa Pro no final do prazo. Leia mais sobre {removeCalendarCancellation}.",
    RemoveCalendarItem4:
      "4. Se quiser continuar a utilizar o Mapa Pro, mas na versão gratuita, não precisa de apagar o Mapa Pro. Visite a nossa página de política de cancelamento para saber como o fazer.",
    RemoveCalendarItem5: "5. Se estiver a apagá-lo porque o Mapa Pro tem problemas. Recomendamos-lhe que visite o nosso {RemoveCalendarFAQ} ou {RemoveCalendarContact} antes de prosseguir.",
    RemoveCalendarFAQ: "Mapa Pro FAQ página",
    RemoveCalendarCancellationText: "A política de cancelamento do Witivio aqui",
    RemoveCalendarContact: "Contacte-nos",
    ClickHere: "Clique aqui",
    PremiumMessage: "Para adicionar mais lugares ou viagens vá para Premium",
    AvailableInPremium: "Disponível em Premium",
    GetAppID: "Acerca",
    GetId: "Acerca",
    Copy: "Cópia",
    UniqueId: "Identificador único",
    Ok: "Ok",
    Copied: "Copiado !",
    Version: "Versão",
    ToKnowMore: "Para saber mais.",
    TeamsProApplications: "Aplicação TeamsPro",
    SelectionApplication: "Seleccione uma aplicação",
    ConnectIDLabel: "Identificador único",
    ConnectLabelError: "este campo deve ser um identificador único",
    ConnectHowTo: "Como obter a identificador único ?",
    ConnectButton: "Ligar",
    Cancel: "Cancelar",
    MessagePreventUniqueIds: "Os identificadores únicos devem ser diferentes",
    Today: "Hoje",
    Tags: "Tags",
    LabelStartDate: "Início",
    LabelEndDate: "Fim",
    LabelTimeZone: "Fuso horário",
    IDNotExists: "Este id não existe.",
    NotRepeat: "Não repete",
    Workweek: "De segunda-feira a sexta-feira",
    Weekly: "Semanalmente",
    Daily: "Diariamente",
    Monthly: "Por mês",
    Yearly: "Anual",
    Custom: "Personalizado",
    Month: "Mês",
    Week: "Semana",
    Day: "Dia",
    Disconnect: "Desconexão",
    Recurrence: "Recorrência",
    Search: "Por favor, procure usando Nome, Cargo, Empresa, Departamento, Notas, Grupos ou Endereço para encontrar o que você precisa.",
    Email: "Email",
    Phone: "Telefone",
    MapNotExist: "Este cartão foi eliminado",
    "Help Center": "Centro de ajuda",
    "Suggest Feature": "Sugerir funcionalidade",
    PremiumMessage1: "Você está usando a versão gratuita do Map Pro 🚀",
    PremiumMessage2: "para atualizar para a versão Premium",
    SuggestFeature: "Sugerir uma Funcionalidade",
    HelpCenter: "Centro de Ajuda",
    TagsTitle: "Etiquetas",
    About: "Acerca",
    Close: "Fechar",

    DeleteMessage: "Você quer deletar este {event}",
    Deletion: "Deletar {event}",
    Delete: "Deletar",
    Place: "lugar",
    Trip: "viagem",
    TitleConnect: "Você pode vincular Contacts Pro ou Calendar Pro e exibir itens no Map Pro.",
    UpgradeToPaidPlan: "Atualizar",
  },
};
