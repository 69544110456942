import { useMemo } from "react";
import { useQuery } from "react-query";
import { IsTeamsApp } from "../AuthService/AuthService";
import * as microsoftTeams from "@microsoft/teams-js";
const isAppFromTeams = async () => new Promise(async (resolve) => {
    microsoftTeams.app.initialize().then(() => resolve(true)).catch(() => resolve(false));
});
export const useMicrosoftTeamsCache = () => {
    const { data: teamsApp } = useQuery(IsTeamsApp, isAppFromTeams, {
        staleTime: Infinity,
    });
    return useMemo(() => ({ teamsApp, }), [teamsApp,]);
};
