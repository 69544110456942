import { jsx as _jsx } from "react/jsx-runtime";
import { Dropdown, Flex } from "@fluentui/react-northstar";
import React from "react";
import { useSearchDropdown } from "./SearchDropdown.logic";
export const SearchDropdown = (props) => {
    const logic = useSearchDropdown(props);
    const override = {
        placeholder: logic.valueRef,
        onSearchQueryChange: logic.changeSearchQuery,
        onChange: logic.onChange,
        search: true,
        searchQuery: logic.value,
    };
    return (_jsx(Flex.Item, { grow: props.options?.fill, children: _jsx(Dropdown, { ...props.FUI, ...override }) }));
};
