import { IAppDispatch } from "calendarShared";
import { setModalWithData, useMsTeamsSelector, useTranslate } from "front";
import { useDispatch } from "react-redux";
import { translations } from "../../../translations";
import { IDialogs } from "../../../types/IDialogs/IDialogs";
import { usePlaceLimitation } from "../../../hooks/usePlaceLimitation/usePlaceLimitation";
import { IView } from "../../../types/IView/IView";
import { useTripLimitation } from "../../../hooks/useTripLimitation/useTripLimitation";

export const useSideViewBottom = (props: { view: IView; content: string; toggleMode: (mode: 0 | 1) => void; mode: number; onAction: () => void; isDisabled: boolean }) => {
  const t = useTranslate(translations);

  const { isOnMobile } = useMsTeamsSelector("isOnMobile");
  const dispatchCtx = useDispatch<IAppDispatch>();
  const { canAddPlace } = usePlaceLimitation();
  const { canAddTrip } = useTripLimitation();

  const handleClose = () => dispatchCtx(setModalWithData({ isOpen: IDialogs.VALIDATE_CANCEL, data: { confirmClose } }));

  const canAdd = () => {
    if (props.view === IView.PLACES) return canAddPlace();
    if (props.view === IView.TRIPS) return canAddTrip();
    return false;
  };

  const confirmClose = () => props.toggleMode(0);
  return { t, isOnMobile, handleClose, canAdd };
};
