import React from "react";
import { useGoogleMap } from "./GoogleMap.logic";
import { Flex } from "@fluentui/react-northstar";
import { IConnectedApp } from "../../types/IConnectedApp/IConnectedApp";
import AlphabetPicker from "../../components/AlphabetPicker/AlphabetPicker";
import Pagination from "../../components/Pagination/Pagination";

const GoogleMap = () => {
  const logic = useGoogleMap();

  return (
    <Flex fill>
      {logic.connectedApp === IConnectedApp.CONTACTS_PRO && (
        <div style={{ padding: "0 .5rem 0 .25rem" }}>
          <AlphabetPicker />
        </div>
      )}
      <Flex fill column>
        <div ref={logic.mapRef} id={"google-map-container"} style={{ height: logic.calculateMapHeight }} />
        {logic.connectedApp === IConnectedApp.CONTACTS_PRO && (
          <Flex hAlign="end" style={{ padding: ".5rem" }}>
            <Pagination
              page={logic.pagination.currentPage}
              take={logic.pagination.take}
              total={logic.total ?? 0}
              changePage={logic.changePage}
            />
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default GoogleMap;
