import { LinkIcon } from "@fluentui/react-northstar";
import { generateGUID, openModal, useMsTeamsSelector, useTranslate, usePremiumSelector } from "front";
import { useCallback, useEffect, useMemo, useReducer, useState } from "react";
import { translations } from "../../translations";
import { useDispatch, useSelector } from "react-redux";
import { IAppDispatch, ReduxStoreState } from "redux/store";
import { IDialogs } from "../../types/IDialogs/IDialogs";
import { reducer, initialState, IAction } from "./Navbar.reducer";
import { ISideView } from "../../types/ISideView/ISideView";
import { useConnectedAppSelector } from "../../redux/reducers/connectedApp.reducer";
import { useForm } from "react-hook-form";
import { queryClient } from "../../index";
import { IQueryKey } from "../../types/IQueryKey/IQueryKey";
import { setPagination } from "../../redux/reducers/PaginationReducer";
import React from "react";
import { CrownIcon } from "icons/Crown";

export const useNavbar = () => {
  const t = useTranslate(translations);
  const dispatchCtx = useDispatch<IAppDispatch>();

  const { connectedApp } = useConnectedAppSelector("connectedApp");
  const { isOnMobile } = useMsTeamsSelector("isOnMobile", "locale");
  const { isPremium, isPlatinum, loaded } = usePremiumSelector("isPremium", "isPlatinum", "loaded");

  // State
  const [state, dispatch] = useReducer(reducer, { ...initialState });

  const openUpgradeDialog = useCallback(() => dispatchCtx(openModal(IDialogs.UPGRADE)), []);
  const openConnectAppDialog = useCallback(() => dispatchCtx(openModal(IDialogs.CONNECT_APP)), []);

  const handlePlaces = () => {
    let sideView: ISideView | undefined = ISideView.PLACES;
    if (state.sideView === ISideView.PLACES) {
      sideView = undefined;
    }
    dispatch({ type: IAction.TOGGLE_SIDE_VIEW, payload: { ...state, sideView } });
  };

  const handleTrips = () => {
    let sideView: ISideView | undefined = ISideView.TRIPS;
    if (state.sideView === ISideView.TRIPS) {
      sideView = undefined;
    }
    dispatch({ type: IAction.TOGGLE_SIDE_VIEW, payload: { ...state, sideView } });
  };

  const handleCloseSideView = () => {
    dispatch({ type: IAction.TOGGLE_SIDE_VIEW, payload: { ...state, sideView: undefined } });
  };

  const [moreMenu, setMoreMenu] = useState([
    {
      key: generateGUID(),
      icon: <LinkIcon outline />,
      content: t("ConnectButton"),
      onClick: openConnectAppDialog,
    },
  ]);

  useEffect(() => {
    let tmpMoreMenu = [...moreMenu];

    if (loaded && !isPlatinum && !moreMenu.some((m) => m.key == "Upgrade")) {
      tmpMoreMenu.push({
        key: "Upgrade",
        content: t("UpgradeToPaidPlan"),
        icon: <CrownIcon permissions={isPremium ? "platinium" : "premium"} />,
        onClick: openUpgradeDialog,
      });

      setMoreMenu(tmpMoreMenu);
    }
  }, [isPremium, isPlatinum, loaded]);

  const menus = useMemo(
    () => ({
      moreMenu,
    }),
    [moreMenu]
  );

  // ===== Relative to Contact Pro =====

  const pagination = useSelector((s: ReduxStoreState) => s.pagination);

  const { register, watch } = useForm<{ search: string }>({
    mode: "onChange",
    defaultValues: {
      search: "",
    },
  });

  useEffect(() => {
    dispatchCtx(setPagination({ ...pagination, skip: 0, currentPage: 1, searchPattern: watch("search") }));
    queryClient.invalidateQueries([IQueryKey.CONTACT]);
  }, [watch("search")]);

  return { ...state, t, menus, handlePlaces, handleTrips, handleCloseSideView, connectedApp, register, isOnMobile };
};
