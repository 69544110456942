import { useEffect, useRef, useState } from "react";
export const useGoogleTravel = (props) => {
    // State
    const [isLoading, setIsLoading] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const [directionsRenderer] = useState(new google.maps.DirectionsRenderer());
    useEffect(() => {
        if (!props.map)
            return;
        directionsRenderer.setMap(props.map);
        directionsRenderer.setOptions({ suppressInfoWindows: true });
    }, [props.map]);
    // Ref
    const isEnabled = useRef(true);
    const directionsService = new google.maps.DirectionsService();
    useEffect(() => {
        setIsLoading(true);
        directionsService.route({
            origin: props.from,
            destination: props.to,
            travelMode: props.travelMode,
        }, (result, status) => {
            if (status === google.maps.DirectionsStatus.OK && result) {
                isEnabled.current && directionsRenderer.setDirections(result);
                setIsValid(true);
            }
            else {
                setIsValid(false);
            }
        });
        setIsLoading(false);
    }, [props.from, props.to, props.travelMode]);
    const toggleIsEnabled = (bool) => (isEnabled.current = bool);
    const clearTrip = () => directionsRenderer.setDirections({ routes: [] });
    return {
        isValid,
        isLoading,
        toggleIsEnabled,
        clearTrip,
    };
};
