import { generateGUID } from "front";
import React from "react";
import { useDialogs } from "./Dialogs.logic";

const Dialogs = () => {
  const logic = useDialogs();

  if (!logic.isOpen) return;

  return (
    <div>
      {logic.dialogs
        .filter((d) => d.show)
        .map((d) => (
          <div key={generateGUID()}>{d.component}</div>
        ))}
    </div>
  );
};

export default Dialogs;
