/**
 * Check if a user has premium access
 * @param tenantId tenant id
 * @param userId user id
 * @param appId app id
 */
export const getAdminCenterData = (tenantId, userId, appId, url) => {
    return new Promise((resolve, reject) => {
        const data = new URLSearchParams();
        data.append("tenantId", tenantId);
        data.append("userId", userId);
        data.append("appId", appId);
        fetch(url + "/teams-app/data", {
            method: "POST",
            body: data,
        })
            .then((res) => {
            return res.json();
        })
            .then((json) => {
            resolve(json);
        })
            .catch(() => {
            reject("Can't fetch admin center data");
        });
    });
};
/**
 * Get Url to show app plans
 * @param user user
 * @param appId appId
 * @param subscriptionsApiBaseUrl witivio subscriptions api base url, in case we want to open admin center in an iframe from subscriptions
 */
export const getAppPlansUrl = (user, appId, subscriptionsApiBaseUrl, url) => {
    if (subscriptionsApiBaseUrl) {
        const baseUrl = subscriptionsApiBaseUrl + "/api/admin-center/";
        return baseUrl + user.TenantId + "/" + user.AadId + "/show-plans/" + appId + "?fromTeams=true&userName=" + encodeURI(user.DisplayName);
    }
    return url + "/services/app/" + appId;
};
// WS : Witvio subscription the app
export const getProductUrlByWS = (user, licencePlanId, subscriptionsApiBaseUrl, url) => {
    if (subscriptionsApiBaseUrl) {
        const baseUrl = !!subscriptionsApiBaseUrl ? subscriptionsApiBaseUrl + "/api/admin-center/" : url + "/teams-app/";
        return baseUrl + "products/" + user.AadId + "/" + licencePlanId;
    }
    return url + "/products/" + licencePlanId;
};
export const getMoreAppsUrl = (appId, subscriptionsApiBaseUrl, url) => {
    if (subscriptionsApiBaseUrl) {
        const baseUrl = subscriptionsApiBaseUrl + "/api/admin-center/";
        return baseUrl + "more-apps/" + appId;
    }
    return url + "/teams-app/more-apps?appId=" + appId + "&fromTeams=true";
};
/**
 * Get url to show product
 */
export const getProductUrl = (licencePlanId, showLicences = false, url) => {
    return url + "/products/" + licencePlanId + (showLicences ? "?tab=licences" : "");
};
/**
 * Create a new access request
 * @param user user
 * @param appId app id
 * @param reason reason
 * @param locale string
 */
export const createAccessRequest = (user, appId, reason, locale, url) => {
    return new Promise((resolve, reject) => {
        const data = new URLSearchParams();
        data.append("user", JSON.stringify(user));
        data.append("appId", appId);
        data.append("reason", reason.replace(/['"]/g, " "));
        data.append("locale", locale);
        fetch(url + "/teams-app/access-request", {
            method: "POST",
            body: data,
        })
            .then((res) => {
            if (res.ok)
                resolve();
            else
                throw new Error();
        })
            .catch(() => {
            reject("Can't create access request");
        });
    });
};
export const createAccessRequestToASpecificSubscription = (user, appId, reason, locale, superAdmin, url) => {
    return new Promise((resolve, reject) => {
        const data = new URLSearchParams();
        data.append("user", JSON.stringify(user));
        data.append("appId", appId);
        data.append("reason", reason.replace(/['"]/g, " "));
        data.append("locale", locale);
        data.append("superAdmin", JSON.stringify(superAdmin));
        fetch(url + "/teams-app/access-request/specific-subscription", {
            method: "POST",
            body: data,
        })
            .then((res) => {
            if (res.ok)
                resolve();
            else
                throw new Error();
        })
            .catch(() => {
            reject("Can't create access request to a specific subscription");
        });
    });
};
