import { useEffect, useState } from "react";
import { useScopesService } from "../services/ScopesService/ScopesService";
import { useDispatch } from "react-redux";
import { initializeAuth, useAuthSelector } from "../redux/reducers/AuthReducer/AuthReducer";
import { useMicrosoftTeamsCache } from "../services/CacheService/microsoft-teams.cache";
import { clearListeners, initializeMSTeams, setListeners, useMsTeamsSelector } from "../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { fetchCurrentUser, useUserSelector } from "../redux/reducers/UserReducer/UserReducer";
import { useConfigurationCache } from "../services/CacheService/configurationCache";
import { setConfiguration } from "../redux/reducers/ConfigurationReducer/ConfigurationReducer";
import { initializeScope } from "../redux/reducers/ScopesReducer/ScopesReducer";
import { getPremium } from "../redux/reducers/PremiumReducer/PremiumReducer";
import { AxiosConfig } from "../apis/AxiosConfig/AxiosConfig";
import { getHomeToken } from "../utils/utils";
import { usePermInitializer } from "./usePermInitializer";
const isFromWidget = () => {
    return window.location.href.includes("widget");
};
export const useAppInitializer = (props) => {
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    const auth = useAuthSelector("accessToken", "isTeamsApp");
    const teams = useMsTeamsSelector("locale", "loaded", "isOnMobile", "themeClass", "tenantId", "userId", "newTeams");
    const user = useUserSelector("currentUser");
    const microsftTeamsCache = useMicrosoftTeamsCache();
    const { config } = useConfigurationCache();
    const scopesService = useScopesService();
    usePermInitializer();
    useEffect(() => {
        dispatch(setListeners());
        return () => {
            dispatch(clearListeners());
        };
    }, []);
    useEffect(() => {
        if (!config)
            return; // Throw Critical Error
        dispatch(setConfiguration({ data: config }));
        const getHomeProToken = async () => {
            const accessToken = await getHomeToken();
            AxiosConfig.setAxiosRequestMiddleware(accessToken ?? "");
            setLoaded(true);
        };
        if (!isFromWidget())
            return;
        getHomeProToken();
    }, [config]);
    useEffect(function onConfigurationLoaded() {
        if (!config?.clientId || microsftTeamsCache.teamsApp == undefined)
            return;
        dispatch(initializeAuth({ isTeamsApp: microsftTeamsCache.teamsApp }));
    }, [config?.clientId, microsftTeamsCache.teamsApp]);
    useEffect(function onAccessTokenRetrieved() {
        if (!auth.accessToken || microsftTeamsCache.teamsApp == undefined)
            return;
        dispatch(initializeMSTeams({ isTeamsApp: microsftTeamsCache.teamsApp, accessToken: auth.accessToken }));
    }, [auth.accessToken, microsftTeamsCache.teamsApp]);
    useEffect(function onMsTeamsInitialized() {
        if (!teams.loaded)
            return;
        if (teams.newTeams) {
            const body = document.querySelector("body");
            body.setAttribute("class", "teamsModern");
        }
        const scopesServiceConfiguration = {
            clientId: config?.clientId,
            apiBaseUrl: window.location.origin,
            scopes: config ? config?.graphScopes.split(" ") : [],
            inTeams: auth.isTeamsApp,
            locale: teams.locale,
            isWidget: false,
            isOnMobile: teams.isOnMobile,
            theme: teams.themeClass,
            tenantId: teams.tenantId,
        };
        dispatch(initializeScope({ initArgs: scopesServiceConfiguration, askGraphToken: props.needInitializegraph }));
        dispatch(getPremium({ tid: teams.tenantId, userId: teams.userId }));
    }, [teams.loaded]);
    useEffect(function onScopesServiceInitialized() {
        if (!scopesService.data.loaded)
            return;
        if (props.needInitializegraph) {
            dispatch(fetchCurrentUser(teams.userId));
        }
        else {
            setLoaded(true);
        }
    }, [scopesService.data.loaded]);
    useEffect(() => {
        if (!scopesService.data.needToConsent)
            return;
        setLoaded(false);
    }, [scopesService.data.needToConsent]);
    useEffect(function onCurrentUserFetched() {
        if (!user.currentUser)
            return;
        setLoaded(true);
    }, [user.currentUser]);
    return { loaded };
};
