import { AxiosConfig } from "../AxiosConfig/AxiosConfig";
const getGraphToken = (scopes) => new Promise(async (resolve) => {
    try {
        const encodeQuery = encodeURIComponent(scopes);
        const response = await AxiosConfig.instance.get("/graphtoken?scopesStr=" + encodeQuery);
        return resolve(response.data);
    }
    catch (_) {
        return resolve(null);
    }
});
const saveToken = () => new Promise(async (resolve) => {
    try {
        await AxiosConfig.instance.get("/StoreToken");
        return resolve();
    }
    catch (_) {
        console.error("Can't save token");
        return resolve();
    }
});
export const TokenApi = {
    saveToken,
    getGraphToken,
};
