import { useMsTeamsSelector, useTranslate } from "front";
import React, { useEffect } from "react";
import { translations } from "../../translations";
import * as microsoftTeams from "@microsoft/teams-js";
import { IMap } from "../../types/IMap/IMap";
import { createMap } from "../../apis/mapsActions/mapsActions";

export const useConfigure = () => {
  const t = useTranslate(translations);
  const { tenantId, channelId, userName } = useMsTeamsSelector("tenantId", "channelId", "userName");

  useEffect(() => {
    microsoftTeams.pages.config.setValidityState(true);
    microsoftTeams.pages.config.registerOnSaveHandler(onSubmit);
  }, []);

  const createNewMap = async () => {
    const map: IMap = {
      tenantId: tenantId,
      channelId: channelId,
      createdBy: userName,
      createdByObjectId: undefined, // Should be different
    };
    const result = await createMap(map);
    if (!result.id) throw new Error("Can't get created map id");
    return result.id;
  };

  const onSubmit = async (saveEvent: microsoftTeams.pages.config.SaveEvent): Promise<void> => {
    try {
      const mapId = await createNewMap();
      await microsoftTeams.pages.config.setConfig({
        contentUrl: `https://${window.location.host}/map/${mapId}`,
        removeUrl: `https://${window.location.host}/remove?id=${mapId}`,
        entityId: mapId,
        suggestedDisplayName: t("MapPro"),
      });
      saveEvent.notifySuccess();
    } catch (error) {}
  };

  return { t };
};
