import React from "react";

const CarIcon = () => {
  return (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 6L3.5 1.5H14.5L16 6M14.5 11C14.1022 11 13.7206 10.842 13.4393 10.5607C13.158 10.2794 13 9.89782 13 9.5C13 9.10218 13.158 8.72064 13.4393 8.43934C13.7206 8.15804 14.1022 8 14.5 8C14.8978 8 15.2794 8.15804 15.5607 8.43934C15.842 8.72064 16 9.10218 16 9.5C16 9.89782 15.842 10.2794 15.5607 10.5607C15.2794 10.842 14.8978 11 14.5 11ZM3.5 11C3.10218 11 2.72064 10.842 2.43934 10.5607C2.15804 10.2794 2 9.89782 2 9.5C2 9.10218 2.15804 8.72064 2.43934 8.43934C2.72064 8.15804 3.10218 8 3.5 8C3.89782 8 4.27936 8.15804 4.56066 8.43934C4.84196 8.72064 5 9.10218 5 9.5C5 9.89782 4.84196 10.2794 4.56066 10.5607C4.27936 10.842 3.89782 11 3.5 11ZM15.92 1C15.72 0.42 15.16 0 14.5 0H3.5C2.84 0 2.28 0.42 2.08 1L0 7V15C0 15.2652 0.105357 15.5196 0.292893 15.7071C0.48043 15.8946 0.734784 16 1 16H2C2.26522 16 2.51957 15.8946 2.70711 15.7071C2.89464 15.5196 3 15.2652 3 15V14H15V15C15 15.2652 15.1054 15.5196 15.2929 15.7071C15.4804 15.8946 15.7348 16 16 16H17C17.2652 16 17.5196 15.8946 17.7071 15.7071C17.8946 15.5196 18 15.2652 18 15V7L15.92 1Z"
        fill="black"
      />
    </svg>
  );
};

export default CarIcon;
