import { useState } from "react";
import { usePremiumSelector } from "../../../redux/reducers/PremiumReducer/PremiumReducer";
import { useConfigurationSelector } from "../../../redux/reducers/ConfigurationReducer/ConfigurationReducer";
import { createAccessRequestToASpecificSubscription, getProductUrlByWS } from "../../../apis/AdminCenter/adminCenterApi";
import { useMsTeamsSelector } from "../../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer";
import { translations } from "../../../translations";
import { useTranslate } from "../../../hook/useTranslate";
import { useLogic } from "../../Premium/UpgradePremium.logic";
import { appSourceBaseUrl } from "./UpgradeDialog";
import { useAppConfigurationSelector } from "../../../redux/reducers/AppConfigurationReducer/AppConfigurationReducer";
import { IframeManager } from "../../../utils/IframeManager";
import { configIframe } from "../../../const/configIframe";
export const useUpgradeLogic = (props) => {
    const upgradePremiumLogic = useLogic(props);
    const { locale, isOnMobile } = useMsTeamsSelector("locale", "isOnMobile");
    const { data: config } = useConfigurationSelector("data");
    const [superAdminIdToRequest, setSuperAdminToRequest] = useState();
    const { adminCenterData } = usePremiumSelector("adminCenterData");
    const { from } = useAppConfigurationSelector("from");
    const t = useTranslate(translations);
    const showPaymentPage = () => {
        upgradePremiumLogic.setState((prev) => ({ ...prev, isPaymentOpen: true }));
        if (config.appSourceOfferId != undefined && config.appSourceOfferId.trim() != "") {
            upgradePremiumLogic.setState((prev) => ({ ...prev, isPaymentOpen: false, showPremiumActivatedContent: false, isClosable: false, appSourceOpened: true }));
            setTimeout(() => {
                upgradePremiumLogic.setState((prev) => ({ ...prev, isClosable: true }));
                window.open(appSourceBaseUrl + locale + "/product/web-apps/" + config.appSourceOfferId, "_blank");
            }, 4000);
        }
        const dataFunction = (data) => {
            if (data.err && !data.result) {
                upgradePremiumLogic.setState((prev) => ({ ...prev, isPaymentOpen: false, showPremiumActivatedContent: false }));
                props.appInsight?.trackEvent({
                    name: configIframe.Upgrade.trackingIds.cancel,
                });
            }
            else {
                upgradePremiumLogic.setLicencePlanId(data.result);
                upgradePremiumLogic.setState((prev) => ({ ...prev, isPaymentOpen: false, showPremiumActivatedContent: true }));
                props.appInsight?.trackEvent({ name: configIframe.Upgrade.trackingIds.validate });
            }
        };
        IframeManager(configIframe.Upgrade, isOnMobile, props.appInsight, from, getProductUrlByWS(upgradePremiumLogic.user, upgradePremiumLogic.licencePlanId, config.apiSubscription), getProductUrlByWS(upgradePremiumLogic.user, upgradePremiumLogic.licencePlanId, undefined, config.adminCenterBaseUrl), dataFunction);
    };
    const updateSuperAdminToRequest = (event, data) => {
        if (!data)
            return null;
        const selectedValue = data.value.value;
        if (!selectedValue)
            return null;
        setSuperAdminToRequest(adminCenterData.tenantSuperAdmins?.find(tsa => tsa.aadId === selectedValue));
    };
    const sendAccessRequest = async () => {
        upgradePremiumLogic.setState((prev) => ({ ...prev, sendingAccessRequest: true }));
        await createAccessRequestToASpecificSubscription(upgradePremiumLogic.user, props.appId, upgradePremiumLogic.state.requestAccessMessage, locale, superAdminIdToRequest, config.adminCenterBaseUrl);
        upgradePremiumLogic.setState((prev) => ({ ...prev, sendingAccessRequest: false, showRequestAccessDialog: false, requestAccessMessage: "", isRequestSent: true }));
    };
    return {
        closeDialog: upgradePremiumLogic.closeDialog,
        state: upgradePremiumLogic.state,
        showPaymentPageForSuperAdmin: upgradePremiumLogic.showPaymentPage,
        licencePlanId: upgradePremiumLogic.licencePlanId,
        setState: upgradePremiumLogic.setState,
        sendAccessRequest: sendAccessRequest,
        showPaymentPage,
        setSuperAdminToRequest,
        updateSuperAdminToRequest,
        user: upgradePremiumLogic.user
    };
};
