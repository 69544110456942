import { jsx as _jsx } from "react/jsx-runtime";
import { v4 as uuidv4 } from "uuid";
import { ICampaignFrequency } from "../../../interfaces/ICampaignFrequency";
export const buildNews = (news, campaignId) => ({ key: uuidv4(), id: campaignId, content: _jsx("div", { style: { width: "100%" }, dangerouslySetInnerHTML: { __html: news.content } }) });
export const onBuildNews = (news, campaignId, objectToFill) => {
    news.forEach((cn) => {
        const newNews = buildNews(cn, campaignId);
        objectToFill.push(newNews);
    });
};
export const isFrequencyIsReached = (frequency, lastShow) => {
    if (frequency === ICampaignFrequency.UNIQUE)
        return false;
    const now = Date.now();
    const diff = now - lastShow;
    const map = {
        [ICampaignFrequency.UNIQUE]: 0,
        [ICampaignFrequency.EVERY_DAY]: 86400000,
        [ICampaignFrequency.EVERY_72H]: 259200000,
        [ICampaignFrequency.EVERY_WEEK]: 604800000, // 1 Week in milliseconds,
    };
    let maxDiff = map[frequency];
    return diff >= maxDiff;
};
