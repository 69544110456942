import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { Alert, CloseIcon, ExclamationTriangleIcon } from "@fluentui/react-northstar";
import { memo, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { removeErrorMessage, useErrorSelector } from "../../redux/reducers/ErrorReducer/ErrorReducer";
export const ErrorBanner = memo(() => {
    const dispatch = useDispatch();
    const { messages } = useErrorSelector("messages");
    // Refs
    const dataRef = useRef([]);
    useEffect(() => {
        hasNewError();
    }, [messages]);
    const hasNewError = () => {
        messages.forEach((message) => {
            if (dataRef.current.includes(message))
                return;
            dataRef.current.push(message.id);
            setTimeout(() => {
                deleteError(message.id);
            }, 5000);
        });
    };
    const deleteError = (id) => {
        dataRef.current = dataRef.current.filter((item) => item !== id);
        dispatch(removeErrorMessage(id));
    };
    return (_jsx(_Fragment, { children: messages.map((err) => (_jsx(Alert, { header: err.message, visible: true, danger: true, icon: _jsx(ExclamationTriangleIcon, {}), actions: [
                { icon: _jsx(CloseIcon, {}), title: "Close", onClick: () => deleteError(err.id), text: true, iconOnly: true },
            ] }, err.id))) }));
});
