import { useTranslate } from "front";
import { useMapCache } from "../../hooks/useMapCache/useMapCache";
import { translations } from "../../translations";
import * as microsoftTeams from "@microsoft/teams-js";
import { useEffect } from "react";
import { deleteMap } from "../../apis/mapsActions/mapsActions";

export const useRemove = () => {
  const t = useTranslate(translations);
  const { queryMap } = useMapCache();

  useEffect(() => {
    if (!queryMap.data.id) return;
    microsoftTeams.pages.config.setValidityState(true);
    microsoftTeams.pages.config.registerOnRemoveHandler(onSubmit);
  }, [queryMap.data]);

  const onSubmit = async (removeEvent: microsoftTeams.pages.config.RemoveEvent): Promise<void> => {
    try {
      const appId = queryMap.data.id;
      if (!appId) throw new Error("No map Id found");
      await deleteMap({ appId });
      removeEvent.notifySuccess();
    } catch (error) {
      removeEvent.notifyFailure();
    }
  };

  return { t, isLoading: queryMap.isLoading };
};
