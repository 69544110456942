import { useState, useRef, useEffect, useCallback } from "react";
export const useSearchDropdown = (props) => {
    // State
    const [_, setRender] = useState([]);
    const [value, setValue] = useState("");
    // Ref
    const valueRef = useRef("");
    useEffect(() => {
        initValue();
    }, [props.options?.defaultValue]);
    const initValue = useCallback(() => {
        if (!props.options?.defaultValue)
            return;
        valueRef.current = props.options?.defaultValue;
        setRender([]);
    }, [props.options?.defaultValue]);
    const changeSearchQuery = (_, data) => setValue(data.searchQuery);
    const onChange = (event, data) => {
        props.FUI.onChange(event, data);
        valueRef.current = data.value?.header;
        if (props.options?.resetValue)
            setValue("");
    };
    return { changeSearchQuery, value, onChange, valueRef: valueRef.current };
};
