import { AppViewData } from "../interfaces/AppView/AppViewData";
import { AppView } from "../interfaces/AppView/AppView";
import Configure from "../views/Configure/Configure";
import Map from "../views/Map/Map";
import Remove from "../views/Remove/Remove";
import Widget from "../views/Widget/Widget";
import { WidgetConfiguration } from "../views/WidgetConfiguration/WidgetConfiguration";

const HomeView: AppViewData = {
  id: AppView.Map,
  path: "/map/:id",
  titleId: "Map",
  element: Map,
};

const ConfigureView: AppViewData = {
  id: AppView.Configure,
  path: "/configure",
  titleId: "Configuration",
  element: Configure,
};

const RemoveView: AppViewData = {
  id: AppView.Remove,
  path: "/remove",
  titleId: "Remove",
  element: Remove,
};

const WidgetView: AppViewData = {
  id: AppView.Widget,
  path: "/widget",
  titleId: "Widget",
  element: Widget,
};

const WidgetConfigurationView: AppViewData = {
  id: AppView.WidgetConfiguration,
  path: "/widget/configure",
  titleId: "Widget Configuration",
  element: WidgetConfiguration,
};

export const AppViews: Record<AppView, AppViewData> = {
  [AppView.Map]: HomeView,
  [AppView.Configure]: ConfigureView,
  [AppView.Remove]: RemoveView,
  [AppView.Widget]: WidgetView,
  [AppView.WidgetConfiguration]: WidgetConfigurationView,
};
