export const translations = {
    locale: "en",
    get: (id, parameters) => {
        if (!id)
            return "";
        const object = translations[translations.locale] ?? translations.en;
        let translation = object[id] ?? translations.en[id] ?? "";
        if (!translation) {
            console.error("Missing translation for '" + id + "'");
            translation = id;
        }
        if (parameters) {
            for (const param in parameters)
                translation = translation.replaceAll("{" + param + "}", parameters[param] + "");
        }
        return translation;
    },
    en: {},
    fr: {},
    de: {},
    it: {},
    es: {},
    pt: {}
};
