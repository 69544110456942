import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AUTH_INIT, AUTH_LOGIN, AUTH_SIGN_OUT, REFRESH_TOKEN } from "./AuthReducer.interfaces";
import { AxiosConfig } from "../../../apis/AxiosConfig/AxiosConfig";
import { ErrorModule } from "../../../components/ErrorBoundary/ErrorBoundary";
import { getAccessToken, initMsalInstance } from "../../../services/AuthService/AuthService";
import { setScope } from "../ScopesReducer/ScopesReducer";
import { getSliceSelector } from "../../utils";
const initialState = {
    inIframe: window.self !== window.top,
    accessToken: undefined,
    isTeamsApp: true,
    msalInstance: undefined,
    accessTokenInterval: undefined,
    clientId: undefined,
};
const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setAuth: (state, action) => {
            Object.assign(state, action.payload);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(refreshAccessToken.fulfilled, (state, action) => {
            if (!action.payload)
                throw new Error("Can't get current user profile");
            Object.assign(state, action.payload);
        });
    },
});
export const initializeAuth = createAsyncThunk(AUTH_INIT, async (data, { getState, dispatch }) => {
    AxiosConfig.setAxiosConfig(dispatch);
    dispatch(refreshAccessToken({ needRefresh: false, isTeamsApp: data.isTeamsApp }));
});
export const refreshAccessToken = createAsyncThunk(REFRESH_TOKEN, async (data, { getState, dispatch }) => {
    const state = getState();
    const config = state.configuration.data;
    const auth = state.auth;
    const msalInstance = !data.needRefresh ? initMsalInstance(config.clientId, auth.inIframe) : auth.msalInstance;
    const isTeamsApp = !data.needRefresh ? data.isTeamsApp : auth.isTeamsApp;
    const accessToken = await getAccessToken(config.clientId, isTeamsApp, msalInstance, auth.inIframe);
    AxiosConfig.setAxiosRequestMiddleware(accessToken?.token ?? "");
    return { msalInstance, isTeamsApp, accessToken, clientId: config.clientId };
});
export const login = createAsyncThunk(AUTH_LOGIN, async (_, { getState, dispatch }) => {
    try {
        const state = getState();
        const auth = state.auth;
        const scopes = state.configuration.data.graphScopes.split(" ");
        if (!auth.clientId)
            return ErrorModule.showErrorAlert("Can't consent scopes, client id is not defined");
        if (auth.msalInstance === undefined)
            initMsalInstance(auth.clientId, auth.inIframe);
        await auth.msalInstance?.loginRedirect({ scopes, prompt: "consent" });
    }
    catch (e) {
        // Refresh window in case of invalid response
        // This issue happens only on Android devices, can't parse result
        // After reload it should work fine thanks to SSO
        if (e.code === "InvalidResponse")
            return window.location.reload();
        else if (e.code === "ConsentFailed")
            ErrorModule.showErrorAlert("User cancelled consent", e);
        else
            ErrorModule.showErrorAlert("Consent failed", e);
        return;
    }
    dispatch(setScope({ needToConsent: true }));
});
export const signOut = createAsyncThunk(AUTH_SIGN_OUT, async (_, { getState }) => {
    const state = getState();
    const auth = state.auth;
    if (!auth.msalInstance)
        return ErrorModule.showErrorAlert("MSAL instance is not initialized");
    const logoutRequest = {
        account: auth.msalInstance.getAllAccounts()[0],
    };
    if (!logoutRequest.account)
        return;
    await auth.msalInstance.logoutRedirect(logoutRequest);
});
export const { setAuth } = authSlice.actions;
export const useAuthSelector = getSliceSelector(authSlice);
export const authReducer = authSlice.reducer;
