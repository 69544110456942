export const TeamsProImages = {
    MainImage: "MainImage.svg",
    TeamsProBlack: "TeamsPro.png",
    TeamsProWhite: "TeamsProWhite.png",
    TeamsAnyContent: "AnyContent.png",
    AdminCenter: {
        PremiumImage: "https://raw.githubusercontent.com/witivio-sdk/webkit-sdk/main/PremiumActivated.svg",
        NoPremiumImage: "https://raw.githubusercontent.com/witivio-sdk/webkit-sdk/main/NoPremium.svg",
        UserAdminImage: "https://raw.githubusercontent.com/witivio-sdk/webkit-sdk/main/UserAdmin.svg",
    }
};
