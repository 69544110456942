import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getSliceSelector } from "front";
import { IConnectedApp } from "../../types/IConnectedApp/IConnectedApp";

const initialState: { connectedApp: undefined | IConnectedApp; appIds: string[] } = {
  connectedApp: undefined,
  appIds: [],
};

const slice = createSlice({
  name: "connectedApp",
  initialState,
  reducers: {
    setConnectedAppsAndIds: (state, action: PayloadAction<typeof initialState>) => {
      Object.assign(state, action.payload);
    },

    setConnectedApp: (state, action: PayloadAction<undefined | IConnectedApp>) => {
      state.connectedApp = action.payload;
    },

    setAppIds: (state, action: PayloadAction<string[]>) => {
      state.appIds = action.payload;
    },
  },
});

export const { setConnectedAppsAndIds, setConnectedApp, setAppIds } = slice.actions;

export const useConnectedAppSelector = getSliceSelector(slice);

export const connectedAppReducer = slice.reducer;
