export var PersistentAlertTymeEnum;
(function (PersistentAlertTymeEnum) {
    PersistentAlertTymeEnum["Success"] = "success";
    PersistentAlertTymeEnum["Error"] = "error";
    PersistentAlertTymeEnum["Warning"] = "warning";
    PersistentAlertTymeEnum["Info"] = "info";
})(PersistentAlertTymeEnum || (PersistentAlertTymeEnum = {}));
export var PersistentCtaTypeEnum;
(function (PersistentCtaTypeEnum) {
    PersistentCtaTypeEnum["Notification"] = "notification";
    PersistentCtaTypeEnum["Link"] = "link";
})(PersistentCtaTypeEnum || (PersistentCtaTypeEnum = {}));
