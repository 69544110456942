import { createSlice } from "@reduxjs/toolkit";
import { getSliceSelector } from "../../utils";
const initialState = {
    data: {
        apiVersion: "",
        clientId: "",
        graphScopes: "",
        appInsightKey: "",
        manifestId: "",
        applicationVersion: "",
        adminCenterBaseUrl: "",
        adminCenterAppId: "",
        apiSubscription: "",
        trackingSecretKey: "",
        trackingBaseUrl: "",
        googleMapApiKey: "",
        apiCalendar: "",
        appSourceOfferId: "",
        whiteList: ""
    }
};
const slice = createSlice({
    name: "configuration",
    initialState,
    reducers: {
        setConfiguration: (state, action) => {
            Object.assign(state, action.payload);
        },
    },
});
export const { setConfiguration } = slice.actions;
export const useConfigurationSelector = getSliceSelector(slice);
export const configurationReducer = slice.reducer;
