import { useMsTeamsSelector, usePremiumSelector, useTranslate } from "front";
import { useCalendarCache } from "../../hooks/useCalendarCache/useCalendarCache";
import { useMapCache } from "../../hooks/useMapCache/useMapCache";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setLimitation, useLimitationSelector } from "../../redux/reducers/limitation.reducer";
import { IAppDispatch } from "../../redux/store";
import { translations } from "../../translations";
import { getLimitation } from "../../apis/mapsActions/mapsActions";
import { useTripLimitation } from "../../hooks/useTripLimitation/useTripLimitation";
import { usePlaceLimitation } from "../../hooks/usePlaceLimitation/usePlaceLimitation";

export const useMap = () => {
  const t = useTranslate(translations);
  const dispatchCtx = useDispatch<IAppDispatch>();

  const { subscription } = useLimitationSelector("subscription");
  const { tenantId } = useMsTeamsSelector("tenantId");

  const { queryMap } = useMapCache();
  useCalendarCache({ initializer: true });
  const { canAddPlace } = usePlaceLimitation();
  const { canAddTrip } = useTripLimitation();

  useEffect(() => {
    if (subscription) return;
    getLimitationAsync();
  }, [subscription]);

  const getLimitationAsync = async () => {
    const limitation = await getLimitation(tenantId);
    dispatchCtx(setLimitation(limitation));
  };

  const limitIsReached = () => !canAddPlace() || !canAddTrip();

  return { t, isLoading: queryMap.isLoading || !subscription, limitIsReached };
};
