import { jsx as _jsx } from "react/jsx-runtime";
import { WidgetSettingsForm } from "@witivio_teamspro/witivio-react-toolkit";
import { checkCalendar, getCalendar } from "../../../apis/apiCalendar";
import { useOptionsSelector } from "../../../redux/reducers/optionsReducer";
import { useConfigurationSelector } from "front";
/**
 * Widget configuration
 * @constructor
 */
export const WidgetConfiguration = () => {
    let calendar = undefined;
    const { nameApp } = useOptionsSelector("nameApp");
    const { data } = useConfigurationSelector("data");
    /**
     * Check if calendar exists
     * @param calendarId calendar ID
     */
    const checkCalendarExists = async (calendarId) => {
        const isCalendarExisting = await checkCalendar(calendarId);
        if (isCalendarExisting)
            calendar = await getCalendar(calendarId);
        return isCalendarExisting;
    };
    /**
     * Get calendar title
     */
    const getCalendarTitle = () => calendar?.name ?? "";
    /**
     * Get channel id
     */
    const getChannelId = () => calendar?.channelId ?? "";
    return (_jsx(WidgetSettingsForm, { appName: nameApp, checkExists: checkCalendarExists, manifestId: data.manifestId ?? "", getChannelId: getChannelId, getWidgetTitle: getCalendarTitle }));
};
