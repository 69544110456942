import { jsx as _jsx } from "react/jsx-runtime";
import { useLocation, useNavigate, useParams, } from 'react-router-dom';
export const withRouter = (Component) => {
    return (props) => {
        const location = useLocation();
        const params = useParams();
        const navigate = useNavigate();
        return (
        // @ts-ignore
        _jsx(Component, { ...props, location: location, params: params, navigate: navigate }));
    };
};
export default withRouter;
