import { Component } from 'react';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from "history";
import withRouter from "./withRouter.hook";
import store from '../../redux/store';
import { setInfos } from 'front';
const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
export let appInsightInstance;
class TelemetryService extends Component {
    componentDidMount() {
        const configuration = store.getState().configuration;
        const key = configuration?.data.appInsightKey;
        if (!key)
            return;
        const appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: key,
                maxBatchInterval: 0,
                disableFetchTracking: false,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory
                    }
                }
            }
        });
        appInsights.loadAppInsights();
        appInsightInstance = appInsights.appInsights;
        store.dispatch(setInfos({ appInsightInstance }));
    }
    render() {
        return this.props.children;
    }
}
export default withRouter(withAITracking(reactPlugin, TelemetryService));
