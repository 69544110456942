import { jsx as _jsx } from "react/jsx-runtime";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSliceSelector } from "../../utils";
import { PersistentAlertTymeEnum, PersistentCtaTypeEnum } from "../../../interfaces/PersistantAlert";
import { MANAGE_EXPIRATION } from "./PersistentAlert.interfaces";
import { translations } from "../../../translations";
import { ExclamationTriangleIcon } from "@fluentui/react-northstar";
const initialState = {
    persistentAlert: null,
    persistentAlertHeight: 0,
};
export const manageExpiration = createAsyncThunk(MANAGE_EXPIRATION, async (data) => {
    if (data.subscriptionCardExpiring) {
        if (data.userId === data.subscriptionOwner) {
            return ({
                title: translations.get('IC_SuperAdminTitle'),
                message: translations.get('IC_SuperAdminMessage'),
                type: PersistentAlertTymeEnum.Warning,
                icon: _jsx(ExclamationTriangleIcon, {}),
                cta: {
                    type: PersistentCtaTypeEnum.Link,
                    label: translations.get('IC_SuperAdminCTA'),
                    action: data.adminCenterBaseUrl + "/products/" + data.subscriptionLicenceId,
                }
            });
        }
        return ({
            title: translations.get('IC_AdminTitle'),
            message: translations.get('IC_AdminMessage'),
            type: PersistentAlertTymeEnum.Warning,
            icon: _jsx(ExclamationTriangleIcon, {}),
            cta: {
                type: PersistentCtaTypeEnum.Notification,
                label: translations.get('IC_AdminCTA'),
            }
        });
    }
    return null;
});
const persistentAlertSlice = createSlice({
    name: "persistentAlert",
    initialState: initialState,
    reducers: {
        setPersistantAlert(state, action) {
            state.persistentAlert = action.payload;
        },
        removePersistantAlert(state) {
            state.persistentAlert = null;
        },
        setPersistantAlertHeight(state, action) {
            state.persistentAlertHeight = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(manageExpiration.fulfilled, (state, action) => {
            state.persistentAlert = action.payload;
        });
    }
});
export const { setPersistantAlert, setPersistantAlertHeight, removePersistantAlert } = persistentAlertSlice.actions;
export const persitantAlertReducer = persistentAlertSlice.reducer;
export const usePersitentAlertSelector = getSliceSelector(persistentAlertSlice);
