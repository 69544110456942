import { createSlice } from "@reduxjs/toolkit";
import { getSliceSelector } from "../../utils";
const initialState = {
    appPathName: "",
    appId: "",
    appOwner: "",
    appName: "",
    isResourceMode: false,
    app: "",
    from: "",
    configuringApp: false
};
const slice = createSlice({
    name: "appConfiguration",
    initialState,
    reducers: {
        setAppConfiguration: (state, action) => {
            Object.assign(state, action.payload);
        },
        setConfiguringApp: (state, action) => {
            state.configuringApp = action.payload;
        },
    },
});
export const { setAppConfiguration, setConfiguringApp } = slice.actions;
export const useAppConfigurationSelector = getSliceSelector(slice);
export const appConfigurationReducer = slice.reducer;
