import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import copy from "copy-to-clipboard";
import { translations } from "../../../translations";
import { useTranslate } from "../../../hook/useTranslate";
import { useConfigurationSelector } from "../../../redux/reducers/ConfigurationReducer/ConfigurationReducer";
import { useAppConfigurationSelector } from "../../../redux/reducers/AppConfigurationReducer/AppConfigurationReducer";
import { setModalWithData, useModalSelector } from "../../../redux/reducers/ModalReducer/ModalReducer";
export const useAboutDialog = () => {
    // Services
    const dispatchCtx = useDispatch();
    const t = useTranslate(translations);
    // Selector
    const config = useConfigurationSelector("data");
    const { appId, appOwner } = useAppConfigurationSelector("appId", "appOwner");
    const { isOpen } = useModalSelector("isOpen");
    // State
    const [state, setState] = useState({ showPopupCopied: false });
    const onCopyAppId = useCallback(() => {
        if (!appId)
            return;
        onCopy(appId);
    }, [appId]);
    const onCopy = (stringToCopy) => {
        copy(stringToCopy);
        setState({ showPopupCopied: true });
        setTimeout(() => {
            setState({ showPopupCopied: false });
        }, 1000);
    };
    const onClose = useCallback(() => dispatchCtx(setModalWithData({ isOpen: undefined, data: undefined })), []);
    return { t, onClose, appVersion: config.data.applicationVersion, onCopyAppId, uniqueId: appId, createdBy: appOwner, state, isOpen };
};
