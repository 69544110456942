export var Modal;
(function (Modal) {
    Modal["Settings"] = "settings";
    Modal["SHOWGETIDDIALOG"] = "showGetIdDialog";
    Modal["PERMISSIONSDIALOG"] = "permissionsDialog";
    Modal["Rooms"] = "RoomManagementDialog";
    Modal["NOTIFICATIONS"] = "notifications";
    Modal["NEWS"] = "news";
    Modal["UPGRADE"] = "upgrade";
})(Modal || (Modal = {}));
