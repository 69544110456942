export var SyncMenu;
(function (SyncMenu) {
    SyncMenu["synchronize"] = "Synchronize";
    SyncMenu["manage"] = "Manage";
})(SyncMenu || (SyncMenu = {}));
export var SyncMaxCalendar;
(function (SyncMaxCalendar) {
    SyncMaxCalendar[SyncMaxCalendar["maxCalendar"] = 3] = "maxCalendar";
})(SyncMaxCalendar || (SyncMaxCalendar = {}));
export var SyncErrorMessage;
(function (SyncErrorMessage) {
    SyncErrorMessage["noError"] = "";
    SyncErrorMessage["noItem"] = "";
    SyncErrorMessage["noUrl"] = "Sync:NoUrlErrorMessage";
    SyncErrorMessage["noName"] = "Sync:NoNameErrorMessage";
    SyncErrorMessage["startUrl"] = "Sync:StartUrlErrorMessage";
    SyncErrorMessage["endUrl"] = "Sync:EndUrlErrorMessage";
})(SyncErrorMessage || (SyncErrorMessage = {}));
