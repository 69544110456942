import { Button, CloseIcon, Dialog, Dropdown, Flex, FormDropdown, FormInput, Input, Text, TrashCanIcon } from "@fluentui/react-northstar";
import { useMemo } from "react";
import { AVAILABLE_APPS_TO_CONNECT, CONNECTED_APPS, useConnectAppDialog } from "./ConnectAppDialog.logic";
import { Controller } from "react-hook-form";
import { KNOW_MORE } from "../../const/links";
import "./ConnectAppDialog.scss";

const ConnectAppDialog = () => {
  const logic = useConnectAppDialog();

  const content = useMemo(
    () => (
      <Flex column gap={"gap.medium"}>
        <Flex column>
          <Text onClick={() => logic.linkRedirection(KNOW_MORE)} weight={"bold"} color={"brand"} content={logic.t("ToKnowMore")} styles={{ cursor: "pointer", textDecoration: "underline" }} />
        </Flex>
        <Flex column gap={"gap.medium"} className="connectAppDialog-form-container">
          <FormDropdown
            fluid
            label={logic.t("TeamsProApplications")}
            value={logic.selectedApp}
            error={logic.selectedApp === undefined}
            placeholder={logic.t("SelectionApplication")}
            items={AVAILABLE_APPS_TO_CONNECT}
            onChange={logic.handleAppToConnect}
          />
          {logic.selectedApp !== undefined ? (
            <Flex column gap={"gap.small"}>
              <Flex column>
                <Flex column gap={"gap.small"} vAlign={"center"}>
                  {Object.keys(CONNECTED_APPS).map((_, i) => (
                    <Controller
                      key={`connecAppDialog-input-form-${i}`}
                      name={`app${i + 1}` as "app1" | "app2" | "app3"}
                      control={logic.control}
                      render={({ field }) => (
                        <Flex vAlign={"end"}>
                          <FormInput
                            fluid
                            styles={{ width: "100%" }}
                            label={`${logic.t("ConnectIDLabel")} n°${i + 1}`}
                            {...field}
                            error={field.value.length > 0 && !logic.isGuidValid(field.value)}
                            errorMessage={field.value.length > 0 && !logic.isGuidValid(field.value) && logic.t("ConnectLabelError")}
                            showSuccessIndicator={logic.isGuidValid(field.value)}
                          />
                          <Button text iconOnly onClick={() => logic.clearInput(i + 1)} icon={<TrashCanIcon disabled={!logic.selectedApp} outline />} />
                        </Flex>
                      )}
                    />
                  ))}
                  {logic.submitError && <Text content={logic.submitError} error style={{ padding: "0.75rem 1rem", background: "rgba(255, 0, 0, .075)", borderRadius: 5 }} />}
                </Flex>
              </Flex>
              <Text styles={{ cursor: "pointer", textDecoration: "underline" }} onClick={logic.getAppIdLink} content={logic.t("ConnectHowTo")} />
            </Flex>
          ) : undefined}
        </Flex>
      </Flex>
    ),
    [logic.selectedApp, logic.submitError]
  );

  const footer = useMemo(
    () => (
      <Flex hAlign="end" gap="gap.small">
        <Button content={logic.t("ButtonCancelModalPlace")} onClick={logic.onClose} />
        <Button content={logic.t("Save")} primary onClick={logic.handleSubmit(logic.onSubmit)} disabled={false} />
      </Flex>
    ),
    [logic.selectedApp]
  );

  return (
    <Dialog
      header={logic.t("TitleConnect")}
      headerAction={{ icon: <CloseIcon />, title: "Close", onClick: logic.onClose }}
      content={content}
      footer={footer}
      defaultOpen={true}
      onCancel={logic.onClose}
      closeOnOutsideClick
    />
  );
};

export default ConnectAppDialog;
