import { IOptionTrip } from "../../../types/IOptionTrip/IOptionTrip";

export let initialState: { mode: number; travelMode: IOptionTrip } = {
  mode: 0,
  travelMode: IOptionTrip.Car,
};

const getDefaultState = () => ({
  mode: 0,
  travelMode: IOptionTrip.Car,
});

export type IState = typeof initialState;

export enum IAction {
  MODIFY_MODE = "modify_mode",
}
export const componentIsUnmounting = () => {
  initialState = getDefaultState();
};

const actionHandlers = Object.fromEntries(
  Object.values(IAction).map((action) => [action, (_: IState, payload: IState) => ({ ...payload })])
);

export function reducer(state: IState, { type, payload }: { type: IAction; payload: IState }): IState {
  const actionHandler = actionHandlers[type] || ((state) => state);
  return actionHandler(state, payload);
}
