import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef } from "react";
import { Flex } from "@fluentui/react-northstar";
import "./googleMap.scss";
import { ThemeClass } from "../../redux/reducers/MicrosoftTeamsReducer/MicrosoftTeamsReducer.interfaces";
/**
 * Google Map
 */
export const GoogleMap = (props) => {
    const mapRef = useRef(null);
    let defaultAddress = null;
    let googleMap = null;
    useEffect(() => {
        if (!props.lat || !props.lng)
            return;
        setTimeout(() => {
            refreshMap();
        }, 500);
    }, [props.lat, props.lng, props.mapType, props.mapTypeControl]);
    const refreshMap = () => {
        defaultAddress = new google.maps.LatLng(props.lat, props.lng);
        const isLightTheme = props.theme === ThemeClass.Default;
        const mapStyles = [
            {
                featureType: "poi",
                stylers: [{ visibility: "off" }],
            },
            {
                featureType: "transit",
                elementType: "labels.icon",
                stylers: [{ visibility: "off" }],
            },
        ];
        if (!isLightTheme) {
            mapStyles.push({ elementType: "geometry", stylers: [{ color: "#242f3e" }] }, { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] }, { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] }, {
                featureType: "administrative.locality",
                elementType: "labels.text.fill",
                stylers: [{ color: "#d59563" }],
            }, {
                featureType: "road",
                elementType: "geometry",
                stylers: [{ color: "#38414e" }],
            }, {
                featureType: "road",
                elementType: "geometry.stroke",
                stylers: [{ color: "#212a37" }],
            }, {
                featureType: "road",
                elementType: "labels.text.fill",
                stylers: [{ color: "#9ca5b3" }],
            }, {
                featureType: "road.highway",
                elementType: "geometry",
                stylers: [{ color: "#746855" }],
            }, {
                featureType: "road.highway",
                elementType: "geometry.stroke",
                stylers: [{ color: "#1f2835" }],
            }, {
                featureType: "road.highway",
                elementType: "labels.text.fill",
                stylers: [{ color: "#f3d19c" }],
            }, {
                featureType: "water",
                elementType: "geometry",
                stylers: [{ color: "#17263c" }],
            }, {
                featureType: "water",
                elementType: "labels.text.fill",
                stylers: [{ color: "#515c6d" }],
            }, {
                featureType: "water",
                elementType: "labels.text.stroke",
                stylers: [{ color: "#17263c" }],
            });
        }
        if (mapRef.current) {
            const map = new google.maps.Map(mapRef.current, {
                zoom: 15,
                center: defaultAddress,
                mapTypeControl: props.mapTypeControl,
                streetViewControl: true,
                zoomControl: true,
                mapTypeId: props.mapType,
                styles: mapStyles,
            });
            new google.maps.Marker({
                map: map,
                position: { lat: props.lat, lng: props.lng },
            });
            googleMap = map;
            if (!props.instanceGoogleMaps || !googleMap)
                return;
            props.instanceGoogleMaps(googleMap);
        }
    };
    return (_jsx(Flex, { fill: true, style: { marginTop: props.labelIsHidden ? ".5rem" : 0 }, children: _jsx("div", { id: 'google-map', ref: mapRef, style: { height: "100%", width: "100%" } }) }));
};
